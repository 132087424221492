import axios, {AxiosError, isAxiosError} from "axios"
import config from "../config/api"
import {
    ICreateOrganizationWithAdmin,
    IDeleteOrganizationArgs,
    IPaginationParamsWithIds,
    IResponse,
    Organization,
    OrganizationForCreation
} from "../types";
import {get, isEqual, isNull} from "lodash";
import {PaginationProps} from "react-bootstrap";

const {apiURL} = config.apiConfig;

interface ICreateOrganizationResponse extends IResponse {
    data?: Organization;
}

export const createOrganizationAPI = async (organization: OrganizationForCreation): Promise<ICreateOrganizationResponse> => {
    try {
        const URL = `${apiURL}/api/organizations`;
        const axiosResponse = await axios.post(URL, organization);

        if (isEqual(axiosResponse.status, 201)) {
            return {
                success: true,
                data: axiosResponse.data
            }
        } else {
            return {
                success: false,
                errors: [axiosResponse.statusText]
            }
        }
    } catch(error) {
        console.error("An error occurred during organization creation(api)", error);
        let errorResponse: ICreateOrganizationResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during organization creation"]
        };
    }
}

export const getUserOrganizationAPI = async (): Promise<ICreateOrganizationResponse> => {
    try {
        const URL = `${apiURL}/api/organizations/my-org`;

        const axiosResponse = await axios.get(URL);

        if (isEqual(axiosResponse.status, 200)) {
            return {
                success: true,
                data: axiosResponse.data
            }
        } else {
            return {
                success: false,
                errors: [axiosResponse.statusText]
            }
        }
    } catch(error) {
        console.error("An error occurred during organization fetching(api)", error);
        let errorResponse: ICreateOrganizationResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during organization fetching"]
        };
    }
}

interface IGetAllOrganizationsResponse extends IResponse {
    data?: Organization[];
    quantity?: number;
}

export const getAllOrganizationsAPI = async (arg: IPaginationParamsWithIds | null): Promise<IGetAllOrganizationsResponse> => {
    try {
        let URL: string =
            `${apiURL}/api/organizations/with-contact-person${!isNull(arg) ? `?limit=${arg.limit}&offset=${arg.offset}&withStripeUrl=${arg.withStripeUrl}` : ""}`;

        if (!isNull(arg) && !!arg.order && !!arg.orderBy) {
            URL = URL.concat(`&order=${arg.order}&orderBy=${arg.orderBy}`);
        }
        const axiosResponse = await axios.get(URL);

        if (isEqual(axiosResponse.status, 200)) {
            return {
                success: true,
                data: get(axiosResponse.data, "data", []),
                quantity: get(axiosResponse.data, "count", 0)
            }
        } else {
            return {
                success: false,
                errors: [axiosResponse.statusText]
            }
        }
    } catch (error) {
        console.error("An error occurred during organizations fetching(api)", error);
        let errorResponse: IGetAllOrganizationsResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during organizations fetching"]
        };
    }
}

interface IUpdateOrganizationResponse extends IResponse{
    data?: Organization
}

export const updateOrganizationAPI = async (organization: Organization, id: string): Promise<IUpdateOrganizationResponse> => {
    try {
        const URL = `${apiURL}/api/organizations/${id}`;

        const axiosResponse = await axios.patch(URL, organization);

        if (isEqual(axiosResponse.status, 200)) {
            return {
                success: true,
                data: axiosResponse.data
            }
        } else {
            return {
                success: false,
                errors: [axiosResponse.statusText]
            }
        }
    } catch(error) {
        console.error("An error occurred during organization updating(api)", error);
        let errorResponse: IUpdateOrganizationResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during organization updating"]
        };
    }
}

interface IDeleteOrganizationResponse extends IResponse {
    message?: string;
}

export const deleteOrganizationAPI = async (arg: IDeleteOrganizationArgs): Promise<IDeleteOrganizationResponse> => {
    try {
        const URL = `${apiURL}/api/organizations/${arg.soft ? "soft/" : ""}${arg.id}`;

        const axiosResponse = await axios.delete(URL);

        if (isEqual(axiosResponse.status, 200)) {
            return  {
                success: true,
                message: get(axiosResponse.data, "message", "")
            }
        } else {
            return {
                success: false,
                errors: [axiosResponse.statusText]
            }
        }
    } catch(error) {
        console.error("An error occurred during organization deleting(api)", error);
        let errorResponse: IDeleteOrganizationResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during organization deleting"]
        };
    }
}

export const restoreOrganizationAPI = async (orgId: string): Promise<IDeleteOrganizationResponse> => {
    try {
        const URL = `${apiURL}/api/organizations/restore/${orgId}`;

        const axiosResponse = await axios.patch(URL);

        if (isEqual(axiosResponse.status, 200)) {
            return  {
                success: true,
                message: get(axiosResponse.data, "message", "")
            }
        } else {
            return {
                success: false,
                errors: [axiosResponse.statusText]
            }
        }
    } catch(error) {
        console.error("An error occurred during organization deleting(api)", error);
        let errorResponse: IDeleteOrganizationResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during organization deleting"]
        };
    }
}

interface ICreateOrganizationAndAdminResponse extends IResponse {
    data?: {
        orgId: string;
        userId: string;
    }
}

export const createOrganizationWithAdminAPI = async (arg: ICreateOrganizationWithAdmin): Promise<ICreateOrganizationAndAdminResponse> => {
    try {
        const URL = `${apiURL}/api/organizations/with-admin`;

        const axiosResponse = await axios.post(URL, arg);

        if (isEqual(axiosResponse.status, 201)) {
            return  {
                success: true,
                data: axiosResponse.data
            }
        } else {
            return {
                success: false,
                errors: [axiosResponse.statusText]
            }
        }
    } catch(error) {
        console.error("An error occurred during organization creating(api)", error);
        let errorResponse: ICreateOrganizationAndAdminResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during organization creating"]
        };
    }
}

interface IGetCustomerPortalLinkResponse extends IResponse {
    data?: string;
}

export const getCustomerPortalURLAPI = async (orgId: string): Promise<IGetCustomerPortalLinkResponse> => {
    try {
        const URL = `${apiURL}/api/organizations/customer-portal/${orgId}`;

        const axiosResponse = await axios.get(URL);

        if (isEqual(axiosResponse.status, 200)) {
            return  {
                success: true,
                data: axiosResponse.data
            }
        } else {
            return {
                success: false,
                errors: [axiosResponse.statusText]
            }
        }
    } catch(error) {
        console.error("An error occurred during organization creating(api)", error);
        let errorResponse: IGetCustomerPortalLinkResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during getting organization customer portal url"]
        };
    }
}
