import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import * as S from "./styled-components";
import {organizationBoard} from "../../constants/dashboard";
import SideBar from "./sideBar";
import {formBasicEmail, formBasicFullName, formBasicOrganizationNumber, formBasicPhoneNumber} from "../../constants";
import * as SU from "../user/styled-components";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectLoading, selectMessage, setMessage} from "../../features/global/globalSlice";
import {RegistrationErrors} from "../../types";
import * as SUser from "../user/styled-components";
import {isEmpty, isEqual} from "lodash";
import {getInvalidFiledName, validateEmail, validateMobileNumber} from "../../utils";
import {createOrganizationAndAdministrator} from "../../features/organization/organizationThunks";
import {selectOrganizationStatus, setOrganizationStatus} from "../../features/organization/organizationSlice";
import {useNavigate} from "react-router-dom";

function AddOrganization() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [orgNumber, setOrgNumber] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [userNumber, setUserNumber] = useState("");
    const [errors, setErrors] = useState<RegistrationErrors>({});
    const [showSuccess, setShowSuccess] = useState(false);
    const [notValid, setNotValid] = useState(false);

    const isLoading = useAppSelector(selectLoading);
    const errorMessage = useAppSelector(selectMessage);
    const status = useAppSelector(selectOrganizationStatus);

    useEffect(() => {
        if (isEqual(status, "loaded")) {
            setShowSuccess(true);
        }

        return () => {
            dispatch(setMessage(null));
        }
    }, [status]);

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                clearSession();
            }, 2000);
        }
    }, [showSuccess]);

    useEffect(() => {
        let fieldName = "email";
        if (errorMessage) {
            if (errorMessage.toLowerCase().indexOf("org name") >= 0) {
                fieldName = "organizationName";
            } else if (errorMessage.toLowerCase().indexOf("org email") >= 0) {
                fieldName = "organizationEmail";
            } else if (errorMessage.toLowerCase().indexOf("email") >= 0) {
                fieldName = "email";
            }
            fieldName = isEqual(fieldName, "email") ? "email" : fieldName;
            setErrors({
                ...errors,
                [fieldName]: errorMessage
            });
        }
    }, [errorMessage]);

    const clearSession = () => {
        dispatch(setOrganizationStatus("idle"));
        setEmail("");
        setName("");
        setUserEmail("");
        setUserName("");
        setOrgNumber("");
        setUserNumber("");
        setShowSuccess(false);
    }

    const validateData = (): boolean => {
        let tmpErrors = {};

        if (isEmpty(name)) {
            tmpErrors = {...tmpErrors, organizationName: "Organization name cannot be empty"};
        } else if (name.length > 20) {
            tmpErrors = {...tmpErrors, organizationName: "Organization name should contain no more than 20 characters"}
        }
        if (isEmpty(email)) {
            tmpErrors = {...tmpErrors, organizationEmail: "Organization email cannot be empty"};
        } else if (email.length > 100) {
            tmpErrors = {
                ...tmpErrors,
                organizationEmail: "Organization email should contain no more than 100 characters"
            };
        } else if (!validateEmail(email)) {
            tmpErrors = {
                ...tmpErrors,
                organizationEmail: "Organization email contains invalid characters"
            };
        }
        if (isEmpty(orgNumber)) {
            tmpErrors = {...tmpErrors, organizationNumber: "Organization phone cannot be empty"}
        } else if (orgNumber.length > 13) {
            tmpErrors = {...tmpErrors, organizationNumber: "Organization phone should contains no more 13 symbols"}
        } else if (!validateMobileNumber(orgNumber)) {
            tmpErrors = {...tmpErrors, organizationNumber: "Organization phone number is not valid"}
        }

        if (isEmpty(userName)) {
            tmpErrors = {...tmpErrors, fullName: "Organization admin name cannot be empty"};
        } else if (userName.length > 20) {
            tmpErrors = {...tmpErrors, fullName: "Organization admin name should contain no more than 20 characters"}
        }
        if (isEmpty(userEmail)) {
            tmpErrors = {...tmpErrors, email: "Organization admin email cannot be empty"};
        } else if (userEmail.length > 100) {
            tmpErrors = {...tmpErrors, email: "Organization admin email should contain no more than 100 characters"};
        } else if (!validateEmail(userEmail)) {
            tmpErrors = {
                ...tmpErrors,
                email: "Organization admin email contains invalid characters"
            };
        }
        if (!isEmpty(userNumber)) {
            if (userNumber.length > 13) {
                tmpErrors = {...tmpErrors, mobileNumber: "Organization admin phone should contains no more 13 symbols"}
            } else if (!validateMobileNumber(userNumber)) {
                tmpErrors = {...tmpErrors, mobileNumber: "Organization admin phone number is not valid"}
            }
        }

        let result = Object.keys(tmpErrors).length > 0;
        setNotValid(result);
        if (result) {
            setErrors(tmpErrors);
        } else {
            setErrors({});
        }

        return result;
    }

    const onAddOrganization = async () => {
        if (!validateData()) {
            await dispatch(createOrganizationAndAdministrator({
                name,
                email,
                orgNumber,
                userName,
                userEmail,
                userNumber
            }));
        }
    }

    const getPadding = () => {
        return {paddingTop: (!notValid ? "100px" : "150px")};
    };

    return (
        <S.StyledDashboardContainer>
            <SideBar currentComponent={organizationBoard}/>
            <S.StyledMainContainer>
                <S.StyledTopBar>
                    <S.StyledMainHeader>
                        <S.StyledUsersHeaderCaption style={{width: "215px"}}>
                            <span style={{color: "#94A3B8", cursor: "pointer"}}
                                  onClick={() => navigate("/organizations-list")}>Organizations</span> / Add
                            organization
                        </S.StyledUsersHeaderCaption>
                    </S.StyledMainHeader>
                </S.StyledTopBar>
                <S.StyledCenterContainer>
                    <S.StyledProfileHeader>
                        <S.StyledHeaderCaption>Add organization</S.StyledHeaderCaption>
                    </S.StyledProfileHeader>
                    {showSuccess && (
                        <div style={{marginBottom: "10px"}}>
                            <S.StyledUserInviteConfirm>
                                <S.StyledConfirmIconWrapper>
                                    <S.StyleConfirmIcon>
                                        <S.StyledIcon style={{color: "#2FBC36"}}
                                                      className="fa-solid fa-circle-check"/>
                                    </S.StyleConfirmIcon>
                                </S.StyledConfirmIconWrapper>
                                <S.StyledUserInviteConfirmText>
                                    Organization has been created
                                </S.StyledUserInviteConfirmText>
                            </S.StyledUserInviteConfirm>
                        </div>
                    )}
                    <S.StyledProfileInfoContainer>
                        <Form>
                            <Form.Group id={formBasicFullName}>
                                <SU.StyledRegistrationLabel>Name</SU.StyledRegistrationLabel>
                                <SU.StyledRegistrationInput type="input"
                                                            value={name}
                                                            isInvalid={!!errors.organizationName}
                                                            disabled={isLoading}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
                                />
                                <S.StyledFeedback type="invalid">{errors.organizationName}</S.StyledFeedback>
                            </Form.Group>
                            <Form.Group id={formBasicEmail}>
                                <SU.StyledRegistrationLabel>Email</SU.StyledRegistrationLabel>
                                <SU.StyledRegistrationInput type="input"
                                                            value={email}
                                                            isInvalid={!!errors.organizationEmail}
                                                            disabled={isLoading}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                                />
                                <S.StyledFeedback type="invalid">{errors.organizationEmail}</S.StyledFeedback>
                            </Form.Group>
                            <Form.Group id={formBasicOrganizationNumber}>
                                <SU.StyledRegistrationLabel>Phone</SU.StyledRegistrationLabel>
                                <SU.StyledRegistrationInput type="input"
                                                            value={orgNumber}
                                                            isInvalid={!!errors.organizationNumber}
                                                            disabled={isLoading}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOrgNumber(event.target.value)}
                                />
                                <S.StyledFeedback type="invalid">{errors.organizationNumber}</S.StyledFeedback>
                            </Form.Group>
                            <S.StyledBottomFrameAddOrganization>
                                <SU.StyledOrganizationAdministratorCaption>
                                    Organization administrator
                                </SU.StyledOrganizationAdministratorCaption>
                                <Form.Group id={formBasicFullName}>
                                    <SU.StyledRegistrationLabel>Name</SU.StyledRegistrationLabel>
                                    <SU.StyledRegistrationInput type="input"
                                                                value={userName}
                                                                isInvalid={!!errors.fullName}
                                                                disabled={isLoading}
                                                                styled={{width: "344px"}}
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUserName(event.target.value)}
                                    />
                                    <S.StyledFeedback type="invalid">{errors.fullName}</S.StyledFeedback>
                                </Form.Group>
                                <Form.Group id={formBasicEmail}>
                                    <SU.StyledRegistrationLabel>Email</SU.StyledRegistrationLabel>
                                    <SU.StyledRegistrationInput type="input"
                                                                value={userEmail}
                                                                isInvalid={!!errors.email}
                                                                disabled={isLoading}
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUserEmail(event.target.value)}
                                    />
                                    <S.StyledFeedback type="invalid">{errors.email}</S.StyledFeedback>
                                </Form.Group>
                                <Form.Group id={formBasicPhoneNumber}>
                                    <SU.StyledRegistrationLabel>Phone</SU.StyledRegistrationLabel>
                                    <SU.StyledRegistrationInput type="input"
                                                                value={userNumber}
                                                                isInvalid={!!errors.mobileNumber}
                                                                disabled={isLoading}
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUserNumber(event.target.value)}
                                    />
                                    <S.StyledFeedback type="invalid">{errors.mobileNumber}</S.StyledFeedback>
                                </Form.Group>
                            </S.StyledBottomFrameAddOrganization>
                            <S.StyledProfileButtonsFrame style={getPadding()}>
                                <S.StyledCreateOrganizationButton onClick={onAddOrganization}>
                                    {isLoading && (<SUser.StyledSingInSpinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />)}
                                    Create organization
                                </S.StyledCreateOrganizationButton>
                            </S.StyledProfileButtonsFrame>
                        </Form>
                    </S.StyledProfileInfoContainer>
                </S.StyledCenterContainer>
            </S.StyledMainContainer>
        </S.StyledDashboardContainer>
    );
}

export default AddOrganization;

