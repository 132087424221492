import axios, {AxiosError} from "axios"
import {isEqual} from "lodash";
import {getToken} from "../utils"
import {redirectToLogin} from "../utils/redirect";

export * from "./userAPI";
export * from "./organizationAPI";
export * from "./logdAPI"
export * from "./deviceAPI"
export * from "./fileAPI";

axios.interceptors.request.use(async (config) => {
    const token = getToken();

    if (typeof token === 'string' && token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, (error: AxiosError) => {
    if (error.code && isEqual(error.code, 401)) {
        redirectToLogin();
    }
});
