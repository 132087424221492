import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom";
import * as S from "./styled-components";
import * as SU from "../user/styled-components"
import SideBar from "./sideBar";
import {organizationBoard} from "../../constants/dashboard";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {Form, Tab, Tabs, Col, Row, Modal} from 'react-bootstrap';
import {get, isEmpty, isEqual} from "lodash";
import {
    CountryCodes,
    formBasicAddress, formBasicCity,
    formBasicCountry,
    formBasicEmail,
    formBasicFullName, formBasicOrganizationCreatorEmail, formBasicOrganizationCreatorName,
    formBasicPhoneNumber, formBasicZip
} from "../../constants";
import {selectLoading, selectMessage} from "../../features/global/globalSlice";
import {
    selectListOfOrganizations,
    selectOrganization, selectOrganizationPortalURL,
    setOrganization
} from "../../features/organization/organizationSlice";
import {Organization, RegistrationErrors} from "../../types";
import * as SUser from "../user/styled-components";
import {isOrgAdmin, isSuperAdmin, validateEmail, validateMobileNumber} from "../../utils";
import {updateOrganization} from "../../features/organization/organizationThunks";
import {selectAuthorizedUser} from "../../features/user/userSlice";
import isUUID from "validator"
import {getCustomerPortalURLAPI} from "../../api";

type CountryKey = keyof typeof CountryCodes;

function OrganizationSettings() {
    const dispatch = useAppDispatch();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [country, setCountry] = useState("NO");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [key, setKey] = useState("commonSettings");
    const [organizationId, setOrganizationId] = useState("");

    const [addressTouch, setAddressTouch] = useState(false);
    const [commonTouch, setCommonTouch] = useState(false);
    const [notValid, setNotValid] = useState(false);

    const [triggered, setTriggered] = useState(false);

    const [errors, setErrors] = useState<RegistrationErrors>({});
    const [showError, setShowError] = useState(false);

    let params = useParams();

    const isLoading = useAppSelector(selectLoading);
    const errorMessage = useAppSelector(selectMessage);
    const organization = useAppSelector(selectOrganization);
    const user = useAppSelector(selectAuthorizedUser);
    const organizationsList = useAppSelector(selectListOfOrganizations);
    const portalURL = useAppSelector(selectOrganizationPortalURL);

    const conditionalStyle = {paddingTop: "28px"};

    useEffect(() => {
        if (organization) {
            setName(organization.name);
            setEmail(organization.email);
            setCountry(organization.country || "NO");
            setPhoneNumber(organization.phoneNumber || "");
            if (organization.shippingAddress) {
                setStreet(organization.shippingAddress.street);
                setCity(organization.shippingAddress.city);
                setPostalCode(organization.shippingAddress.postalCode);
            } else {
                setStreet("");
                setCity("");
                setPostalCode("");
            }
            setCommonTouch(false);
            setAddressTouch(false);
        }
    }, [organization]);

    useEffect(() => {
        if (!isEmpty(organizationId)) {
            const pickedOrg = organizationsList.filter((org) => isEqual(organizationId, org.id))[0];
            dispatch(setOrganization(pickedOrg));
        }
    }, [organizationId]);

    useEffect(() => {
        if (!triggered && isEqual(params.tab, "shippingAddress")) {
            setKey("shippingAddress");
            setTriggered(true);
        }

        return () => {
            if (isSuperAdmin(user)) {
                dispatch(setOrganization(null));
            }
        }
    }, []);

    const onUpdateOrganization = async () => {
        if (!validateData() && organization && organization.id) {
            const org: Organization = {
                name, email, country, phoneNumber,
                shippingAddress: {
                    street, city, postalCode
                }
            };

            await dispatch(updateOrganization({organization: org, id: organization.id}))
        }
    }

    const validateData = (): boolean => {
        let tmpErrors = {};

        if (commonTouch) {
            if (isEmpty(name)) {
                tmpErrors = {...tmpErrors, organizationName: "Organization name cannot be empty"}
            } else if (name.length > 100) {
                tmpErrors = {...tmpErrors, organizationName: "Organization name cannot be longer 100 characters"}
            }
            if (isEmpty(email)) {
                tmpErrors = {...tmpErrors, email: "Email cannot be empty"}
            } else if (email.length > 100) {
                tmpErrors = {...tmpErrors, email: "Email cannot be longer 100 characters"}
            } else if (!validateEmail(email)) {
                tmpErrors = {...tmpErrors, email: 'Email is not valid'}
            }
            if (!isEmpty(phoneNumber)) {
                if (phoneNumber.length > 13) {
                    tmpErrors = {...tmpErrors, mobileNumber: "Phone should contains no more 13 symbols"}
                } else if (!validateMobileNumber(phoneNumber)) {
                    tmpErrors = {...tmpErrors, mobileNumber: "Phone number is not valid"}
                }
            }
        }

        if (addressTouch) {
            if (isEmpty(street)) {
                tmpErrors = {...tmpErrors, street: "Street cannot be empty"}
            } else if (street.length > 100) {
                tmpErrors = {...tmpErrors, street: "Address cannot be longer 100 characters"}
            }
            if (isEmpty(city)) {
                tmpErrors = {...tmpErrors, city: "City cannot be empty"}
            } else if (city.length > 75) {
                tmpErrors = {...tmpErrors, city: "City name cannot be longer 75 characters"}
            }
            if (isEmpty(postalCode)) {
                tmpErrors = {...tmpErrors, postalCode: "Zip/Post code cannot be empty"}
            } else if (postalCode.length > 10) {
                tmpErrors = {...tmpErrors, postalCode: "Zip/Post code cannot be longer 10 characters"}
            }
        }

        let result = Object.keys(tmpErrors).length > 0;
        setNotValid(result);
        if (result) {
            setErrors(tmpErrors);
        } else {
            setErrors({});
        }


        return result;
    }

    const getNotValidMargin = () => {
        return {marginTop: notValid ? "20px" : "0px"};
    }

    const onNavigateToPortal = async () => {
        try {
            if (organization && organization.id) {
                const {id} = organization;
                if (!isEmpty(id)) {
                    const response = await getCustomerPortalURLAPI(id);

                    if (response.success) {
                        const URL = get(response, "data.url");
                        window.open(URL, "_blank", "noopener,noreferrer");
                    } else {
                        onNavigateErrorHandler()
                    }
                }
            } else {
                onNavigateErrorHandler();
            }
        } catch (error) {
            console.error("An error occurred during obtaining link to Stripe portal", error);
            onNavigateErrorHandler();
        }
    }

    const onNavigateErrorHandler = () => {
        setShowError(true);
    }

    const renderModalWithError = () => {
        return (
            <S.StyledEditRoleModal show={showError}
                                   centered={true}
                                   onHide={() => setShowError(false)}>
                <Modal.Header className="border-0" closeButton={true}/>
                <Modal.Body>
                    Unfortunately, something went wrong. Please try again later.
                </Modal.Body>
                <Modal.Footer className="border-0" style={{justifyContent: "center"}}>
                    <S.StyledNoDevicesOrderButton onClick={() => setShowError(false)}>
                        Ok
                    </S.StyledNoDevicesOrderButton>
                </Modal.Footer>
            </S.StyledEditRoleModal>
        )
    }

    return (
        <S.StyledDashboardContainer>
            <SideBar currentComponent={organizationBoard}/>

            {showError && renderModalWithError()}
            <S.StyledMainContainer>
                <S.StyledTopBar>
                    <S.StyledMainHeader>
                        <Col md={3}>
                            <S.StyledMainHeader style={{width: "130px"}}>Organization Settings</S.StyledMainHeader>
                        </Col>
                        {isSuperAdmin(user) && (
                            <Col md={4}>
                                <S.StyledUserRoleSelect value={organizationId}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOrganizationId(event.target.value)}
                                >
                                    <S.StyledUserRoleOption value={""}>Pick an organization</S.StyledUserRoleOption>
                                    {organizationsList.map((org) => {
                                        return (
                                            <S.StyledUserRoleOption value={org.id}>
                                                {org.name}
                                            </S.StyledUserRoleOption>
                                        )
                                    })}
                                </S.StyledUserRoleSelect>
                            </Col>
                        )}
                        {isOrgAdmin(user) && !isEmpty(portalURL) && (
                            <Col md={4} style={{width: 'fit-content'}}>
                                <SU.StyledLink onClick={onNavigateToPortal}>
                                    Customer portal settings
                                </SU.StyledLink>
                            </Col>
                        )}
                    </S.StyledMainHeader>
                </S.StyledTopBar>
                <S.StyledCenterContainer>
                    <S.StyledProfileInfoContainer style={{top: "100px"}}>
                        <Tabs id="settings-tabs" activeKey={key} onSelect={(key) => key && setKey(key)}>
                            <Tab eventKey="commonSettings" title="Common">
                                <Form>
                                    <Form.Group controlId={formBasicFullName}>
                                        <SU.StyledRegistrationLabel>Organization name</SU.StyledRegistrationLabel>
                                        <SU.StyledRegistrationInput type="input"
                                                                    value={name}
                                                                    isInvalid={!!errors.organizationName}
                                                                    disabled={isLoading}
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                        setName(event.target.value);
                                                                        setCommonTouch(true);
                                                                    }}
                                        />
                                        <S.StyledFeedback type="invalid">{errors.organizationName}</S.StyledFeedback>
                                    </Form.Group>
                                    <Form.Group controlId={formBasicEmail}>
                                        <SU.StyledRegistrationLabel>Email</SU.StyledRegistrationLabel>
                                        <SU.StyledRegistrationInput type="input"
                                                                    value={email}
                                                                    isInvalid={!!errors.email}
                                                                    disabled={isLoading}
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                        setEmail(event.target.value);
                                                                        setCommonTouch(true);
                                                                    }}

                                        />
                                        <S.StyledFeedback type="invalid">{errors.email}</S.StyledFeedback>
                                    </Form.Group>
                                    <Form.Group controlId={formBasicPhoneNumber}>
                                        <SU.StyledRegistrationLabel>Phone</SU.StyledRegistrationLabel>
                                        <SU.StyledRegistrationInput type="input"
                                                                    value={phoneNumber}
                                                                    isInvalid={!!errors.mobileNumber}
                                                                    disabled={isLoading}
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                        setPhoneNumber(event.target.value);
                                                                        setCommonTouch(true);
                                                                    }}
                                        />
                                        <S.StyledFeedback type="invalid">{errors.mobileNumber}</S.StyledFeedback>
                                    </Form.Group>
                                    {organization && organization.contactUser && (
                                        <div>
                                            <Form.Group controlId={formBasicOrganizationCreatorEmail}>
                                                <SU.StyledRegistrationLabel>
                                                    Organization creator email
                                                </SU.StyledRegistrationLabel>
                                                <SU.StyledRegistrationInput type="input"
                                                                            value={get(organization, "contactUser.user.email", "Not provided")}
                                                                            disabled={true}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId={formBasicOrganizationCreatorName}>
                                                <SU.StyledRegistrationLabel>
                                                    Organization creator name
                                                </SU.StyledRegistrationLabel>
                                                <SU.StyledRegistrationInput type="input"
                                                                            value={get(organization, "contactUser.user.name", "Not provided")}
                                                                            disabled={true}
                                                />
                                            </Form.Group>
                                        </div>
                                    )}
                                </Form>
                            </Tab>
                            <Tab eventKey="shippingAddress" title="Shipping address">
                                <Form>
                                    <Form.Group controlId={formBasicCountry}>
                                        <SU.StyledRegistrationLabel>Country</SU.StyledRegistrationLabel>
                                        <S.StyledUserRoleSelect value={country}
                                                                disable={isLoading}
                                                                style={{overflowY: "scroll"}}
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                    setCountry(event.target.value);
                                                                    setCommonTouch(true);
                                                                }}
                                        >
                                            {
                                                //@ts-ignore
                                                Object.keys(CountryCodes).map((key: CountryKey) => {
                                                    return (
                                                        <S.StyledUserRoleOption
                                                            value={CountryCodes[key]}>{String(key)}</S.StyledUserRoleOption>
                                                    )
                                                })}
                                        </S.StyledUserRoleSelect>
                                        <S.StyledFeedback type="invalid">{errors.country}</S.StyledFeedback>
                                    </Form.Group>
                                    <Form.Group controlId={formBasicAddress}>
                                        <SU.StyledRegistrationLabel>Address</SU.StyledRegistrationLabel>
                                        <SU.StyledRegistrationInput type="input"
                                                                    value={street}
                                                                    isInvalid={!!errors.street}
                                                                    disabled={isLoading}
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                        setStreet(event.target.value);
                                                                        setAddressTouch(true);
                                                                    }}

                                        />
                                        <S.StyledFeedback type="invalid">{errors.street}</S.StyledFeedback>
                                    </Form.Group>
                                    <SU.StyledBottomOrganizationSection>
                                        <Form.Group controlId={formBasicCity}>
                                            <SU.StyledRegistrationLabelSmall>City</SU.StyledRegistrationLabelSmall>
                                            <SU.StyledRegistrationInputSmall type="input"
                                                                             value={city}
                                                                             isInvalid={!!errors.city}
                                                                             disabled={isLoading}
                                                                             onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                                 setCity(event.target.value);
                                                                                 setAddressTouch(true);
                                                                             }}
                                            />
                                            <S.StyledFeedback type="invalid">{errors.city}</S.StyledFeedback>
                                        </Form.Group>
                                        <Form.Group controlId={formBasicZip}>
                                            <SU.StyledRegistrationLabelSmall>Post code /
                                                Zip</SU.StyledRegistrationLabelSmall>
                                            <SU.StyledRegistrationInputSmall type="input"
                                                                             value={postalCode}
                                                                             isInvalid={!!errors.postalCode}
                                                                             disabled={isLoading}
                                                                             onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                                 setPostalCode(event.target.value);
                                                                                 setAddressTouch(true);
                                                                             }}
                                            />
                                            <S.StyledFeedback type="invalid">{errors.postalCode}</S.StyledFeedback>
                                        </Form.Group>
                                    </SU.StyledBottomOrganizationSection>
                                </Form>
                            </Tab>
                        </Tabs>
                        <S.StyledProfileButtonsFrame style={getNotValidMargin()}>
                            <S.StyledProfileSaveButton style={{fontSize: "revert"}}
                                                       onClick={onUpdateOrganization}
                                                       disabled={isLoading || !(commonTouch || addressTouch)}
                            >
                                {isLoading && (<SUser.StyledSingInSpinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />)}
                                Save changes
                            </S.StyledProfileSaveButton>
                        </S.StyledProfileButtonsFrame>

                    </S.StyledProfileInfoContainer>
                </S.StyledCenterContainer>
            </S.StyledMainContainer>
        </S.StyledDashboardContainer>
    );
}

export default OrganizationSettings;
