import styled from '@emotion/styled';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from "react-router-dom";
import background_registration from "../../images/background_registration.png";
import company_logo from "../../images/company_logo.png";
import company_name from "../../images/company_name.png";
import { deviceBreakpoints } from '../../constants/media-breakpoints';

export const RegistrationCol = styled(Col as any)``;
export const RegistrationContainer = styled(Container as any)`
    margin: initial;
    width: 100vw;
    max-width: 100%;
    padding: initial;

    @media ${deviceBreakpoints.tablet} { 
        margin: 0 auto;
    }
`;

export const StyledRegistrationForm = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: 1920px;
    max-height: 820px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background: #14518e;
`;

export const StyledRegistrationFrame = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    max-width: 552px;
    width: 100%;
    // height: 100vh;
    min-height: 100vh;
    background: #FFFFFF;

    @media ${deviceBreakpoints.laptopS} { 
        max-width: 420px;
        display: flex;
        justify-content: center;
    }

    @media ${deviceBreakpoints.tablet} { 
        max-width: 100%;
        display: flex;
        justify-content: center;
    }
`;

export const StyledRegistrationImageBlock = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    @media ${deviceBreakpoints.tablet} { 
        display: none
    }
`;

export const StyledRegistrationImage = styled.div`
    position: relative;
    flex-grow: 1;
    max-width: 100%;
    height: 100%;
    background: url(${background_registration}) center / cover no-repeat;

    @media ${deviceBreakpoints.laptopL} { 
        background: url(${background_registration}) center / contain no-repeat;
    }
`;

export const StyledRegistrationGroup = styled.div`
display: flex;
flex-direction: column;
margin: 0 auto;
position: relative;
width: 360px;
height: 100%;

@media ${deviceBreakpoints.laptopS} { 
    width:290px;
}
`;

export const StyledRegistrationData = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 20px;

position: relative;
width: 360px;
height: 508px;

@media ${deviceBreakpoints.laptopS} { 
    width: 290px;
}
`;

export const StyledRegistrationInput = styled(Form.Control as any)`
/* Base input */


box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: center;
padding: 12px 8px 12px 12px;
gap: 8px;

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;

width: 360px;
height: 48px;

/* White */

background: #FFFFFF;
/* Slate/Slate-200 */

border: 1px solid #E2E8F0;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;

@media ${deviceBreakpoints.laptopS} { 
    width: 290px;
}
`;

export const StyledAuthInput = styled(Form.Control as any)`
/* Base input */


box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: center;
padding: 12px 8px 12px 12px;
gap: 8px;

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;

width: 360px;
height: 48px;

/* White */

background: #FFFFFF;
/* Slate/Slate-200 */

border: 1px solid #E2E8F0;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;

@media ${deviceBreakpoints.laptopS} { 
    width: 290px;
}

`;

export const StyledRegistrationInputSmall = styled(Form.Control as any)`
box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: center;
padding: 12px 8px 12px 12px;
gap: 8px;

width: 176px;
height: 48px;

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;

/* White */

background: #FFFFFF;
/* Slate/Slate-200 */

border: 1px solid #E2E8F0;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 1;
`;

export const StyledRegistrationLabel = styled(Form.Label as any)`
/* {Label} */


height: 16px;

/* Paragraph 2 (500)

Used in small buttons, form field labels and table headers.
*/
font-family: 'SF Pro';
font-style: normal;
font-weight: 510;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */


/* Slate/Slate-600 */

color: #475569;


/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
`;

export const StyledRegistrationLabelSmall = styled.div`
width: 176px;
height: 16px;

/* Paragraph 2 (500)

Used in small buttons, form field labels and table headers.
*/
font-family: 'SF Pro';
font-style: normal;
font-weight: 510;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */


/* Slate/Slate-600 */

color: #475569;


/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
`;

export const StyledBottomOrganizationSection = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
padding-top: 12px;
gap: 8px;

width: 360px;
height: 72px;


/* Inside auto layout */

flex: none;
order: 5;
align-self: stretch;
flex-grow: 0;

@media ${deviceBreakpoints.tablet} { 
    width: 290px;
    flex-wrap: wrap;
    height: auto;
}

`;

export const StyledCaption = styled.div`
/* Create account */


width: 152px;
height: 28px;

/* Heading 5 */

font-family: 'SF Pro';
font-style: normal;
font-weight: 590;
font-size: 21px;
line-height: 28px;
/* identical to box height, or 133% */

display: flex;
align-items: center;

/* Slate/Slate-900 */

color: #0F172A;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyledSubmitButton = styled(Button as any)`
/* Base button/Lightmode */


/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 32px;
gap: 8px;

width: 360px;
height: 48px;

/* Primary/Primary-500 */

background: #2383FE;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

@media ${deviceBreakpoints.laptopS} { 
    width: 290px;
}
`;

export const StyledBottomText = styled.div`
/* Already have an account? Sign in */


// width: 360px;
height: 20px;

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */


/* Slate/Slate-900 */

color: #0F172A;


/* Inside auto layout */

flex: none;
order: 6;
align-self: stretch;
flex-grow: 0;
`;

export const StyledImageText = styled.div`
/* Already have an account? Sign in */

position: absolute;
bottom: 100px;
left: 50%;
transform: translate(-50%, 0);
height: 20px;

text-align: center;
font-family: 'SF Pro';
font-style: normal;
font-weight: 590;
font-size: 21px;
line-height: 28px;
/* identical to box height, or 133% */

/* Slate/Slate-900 */

color: #0F172A;
`;

export const StyledTopLogo = styled.div`
position: relative;
width: 180px;
height: 40px;
margin-top: 40px;
margin-bottom: 100px;
`;

export const StyledCompanyLogo = styled.div`
position: absolute;
width: 37.02px;
height: 34.84px;
left: 0px;
top: 3px;

background: url(${company_logo});
`;

export const StyledCompanyName = styled.div`
position: absolute;
width: 142.98px;
height: 34.84px;
left: 37.02px;
top: 3px;

background: url(${company_name});
`;

export const StyledLoginButton = styled(Button as any)`
/* Base button/Lightmode */


/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 32px;
gap: 8px;

width: 360px;
height: 48px;

/* Primary/Primary-500 */

background: #2383FE;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

@media ${deviceBreakpoints.laptopS} { 
    width: 290px;
}
`;

export const StyledBaseLightModeButton = styled(Button as any)`
/* Base button/Lightmode */


box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 32px;
gap: 8px;

width: 360px;
height: 48px;

/* White */

background: #FFFFFF;
color: #2383FE;
/* Primary/Primary-100 */

border: 1px solid #D2EEFE;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

@media ${deviceBreakpoints.laptopS} { 
    width: 290px;
}
`;

export const StyledLink = styled(Link as any)`
/* Forgot your password? */


// width: 360px;
height: 20px;

font-family: 'SF Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

text-decoration-line: underline;

color: #2383FE;


/* Inside auto layout */

flex: none;
order: 5;
align-self: stretch;
flex-grow: 0;
`;

export const StyledSingInSpinner = styled(Spinner as any)``;

export const StyledForgotPasswordCaption = styled.div`
width: 167px;
height: 28px;

/* Heading 5 */

font-family: 'SF Pro';
font-style: normal;
font-weight: 590;
font-size: 21px;
line-height: 28px;
/* identical to box height, or 133% */

display: flex;
align-items: center;

/* Slate/Slate-900 */

color: #0F172A;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyledOrganizationAdministratorCaption = styled.div`
/* Organization administrator */

width: 182px;
height: 32px;

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 32px;
/* identical to box height, or 229% */

color: #000000;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;
`;
