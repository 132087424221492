import React, {useEffect, useState} from 'react';
import * as S from "./styled-components";
import Form from "react-bootstrap/Form";
import {formBasicEmail, formBasicRestoreToken} from "../../constants";
import {RegistrationErrors} from "../../types";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectLoading, selectMessage} from "../../features/global/globalSlice";
import {isEmpty} from "lodash";
import {validateEmail} from "../../utils";
import {restorePasswordRequest, restorePasswordComplete} from "../../features/user/userThunks";
import {setMessage} from "../../features/global/globalSlice";

function ForgotPassword() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();


    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState<RegistrationErrors>({});
    const [tokenReceived, setTokenReceived] = useState(false);
    const [token, setToken] = useState("");

    const isLoading = useAppSelector(selectLoading);
    const errorMessage = useAppSelector(selectMessage);

    useEffect(() => {
        return () => {
            dispatch(setMessage(null));
        }
    }, []);

    useEffect(() => {
        if (errorMessage) {
            const fieldName: string = errorMessage.indexOf("token") >= 0 ? "restoreToken" : "email";
            setErrors({
                ...errors,
                [fieldName]: errorMessage
            })
        } else {
            setErrors({});
        }
    }, [errorMessage]);

    const validateData = (): boolean => {
        let tmpErrors = {};
        if (!tokenReceived) {
            if (isEmpty(email)) {
                tmpErrors = {...tmpErrors, email: "Email cannot be empty"};
            } else if (email.length > 100) {
                tmpErrors = {...tmpErrors, email: "Email should contain no more than 100 characters"};
            } else if (!validateEmail(email)) {
                tmpErrors = {
                    ...tmpErrors,
                    email: "Email contains invalid characters"
                };
            }
        } else {
            if (isEmpty(token)) {
                tmpErrors = {...tmpErrors, restoreToken: "Restore token cannot be empty"};
            }
        }

        let notValid = Object.keys(tmpErrors).length > 0;
        if (notValid) {
            setErrors(tmpErrors);
        } else {
            setErrors({});
        }

        return notValid;
    }

    const onResetPassword = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!validateData()) {
            if (!tokenReceived) {
                const {payload} = await dispatch(restorePasswordRequest(email));
                setTokenReceived(Boolean(payload));
            } else {
                const {payload} = await dispatch(restorePasswordComplete({email, token}));
                if (Boolean(payload)) {
                    navigate("/sign-in");
                }
            }
        }
    }

    return (
        <S.RegistrationContainer>
            <S.StyledRegistrationForm>
                <S.StyledRegistrationFrame>
                    <S.StyledRegistrationGroup>
                        <S.StyledTopLogo>
                            <S.StyledCompanyLogo/>
                            <S.StyledCompanyName/>
                        </S.StyledTopLogo>
                        <Form onSubmit={onResetPassword}>
                            <S.StyledRegistrationData>
                                <S.StyledForgotPasswordCaption>Forgot Password</S.StyledForgotPasswordCaption>
                                <Form.Group controlId={formBasicEmail}>
                                    <S.StyledRegistrationLabel>Email</S.StyledRegistrationLabel>
                                    <S.StyledRegistrationInput type="email"
                                                            disabled={isLoading || tokenReceived}
                                                            placeholder="e.g. john@appleseed.com"
                                                            isInvalid={!!errors.email}
                                                            value={email}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                </Form.Group>
                                {tokenReceived && (
                                    <Form.Group controlId={formBasicRestoreToken}>
                                        <S.StyledRegistrationLabel>Restore token</S.StyledRegistrationLabel>
                                        <S.StyledRegistrationInput type="input"
                                                                disabled={isLoading}
                                                                placeholder="xxx xxx xxx xxx"
                                                                isInvalid={!!errors.restoreToken}
                                                                value={token}
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setToken(event.target.value)}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.restoreToken}</Form.Control.Feedback>
                                    </Form.Group>
                                )}
                                <S.StyledLoginButton type="submit" disabled={isLoading}>
                                    {isLoading && (<S.StyledSingInSpinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />)}
                                    {tokenReceived ? "Send new password" : "Reset password"}
                                </S.StyledLoginButton>
                                <S.StyledLink to="/sign-in">
                                    Go back to sign in
                                </S.StyledLink>
                            </S.StyledRegistrationData>
                        </Form>
                    </S.StyledRegistrationGroup>
                </S.StyledRegistrationFrame>
                <S.StyledRegistrationImageBlock>
                    <S.StyledRegistrationImage/>
                    <S.StyledImageText>
                        Online asset tracing service made of recycled ocean plastic
                    </S.StyledImageText>
                </S.StyledRegistrationImageBlock>
            </S.StyledRegistrationForm>
        </S.RegistrationContainer>
    )
}

export default ForgotPassword;
