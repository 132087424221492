export const devicesBoard = "devicesBoard";
export const usersBoard = "usersBoard";
export const eventsBoard = "eventsBoard";
export const profileBoard = "profileBoard";
export const organizationBoard = "organizationBoard";
export const dashBoard = "dashBoard";

export const ORG_ADMIN = "ORG_ADMIN";
export const ORG_MEMBER = "ORG_MEMBER";
export const SUPERUSER = "SUPERUSER";
