import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {get, isEqual} from "lodash";
import {useCookies} from "react-cookie";
import * as S from "./styled-components";
import {devicesBoard, eventsBoard, organizationBoard, profileBoard, usersBoard} from "../../constants/dashboard";
import {isOrgAdmin, isSuperAdmin, saveToken} from "../../utils";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {clearOrganizationData} from "../../features/organization/organizationSlice";
import {clearUserData, selectAuthorizedUser} from "../../features/user/userSlice";
import {clearGlobalData} from "../../features/global/globalSlice"
import {clearDevicesData} from "../../features/device/deviceSlice";

interface ISideBarProps {
    currentComponent: string
}

function SideBar(props: ISideBarProps) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [phoneMenuActive, setPhoneMenuActive] = useState(false);
    const [cookie, setCookie, removeCookie] = useCookies(["token"]);

    const toggleMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (phoneMenuActive)
            setPhoneMenuActive(false)
        else {
            setPhoneMenuActive(true)
        }
    }

    useEffect(() => {
        const handleWindowResize = () => {
            if (Number(window.innerWidth) > 500) {
                setPhoneMenuActive(false)
            }
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const user = useAppSelector(selectAuthorizedUser);

    const setActiveRoad = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        switch (event.currentTarget.id) {
            case devicesBoard:
                navigate("/devices");
                break;
            case usersBoard:
                navigate("/users");
                break;
            case eventsBoard:
                navigate("/events");
                break;
            case profileBoard:
                navigate("/profile");
                break;
            case organizationBoard:
                navigate(isSuperAdmin(user) ? "/organizations-list" : "/organization/commonSettings");
                break;
        }
    }

    const setActiveMenuElement = (currentElement: string) => {
        return isEqual(currentElement, props.currentComponent) ? {
            background: "#0B3493",
            borderRadius: "8px",
            color: "#7AC0FE"
        } : {};
    }

    const setPhoneMenuActiveStyle = () => {
        return phoneMenuActive ? {display: 'block'} : {};
    }

    const onSignOut = () => {
        removeCookie("token", {path: "/"});
        saveToken("");
        dispatch(clearOrganizationData());
        dispatch(clearUserData());
        dispatch(clearGlobalData());
        dispatch(clearDevicesData())

        navigate("/sign-in")
    }


    return (
        <S.StyledSideBar>
            <S.StyledNavigationTopSection>
                <S.StyledTopContainer>
                    <S.StyledBrandContainer>
                        <S.StyledCompanyNameWithLogo/>
                    </S.StyledBrandContainer>
                    <S.StyledMenuIcon onClick={toggleMenu}></S.StyledMenuIcon>
                </S.StyledTopContainer>
                <S.StyledNavContainer style={setPhoneMenuActiveStyle()}>
                    <S.StyledNavSectionTop>
                        <S.StyledNavigationDevices id={devicesBoard} onClick={setActiveRoad}
                                                   style={setActiveMenuElement(devicesBoard)}>
                            <S.StyledNavigationIcon>
                                <S.StyledIcon className="fa-solid fa-microchip" style={{color: "#ffffff"}}/>
                            </S.StyledNavigationIcon>
                            <S.StyledDevicesCaption>Devices</S.StyledDevicesCaption>
                        </S.StyledNavigationDevices>

                        <S.StyledNavigationUsers id={usersBoard} onClick={setActiveRoad}
                                                 style={setActiveMenuElement(usersBoard)}>
                            <S.StyledNavigationIcon>
                                <S.StyledIcon className="fa-solid fa-users" style={{color: "#ffffff"}}/>
                            </S.StyledNavigationIcon>
                            <S.StyledUsersCaption>Users</S.StyledUsersCaption>
                        </S.StyledNavigationUsers>

                        {(isSuperAdmin(user) || isOrgAdmin(user)) && (
                            <S.StyledNavigationEvents id={eventsBoard} onClick={setActiveRoad}
                                                      style={setActiveMenuElement(eventsBoard)}>
                                <S.StyledNavigationIcon>
                                    <S.StyledIcon className="fa-solid fa-list" style={{color: "#ffffff"}}/>
                                </S.StyledNavigationIcon>
                                <S.StyledEventsCaption>Event log</S.StyledEventsCaption>
                            </S.StyledNavigationEvents>
                        )}
                    </S.StyledNavSectionTop>

                    <S.StyledNavigationSectionBottom>

                        <S.StyledNavigationHeading>
                            <S.StyledHeadingCaption>Settings</S.StyledHeadingCaption>
                        </S.StyledNavigationHeading>

                        <S.StyledNavigationProfile id={profileBoard} onClick={setActiveRoad}
                                                   style={setActiveMenuElement(profileBoard)}>
                            <S.StyledNavigationIcon>
                                <S.StyledIcon className="fa-solid fa-gear" style={{color: "#ffffff"}}/>
                            </S.StyledNavigationIcon>
                            <S.StyledProfileCaption>Profile</S.StyledProfileCaption>
                        </S.StyledNavigationProfile>

                        <S.StyledNavigationOrganization id={organizationBoard} onClick={setActiveRoad}
                                                        style={setActiveMenuElement(organizationBoard)}>
                            <S.StyledNavigationIcon>
                                <S.StyledIcon className="fa-solid fa-building" style={{color: "#ffffff"}}/>
                            </S.StyledNavigationIcon>
                            <S.StyledOrganizationCaption>Organization{isSuperAdmin(user) ? "s" : ""}</S.StyledOrganizationCaption>
                        </S.StyledNavigationOrganization>
                    </S.StyledNavigationSectionBottom>

                    <S.StyledSideBarFrame/>

                    <S.StyledNavigationSigOutLink onClick={onSignOut}>
                        <S.StyledNavigationIcon>
                            <S.StyledIcon className="fa-solid fa-right-from-bracket" style={{color: "#ffffff"}}/>
                        </S.StyledNavigationIcon>
                        <S.StyledSignOutCaption>Sign out</S.StyledSignOutCaption>
                    </S.StyledNavigationSigOutLink>

                </S.StyledNavContainer>

            </S.StyledNavigationTopSection>
            <S.StyledBlur style={setPhoneMenuActiveStyle()} onClick={toggleMenu}></S.StyledBlur>
        </S.StyledSideBar>
    )
}

export default SideBar;
