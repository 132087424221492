import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducer from "../features/user/userSlice";
import registrationReducer from "../features/registration/registrationSlice";
import globalReducer from "../features/global/globalSlice";
import organizationReducer from "../features/organization/organizationSlice";
import deviceReducer from "../features/device/deviceSlice"
import fileReducer from "../features/file/fileSlice"

export const store = configureStore({
  reducer: {
    user: userReducer,
    registration: registrationReducer,
    global: globalReducer,
    organization: organizationReducer,
    device: deviceReducer,
    file: fileReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
