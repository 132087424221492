import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import * as S from "./styled-components"
import * as SU from "../user/styled-components"
import {usersBoard} from "../../constants/dashboard";
import SideBar from "./sideBar";
import {formBasicEmail, formBasicSelectOrganization, formBasicUserRole} from "../../constants";
import {RegistrationErrors} from "../../types";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectLoading, selectMessage, setMessage} from "../../features/global/globalSlice";
import * as SUser from "../user/styled-components";
import {isEmpty, isEqual} from "lodash";
import {getInvalidFiledName, isActiveOrg, isOrgAdmin, isSuperAdmin, validateEmail} from "../../utils";
import {selectAuthorizedUser, selectUserOperationStatus, setStatus} from "../../features/user/userSlice";
import {sendInvite} from "../../features/user/userThunks";
import {selectListOfOrganizations, selectOrganization} from "../../features/organization/organizationSlice";
import {useNavigate} from "react-router-dom";

function InviteUser() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [role, setRole] = useState("ORG_MEMBER");
    const [organizationId, setOrganizationId] = useState("");
    const [errors, setErrors] = useState<RegistrationErrors>({});
    const [showSuccess, setShowSuccess] = useState(false);

    const isLoading = useAppSelector(selectLoading);
    const status = useAppSelector(selectUserOperationStatus);
    const organization = useAppSelector(selectOrganization);
    const errorMessage = useAppSelector(selectMessage);
    const organizationsList = useAppSelector(selectListOfOrganizations);
    const user = useAppSelector(selectAuthorizedUser);

    useEffect(() => {
        if (isEqual(status, "loaded")) {
            setShowSuccess(true);
        }

        return () => {
            dispatch(setMessage(null));
        }
    }, [status]);

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                clearSession();
            }, 2000);
        }
    }, [showSuccess]);

    useEffect(() => {
        if (!isEmpty(errorMessage)) {
            let fieldName = getInvalidFiledName(errorMessage);
            fieldName = isEqual(fieldName, "email") ? "email" : fieldName;
            setErrors({
                ...errors,
                [fieldName]: errorMessage
            });
        }
    }, [errorMessage]);

    const clearSession = () => {
        dispatch(setStatus("idle"));
        setEmail("");
        setOrganizationId("");
        setRole("ORG_MEMBER");
        setShowSuccess(false);
    }

    const validate = (): boolean => {
        let tmpErrors = {};

        if (isEmpty(email)) {
            tmpErrors = {...tmpErrors, email: "Email cannot be empty"};
        } else if (email.length > 100) {
            tmpErrors = {...tmpErrors, email: "Email should contain no more than 100 characters"};
        } else if (!validateEmail(email)) {
            tmpErrors = {
                ...tmpErrors,
                email: "Email contains invalid characters"
            };
        }

        if (isEmpty(role)) {
            tmpErrors = {...tmpErrors, role: "Access level cannot be empty"};
        }

        if (isSuperAdmin(user) && isEqual(organizationId, "")) {
            tmpErrors = {...tmpErrors, organizationId: "You must pick organization"}
        }

        let notValid = Object.keys(tmpErrors).length > 0;
        if (notValid) {
            setErrors(tmpErrors);
        } else {
            setErrors({});
        }

        return notValid;
    }

    const onSendInvite = async () => {
        if (!validate() && ((organization && organization.id) || organizationId)) {
            const orgId = isSuperAdmin(user) ? organizationId : String(organization && organization.id);
            await dispatch(sendInvite({email, role, orgId}));
        }
    }

    return (
        <S.StyledDashboardContainer>
            <SideBar currentComponent={usersBoard}/>
            <S.StyledMainContainer>
                <S.StyledTopBar>
                    <S.StyledMainHeader>
                        <S.StyledUsersHeaderCaption>
                            <span style={{color: "#94A3B8", cursor: "pointer"}}
                                  onClick={() => navigate("/users")}>Users</span> / Invite
                        </S.StyledUsersHeaderCaption>
                    </S.StyledMainHeader>
                </S.StyledTopBar>
                <S.StyledCenterContainer>
                    <S.StyledProfileHeader>
                        <S.StyledHeaderCaption>Invite users</S.StyledHeaderCaption>
                    </S.StyledProfileHeader>

                    <S.StyledProfileInfoContainer>
                        <Form>
                            <Form.Group id={formBasicEmail}>
                                <SU.StyledRegistrationLabel>Email</SU.StyledRegistrationLabel>
                                <SU.StyledRegistrationInput type="input"
                                                            value={email}
                                                            isInvalid={!!errors.email}
                                                            disabled={isLoading}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                                />
                                <S.StyledFeedback type="invalid">{errors.email}</S.StyledFeedback>
                            </Form.Group>
                            {isSuperAdmin(user) && (
                                <Form.Group controlId={formBasicSelectOrganization}>
                                    <SU.StyledRegistrationLabel>Organization</SU.StyledRegistrationLabel>
                                    <S.StyledUserRoleSelect value={organizationId}
                                                            isInvalid={!!errors.organizationId}
                                                            disabled={isLoading}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOrganizationId(event.target.value)}
                                    >
                                        <S.StyledUserRoleOption value={""}>---</S.StyledUserRoleOption>
                                        {organizationsList.map((org) => {
                                            if (isActiveOrg(org)) {
                                                return (
                                                    <S.StyledUserRoleOption value={org.id}>
                                                        {org.name}
                                                    </S.StyledUserRoleOption>
                                                )
                                            }
                                        })}
                                    </S.StyledUserRoleSelect>
                                    <S.StyledFeedback type="invalid">{errors.organizationId}</S.StyledFeedback>
                                </Form.Group>
                            )}
                            <Form.Group id={formBasicUserRole}>
                                <SU.StyledRegistrationLabel>Access level</SU.StyledRegistrationLabel>
                                <S.StyledUserRoleSelect value={role}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRole(event.target.value)}
                                                        disabled={isLoading}
                                                        isInvalid={!!errors.role}
                                >
                                    <S.StyledUserRoleOption value={"ORG_MEMBER"}>Organization
                                        member</S.StyledUserRoleOption>
                                    <S.StyledUserRoleOption value={"ORG_ADMIN"}>Organization
                                        admin</S.StyledUserRoleOption>
                                </S.StyledUserRoleSelect>
                                <S.StyledFeedback type="invalid">{errors.role}</S.StyledFeedback>
                            </Form.Group>

                            <S.StyledProfileButtonsFrame>
                                <S.StyledProfileSaveButton style={{fontSize: "revert"}} onClick={onSendInvite}>
                                    {isLoading && (<SUser.StyledSingInSpinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />)}
                                    Send invite
                                </S.StyledProfileSaveButton>
                            </S.StyledProfileButtonsFrame>

                            {showSuccess && (<div style={{paddingTop: "20px"}}>
                                <S.StyledUserInviteConfirm>
                                    <S.StyledConfirmIconWrapper>
                                        <S.StyleConfirmIcon>
                                            <S.StyledIcon style={{color: "#2FBC36"}}
                                                          className="fa-solid fa-circle-check"/>
                                        </S.StyleConfirmIcon>
                                    </S.StyledConfirmIconWrapper>
                                    <S.StyledUserInviteConfirmText>
                                        Invite has been sent to {email}
                                    </S.StyledUserInviteConfirmText>
                                </S.StyledUserInviteConfirm>
                            </div>)}
                        </Form>
                    </S.StyledProfileInfoContainer>
                </S.StyledCenterContainer>
            </S.StyledMainContainer>
        </S.StyledDashboardContainer>
    )
}

export default InviteUser;
