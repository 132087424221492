import {createAsyncThunk} from '@reduxjs/toolkit';
import {setLoading, setMessage} from "../global/globalSlice";
import {
    getOrganizationDevicesAPI,
    getDevicesFeaturesAPI,
    getPriceAPI,
    addDevicesAPI,
    getFreeDevicesAPI,
    removeDeviceFormOrganizationAPI,
    returnDeviceAPI, cancelPreOrderAPI,
    updateDeviceAPI,
    deleteDeviceAPI
} from "../../api";
import {head, isArray, isEqual} from "lodash";
import {
    AddDevicesParams, CancelOrderParams,
    CreateOrder, Device,
    DevicePaginationParams,
    IPaginationParams,
    OrderType,
    UnlinkDeviceFromOrg
} from "../../types";

export const getOrganizationDevices = createAsyncThunk("device/get-devices", async (arg: DevicePaginationParams, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await getOrganizationDevicesAPI(arg);
        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return {
                devices: response.data,
                quantity: response.quantity
            };
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during devices fetching(thunk)" : "An error occurred during devices fetching(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
})

export const getDevicesFeatures = createAsyncThunk("devices/features", async (arg: any, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await getDevicesFeaturesAPI();
        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return {
                devicesFeatures: response.data,
                freeDevices: response.freeDevices
            };
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during devices features fetching(thunk)" : "An error occurred during devices features fetching(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
})

export const getPreOrderInformation = createAsyncThunk("devices/pre-order", async (arg: CreateOrder, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await getPriceAPI(arg, true);
        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return response.data;
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during pre-order information fetching(thunk)" : "An error occurred during pre-order information fetching(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
})

export const createOrder = createAsyncThunk("devices/order", async (arg: CreateOrder, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await getPriceAPI(arg, false);
        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return {
                data: response.data,
                orderType: arg.orderType
            };
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during order creation(thunk)" : "An error occurred during order creation(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
})

export const addDevicesToOrganization = createAsyncThunk("devices/add", async (arg: AddDevicesParams, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await addDevicesAPI(arg);
        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return {
                data: response.data
            };
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during order creation(thunk)" : "An error occurred during order creation(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
})

export const getFreeDevices = createAsyncThunk("devices/free", async (arg: any, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await getFreeDevicesAPI();
        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return {
                data: response.data
            };
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during fetching free device(thunk)" : "An error occurred during fetching free device(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
})

export const unlinkDevice = createAsyncThunk("devices/unlink", async (arg: UnlinkDeviceFromOrg, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await removeDeviceFormOrganizationAPI(arg);
        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return {
                result: true
            };
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during unlinking devices(thunk)" : "An error occurred during unlinking devices(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
})

export const returnDevice = createAsyncThunk("devices/return", async (arg: string[], thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await returnDeviceAPI(arg);
        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return {
                result: true
            };
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during unlinking devices(thunk)" : "An error occurred during unlinking devices(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
})

export const cancelPreOder = createAsyncThunk("devices/cancel-pre-order", async (arg: CancelOrderParams, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await cancelPreOrderAPI(arg);
        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return {
                result: true
            };
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during unlinking devices(thunk)" : "An error occurred during unlinking devices(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
})

export const updateDevice = createAsyncThunk("devices/update", async(arg: Device, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await updateDeviceAPI(arg);
        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return {
                result: true,
            };
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during patching the device(thunk)" : "An error occurred during patching the device(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
})

export const deleteDevice = createAsyncThunk("devices/delete", async (arg: string, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await deleteDeviceAPI(arg);
        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return {
                result: true,
            };
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during patching the device(thunk)" : "An error occurred during patching the device(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
})
