import axios, { isAxiosError } from "axios";
import { isEqual, get } from "lodash";
import { IResponse } from "../types";
import config from "../config/api"

const {apiURL} = config.apiConfig;

interface IGetFileResponse extends IResponse {
    data?: string
}

export const getTermsOfServiceAPI = async (): Promise<IGetFileResponse> => {
    try {
        const URL = `${apiURL}/api/database-file/retrieve`;

        const {data, status, statusText} = await axios.get(URL, {
            responseType: 'arraybuffer',
          });
          // Convert to serializable data for serializableCheck
          const blobFile = new Blob([data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blobFile);

        if (isEqual(status, 200)) {
            return {
                success: true,
                data: url
            }
        } else {
            return {
                success: false,
                errors: [statusText]
            }
        }
    } catch(error) {
        console.error("An error occurred during fetching file(api)", error);
        let errorResponse: IGetFileResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during fetching file"]
        };
    }
}
