import config from "../config/api";
import {get, isEqual, isUndefined} from "lodash";
import {Organization, User} from "../types";
import {ORG_ADMIN, ORG_MEMBER, SUPERUSER} from "../constants/dashboard";
import {CountryCodes} from "../constants";
import React from "react";

export const getToken = () => {
    const {token} = config.apiConfig;
    return token;
}

export const saveToken = (token: string) => {
    config.apiConfig.token = token;
}

export const validateEmail = (email: string) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
export const validatePassword = (password: string) => /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,255}$/.test(password);
export const validateMobileNumber = (mobileNumber: string) => /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(mobileNumber);
export const getInvalidFiledName = (errorMessage: string | null) => errorMessage && errorMessage.toLowerCase().indexOf("password") >= 0 ? "password" : "email";
export const getInvalidFieldNameForOrganization = (errorMessage: string | null) => errorMessage && errorMessage.toLowerCase().indexOf("email") >= 0 ? "email" : "organizationName";

export const getUserRoleName = (input: string | undefined) => {
    if (isUndefined(input)) return null;
    if (isEqual(input, ORG_MEMBER)) {
        return "Member"
    } else if (isEqual(input, ORG_ADMIN)) {
        return "Administrator"
    } else {
        return "Super user"
    }
}

export const isOrgAdmin = (user: User | null): boolean => {
    return !!user && isEqual(user.role, ORG_ADMIN);
}

export const isSuperAdmin = (user: User | null): boolean => {
    return !!user && isEqual(user.role, SUPERUSER);
}

export const getCountryName = (value: string): string => {
    // @ts-ignore
    return <string>Object.keys(CountryCodes).find(key => CountryCodes[key] === value);
}

export const getId = (event: React.MouseEvent<HTMLDivElement>) => get(event, "target.parentNode.id", "");

// @ts-ignore
export const isActiveOrg = (org: Organization | null) => !isEqual(org, null) && isEqual(org.deletedAt, null);
