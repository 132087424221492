import {createAsyncThunk} from "@reduxjs/toolkit";
import {CreateUserRequest} from "../../types";
import {registerUserAPI} from "../../api";

export const createUser = createAsyncThunk("auth/registration", async (arg: CreateUserRequest, thinkAPI) => {
    try {

        const response = await registerUserAPI(arg);

        if (response.success && response.data) {
            return response.data.user;
        } else {

        }
    } catch(error) {

    }
});
