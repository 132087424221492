export const formBasicFullName = "formBasicFullName";
export const formBasicEmail = "formBasicEmail";
export const formBasicCurrentPassword = "formBasicCurrentPassword";
export const formBasicPassword = "formBasicPassword";
export const formBasicPasswordConfirm = "formBasicPasswordConfirm";
export const formBasicRestoreToken = "formBasicRestoreToken";
export const formBasicOrganizationName = "formBasicOrganizationName"
export const formBasicPhoneNumber = "formBasicPhoneNumber";
export const formBasicOrganizationNumber = "formBasicOrganizationNumber"
export const formBasicRegistrationDate = "formBasicRegistrationDate";
export const formBasicUserRole = "formBasicUserRole";
export const formBasicCountry = "formBasicCountry";
export const formBasicAddress = "formBasicAddress";
export const formBasicCity = "formBasicCity";
export const formBasicZip = "formBasicZip";
export const formBasicOrganizationCreatorEmail = "formBasicOrganizationCreatorEmail";
export const formBasicOrganizationCreatorName = "formBasicOrganizationCreatorName";
export const formBasicSelectOrganization = "formBasicSelectOrganization"
export const formBasicDevicesQuantity = "formBasicDevicesQuantity";

export const passwordValidationError = "Password should be at least 8 characters long with at least one uppercase letter and number"
