import {createAsyncThunk} from '@reduxjs/toolkit';
import {LogInInformation, CreateUserRequest, CompletePasswordRestoreArgs, User, SendUserInvite} from "../../types";
import {
    authorizeUserAPI,
    getUserByTokenAPI,
    completeRestorePasswordRequestAPI,
    registerUserAPI,
    restorePasswordRequestAPI,
    updateProfileRequestAPI, sendUserInviteAPI, deleteUserAPI
} from "../../api";
import {setLoading, setMessage} from "../global/globalSlice";
import {head, isArray} from "lodash";
import {saveToken} from "../../utils";


export const authorizeUser = createAsyncThunk("auth/auth", async (arg: LogInInformation, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));

        const response = await authorizeUserAPI(arg);

        thunkAPI.dispatch(setLoading(false));

        if (response.success && response.data) {
            return response.data;
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during authorization(thunk)" : "An error occurred during authorization(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
        }
    } catch(error) {
        thunkAPI.dispatch(setLoading(true));
        console.error("An error occurred during authorization(thunk)",error);
    }
});

export const getMe = createAsyncThunk("auth/me", async (arg: any, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));

        const response = await getUserByTokenAPI();

        thunkAPI.dispatch(setLoading(false));

        if (response.success && response.data) {
            return response.data;
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during authorization(thunk)" : "An error occurred during authorization(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
        }
    } catch(error) {
        thunkAPI.dispatch(setLoading(true));
        console.error("An error occurred during authorization(thunk)",error);
    }
});

export const registerUser = createAsyncThunk("auth/registration", async (arg: CreateUserRequest, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));

        const response = await registerUserAPI(arg);

        thunkAPI.dispatch(setLoading(false));

        if (response.success && response.data) {
            saveToken(response.data.accessToken);
            return response.data;
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during registration(thunk)" : "An error occurred during registration(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
        }
    } catch(error) {
        thunkAPI.dispatch(setLoading(false));
        console.error("An error occurred during registration(thunk)",error);
    }
})

export const restorePasswordRequest = createAsyncThunk("auth/restore-request", async (arg: string, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));

        const response = await restorePasswordRequestAPI(arg);

        thunkAPI.dispatch(setLoading(false));


        if (response.success) {
            return true;
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during registration(thunk)" : "An error occurred during registration(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch(error) {
        thunkAPI.dispatch(setLoading(false));
        console.error("An error occurred during password restoring(thunk)",error);
        return false;
    }
});

export const restorePasswordComplete = createAsyncThunk("auth/restore-complete", async (arg: CompletePasswordRestoreArgs, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));

        const response = await completeRestorePasswordRequestAPI(arg.email, arg.token);

        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return true;
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during registration(thunk)" : "An error occurred during registration(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch(error) {
        thunkAPI.dispatch(setLoading(false));
        return false;
    }
});

interface UpdateProfileParams {
    user: User,
    updateState: boolean
}

export const updateProfile = createAsyncThunk("user/update-profile", async (arg: UpdateProfileParams, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));

        const response = await updateProfileRequestAPI(arg.user);

        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return {
                data: response.data,
                updateState: arg.updateState
            };
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during profile updating(thunk)" : "An error occurred during profile updating(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch(error) {
        thunkAPI.dispatch(setLoading(false));
        return false;
    }
});

export const sendInvite = createAsyncThunk("user/send-invite", async (arg: SendUserInvite, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));

        const response = await sendUserInviteAPI(arg);

        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return true;
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during sending invite to the user(thunk)" : "An error occurred during sending invite to the user(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch(error) {
        thunkAPI.dispatch(setLoading(false));

        return false;
    }
})

export const deleteUser = createAsyncThunk("user/delete", async(arg: string, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));

        const response = await deleteUserAPI(arg);

        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return true;
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred deleting user(thunk)" : "An error occurred during deleting user(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch(error) {
        thunkAPI.dispatch(setLoading(false));

        return false;
    }
})
