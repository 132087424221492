import React, {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import {useNavigate} from 'react-router-dom';
import * as S from "./styled-components";
import {get, isEmpty, isEqual} from "lodash";
import {RegistrationErrors} from "../../types";
import {getInvalidFiledName, validateEmail, validatePassword} from "../../utils";
import {authorizeUser} from "../../features/user/userThunks";
import {selectLoading, selectMessage, setMessage} from "../../features/global/globalSlice"
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {formBasicEmail, formBasicPassword} from "../../constants";
import {selectAuthorizedUser} from "../../features/user/userSlice";
import {useCookies} from "react-cookie";
import {saveToken} from "../../utils";

function SignIn() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [cookie, setCookie] = useCookies(["token"]);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState<RegistrationErrors>({});

    const isLoading = useAppSelector(selectLoading);
    const errorMessage = useAppSelector(selectMessage);
    const authorizedUser = useAppSelector(selectAuthorizedUser);

    useEffect(() => {
        if (authorizedUser) {
            navigate("/dashboard");
        }
    }, [authorizedUser]);

    useEffect(() => {
        setErrors({
            ...errors,
            [getInvalidFiledName(errorMessage)]: errorMessage
        })
    }, [errorMessage]);

    useEffect(() => {
        return () => {
            dispatch(setMessage(null));
        }
    }, []);

    const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {id, value} = event.target;
        if (isEqual(id, formBasicEmail)) {
            setEmail(value);
        } else if (isEqual(id, formBasicPassword)) {
            setPassword(value);
        }
    }

    const onNavigateToRegistration = () => {
        navigate("/registration");
    }

    const onSignIn = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!validateData()) {
            const response = await dispatch((authorizeUser({email, password})));
            if (response.payload) {
                setCookie("token", get(response, "payload.accessToken"), {path: '/'});
                saveToken(get(response, "payload.accessToken", ""));
            }
        }
    }

    const validateData = (): boolean => {
        let tmpErrors = {};

        if (isEmpty(email)) {
            tmpErrors = {...tmpErrors, email: "Email cannot be empty"};
        } else if (email.length > 100) {
            tmpErrors = {...tmpErrors, email: "Email should contain no more than 100 characters"};
        } else if (!validateEmail(email)) {
            tmpErrors = {
                ...tmpErrors,
                email: "Email contains invalid characters"
            };
        }
        if (isEmpty(password)) {
            tmpErrors = {...tmpErrors, password: "Password cannot be empty"}
        } else if (!validatePassword(password)) {
            tmpErrors = {
                ...tmpErrors,
                password: "Incorrect password or email"
            }
        }
        let notValid = Object.keys(tmpErrors).length > 0;
        if (notValid) {
            setErrors(tmpErrors);
        } else {
            setErrors({});
        }

        return notValid;

    }

    return (
        <S.RegistrationContainer>
            <S.StyledRegistrationForm>
                <S.StyledRegistrationFrame>
                    <S.StyledRegistrationGroup>
                        <S.StyledTopLogo>
                            <S.StyledCompanyLogo/>
                            <S.StyledCompanyName/>
                        </S.StyledTopLogo>
                        <Form onSubmit={onSignIn}>
                            <S.StyledRegistrationData>
                                <S.StyledCaption>Sign In</S.StyledCaption>
                                <Form.Group controlId={formBasicEmail}>
                                    <S.StyledRegistrationLabel>Email</S.StyledRegistrationLabel>
                                    <S.StyledRegistrationInput type="email"
                                                            disabled={isLoading}
                                                            placeholder="e.g. john@appleseed.com"
                                                            isInvalid={!!errors.email}
                                                            value={email}
                                                            onChange={onChangeValue}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId={formBasicPassword}>
                                    <S.StyledRegistrationLabel>Password</S.StyledRegistrationLabel>
                                    <S.StyledRegistrationInput type="password"
                                                            disabled={isLoading}
                                                            placeholder="Enter your password"
                                                            isInvalid={!!errors.password}
                                                            value={password}
                                                            onChange={onChangeValue}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                </Form.Group>
                                <S.StyledLoginButton type='submit' disabled={isLoading}>
                                    {isLoading && (<S.StyledSingInSpinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />)}
                                    Log In
                                </S.StyledLoginButton>
                                <S.StyledBaseLightModeButton
                                    onClick={onNavigateToRegistration}
                                    disabled={isLoading}
                                >
                                    Create Account
                                </S.StyledBaseLightModeButton>
                                <S.StyledLink to="/password-forgot">
                                    Forgot your password?
                                </S.StyledLink>
                            </S.StyledRegistrationData>
                        </Form>
                    </S.StyledRegistrationGroup>
                </S.StyledRegistrationFrame>
                <S.StyledRegistrationImageBlock>
                    <S.StyledRegistrationImage/>
                    <S.StyledImageText>
                        Online asset tracing service made of recycled ocean plastic
                    </S.StyledImageText>
                </S.StyledRegistrationImageBlock>
            </S.StyledRegistrationForm>
        </S.RegistrationContainer>
    );
}

export default SignIn;
