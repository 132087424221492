import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import { getTermsOfServiceFile } from './fileThunks';

export interface FileState {
    fileURL: string | null;
    status: "idle" | "loading" | "failed" | "loaded" | "deleted" | "updated";
}

const initialState: FileState = {
    fileURL: null,
    status: "idle",
};

export const fileSlice = createSlice({
    name: "file",
    initialState,
    reducers: {
        setStatus: (state, action: PayloadAction<string>) => {
            state.status = "idle";
        },
        setFile: (state, action: PayloadAction<any>) => {
            state.fileURL = action.payload
        },
        clearFileData: (state) => {
            state.status = "idle";
            state.fileURL = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTermsOfServiceFile.rejected, (state) => {
                state.status = "failed"
            })
            .addCase(getTermsOfServiceFile.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) {
                    state.fileURL = action.payload;
                }
            })
            .addCase(getTermsOfServiceFile.pending, (state) => {
                state.status = "idle"
            })
    }
});

export const {setFile, setStatus, clearFileData} = fileSlice.actions;

export const getFileURL = (state: RootState) => state.file.fileURL;

export default fileSlice.reducer;