import React, {useEffect, useState} from "react";
import {Col, Row, Table, Modal, Button, Spinner} from "react-bootstrap"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import * as S from "./styled-components";
import SideBar from "./sideBar";
import {dashBoard, ORG_MEMBER, organizationBoard} from "../../constants/dashboard";
import {getCountryName, getId, isActiveOrg, isSuperAdmin} from "../../utils";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectAuthorizedUser, setStatus} from "../../features/user/userSlice";
import {
    selectListOfOrganizations,
    selectOrganizationsQuantity,
    selectOrganizationStatus
} from "../../features/organization/organizationSlice";
import {Organization} from "../../types";
import {get, isEmpty, isEqual} from "lodash"
import EditOrganizationModal from "./editOrganizationModal";
import {
    deleteOrganization,
    getAllOrganizations, restoreOrganization,
    updateOrganization
} from "../../features/organization/organizationThunks";
import {setOrganizationStatus} from "../../features/organization/organizationSlice";
import PaginationFooter from "../paginationFooter";
import {selectLoading, setLoading} from "../../features/global/globalSlice";
import * as SUser from "../user/styled-components";
import {useNavigate} from "react-router-dom";
import {getCustomerPortalURLAPI} from "../../api";
import {QueryOrderByOrgParam, QueryOrderByUserParam, SqlOrder} from "../../constants";
import {SortHeader} from "../sort-header";

function OrganizationsList() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const limit = 8;
    const [pageNumber, setPageNumber] = useState(1);
    const [showEdit, setShowEdit] = useState(false);
    const [currentPopover, setCurrentPopover] = useState<number | null>(null);
    const [organizationId, setOrganizationId] = useState("");
    const [pickedOrganization, setPickedOrganization] = useState<Organization | null>(null);
    const [loadedData, setLoadedData] = useState(false);
    const [confirmSoft, setConfirmSoft] = useState(false);
    const [confirmHard, setConfirmHard] = useState(false);
    const [showError, setShowError] = useState(false);

    const [order, setOrder] = useState("");
    const [orderBy, setOrderBy] = useState("");

    const user = useAppSelector(selectAuthorizedUser);
    const organizationsList = useAppSelector(selectListOfOrganizations);
    const organizationsQuantity = useAppSelector(selectOrganizationsQuantity);
    const isLoading = useAppSelector(selectLoading);
    const organizationStatus = useAppSelector(selectOrganizationStatus);

    const lastPage = Math.ceil(organizationsQuantity / limit);
    const offset = (pageNumber - 1) * limit; //+ 1;
    let endRange = (offset + limit - 1) > organizationsQuantity ? organizationsQuantity - 1 : offset + limit - 1;

    useEffect(() => {
        if (user && isSuperAdmin(user)) {
            fetchOrganizations();
        }
    }, [pageNumber, organizationId, order, orderBy]);

    useEffect(() => {
        if (isEqual(organizationStatus, "loaded")) {
            onHideEditBox(true);
            dispatch(setOrganizationStatus("idle"));

        } else if (isEqual(organizationStatus, "deleted") || isEqual(organizationStatus, "restored")) {
            dispatch(setOrganizationStatus("idle"));
            dispatch(setLoading(true));
            setTimeout(() => {
                dispatch(setLoading(false));
                onCloseConfirmation();
                fetchOrganizations();
            }, 250);
        }
    }, [organizationStatus]);

    async function fetchOrganizations() {
        await dispatch(getAllOrganizations({limit, offset, order, orderBy, withStripeUrl: true}));
    }

    const getFullAddress = (org: Organization) => {
        if (org && org.shippingAddress && org.shippingAddress.city && org.shippingAddress.postalCode && org.shippingAddress.city) {
            return org.shippingAddress.street + ", " + org.shippingAddress.postalCode + " " + org.shippingAddress.city
        } else {
            return "Not provided";
        }
    }


    const onEditOrganization = (event: React.MouseEvent<HTMLDivElement>) => {
        const orgId = getId(event);
        if (!isEqual(orgId, "")) {
            const org = organizationsList.filter((or) => isEqual(or.id, orgId))[0];
            if (org) {
                setShowEdit(true);
                setPickedOrganization(org);
                setCurrentPopover(null);
            }
        }
    }

    const onChangeOrder = (orderFiled: string, orderByField: string) => {
        let newOrder = "";
        if (isEqual(orderFiled, orderBy)) {
            switch (orderByField) {
                case SqlOrder.ASС:
                    newOrder = SqlOrder.DESC;
                    break;
                case SqlOrder.DESC:
                    newOrder = "";
                    break;
                case "":
                    newOrder = SqlOrder.ASС
                    break;
            }
        } else {
            newOrder = SqlOrder.ASС;
        }

        if (isEmpty(newOrder)) {
            setOrder("");
            setOrderBy("");
        } else {
            setOrder(newOrder);
            setOrderBy(orderFiled);
        }
    }

    const onHideEditBox = async (withUpdate: boolean) => {
        setPickedOrganization(null);
        setShowEdit(false);

        if (withUpdate) {
            await fetchOrganizations();
        }
    }

    const onUpdateOrganization = async (org: Organization) => {
        if (org && org.id) {
            await dispatch(updateOrganization({organization: org, id: org.id}));
        }
    }

    const onSoftDelete = async (event: React.MouseEvent<HTMLDivElement>) => {
        const id = getId(event);
        if (!isEqual(id, "")) {
            setOrganizationId(id);
            const org = organizationsList.filter((or) => isEqual(or.id, id))[0];
            if (org) {
                setConfirmSoft(true);
                setPickedOrganization(org);
                setCurrentPopover(null);
            }
        }
    }

    const onHardDelete = async (event: React.MouseEvent<HTMLDivElement>) => {
        const id = getId(event);
        if (!isEqual(id, "")) {
            setOrganizationId(id);
            const org = organizationsList.filter((or) => isEqual(or.id, id))[0];
            if (org) {
                setConfirmHard(true);
                setPickedOrganization(org);
                setCurrentPopover(null);
            }
        }
    }

    const onCheckSubscription = async (event: React.MouseEvent<HTMLDivElement>) => {
        const id = getId(event);
        if (!isEqual(id, "")) {
            const org = organizationsList.filter((or) => isEqual(or.id, id))[0];

            if (org && org.id) {
                setCurrentPopover(null);
                try {
                    const response = await getCustomerPortalURLAPI(id);

                    if (response.success) {
                        const URL = get(response, "data.url");
                        window.open(URL, "_blank", "noopener,noreferrer");
                    } else {
                        onNavigateErrorHandler();
                    }
                } catch (error) {
                    console.error(`An error occurred during obtaining link to Stripe portal for ord: [${get(org, "id")}]`, error);
                    onNavigateErrorHandler();
                }
            }
        }
    }

    const onNavigateErrorHandler = () => {
        setShowError(true);
    }

    const renderModalWithError = () => {
        return (
            <S.StyledEditRoleModal show={showError}
                                   centered={true}
                                   onHide={() => setShowError(false)}>
                <Modal.Header className="border-0" closeButton={true}/>
                <Modal.Body>
                    Unfortunately, something went wrong. Please try again later.
                </Modal.Body>
                <Modal.Footer className="border-0" style={{justifyContent: "center"}}>
                    <S.StyledNoDevicesOrderButton onClick={() => setShowError(false)}>
                        Ok
                    </S.StyledNoDevicesOrderButton>
                </Modal.Footer>
            </S.StyledEditRoleModal>
        )
    }

    const onCloseConfirmation = () => {
        setConfirmHard(false);
        setConfirmSoft(false);
        setOrganizationId("");
        setPickedOrganization(null);
    }

    const onDeleteConfirm = async (soft: boolean) => {
        if (!isEqual(organizationId, "")) {
            if (!soft) {
                await dispatch(deleteOrganization({id: organizationId, soft: soft}));
            } else if (isActiveOrg(pickedOrganization)) {
                await dispatch(deleteOrganization({id: organizationId, soft: soft}));
            } else {
                await dispatch(restoreOrganization(organizationId));
            }
        }
    }

    const onAddOrganization = () => {
        navigate("/add-organization");
    }

    const headerStyle = {padding: "16px 8px 16px 22px", gap: "4px", alignItems: "center"};

    const getClassForRow = (active: boolean) => active ? "" : "table-active";

    return (
        <S.StyledDashboardContainer>
            <SideBar currentComponent={organizationBoard}/>

            {showError && renderModalWithError()}

            {showEdit && (<EditOrganizationModal show={showEdit}
                                                 isLoading={isLoading}
                                                 organization={pickedOrganization}
                                                 onClose={onHideEditBox}
                                                 onUpdate={onUpdateOrganization}
            />)}
            <S.StyledMainContainer>
                <S.StyledEditRoleModal show={confirmSoft || confirmHard} onHide={onCloseConfirmation}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm {confirmSoft ? (isActiveOrg(pickedOrganization) ? "deactivation" : "activation") : "delete"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure that you want
                        to {confirmSoft ? (isActiveOrg(pickedOrganization) ? "deactivate " : "activate ") : "delete "}
                        <strong>{get(pickedOrganization, "name", "")}</strong>?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onCloseConfirmation}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => onDeleteConfirm(confirmSoft)}>
                            {confirmSoft ? (isActiveOrg(pickedOrganization) ? "Deactivate" : "Activate") : "Delete"}
                            {isLoading && (<SUser.StyledSingInSpinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />)}
                        </Button>
                    </Modal.Footer>
                </S.StyledEditRoleModal>
                <S.StyledTopBar>
                    <S.StyledMainHeader>
                        <Col md={1} style={{width: 'auto'}}>
                            <S.StyledUsersHeaderCaption>Organizations</S.StyledUsersHeaderCaption>
                        </Col>

                        {isSuperAdmin(user) && (
                            <Col md={1} style={{width: 'fit-content'}}>
                                <S.StyledInviteUserButton style={{width: "165px"}} onClick={onAddOrganization}>
                                    <S.StyledIcon className="fa-solid fa-plus" style={{color: "#ffffff"}}/>
                                    Add organization
                                </S.StyledInviteUserButton>
                            </Col>)
                        }
                    </S.StyledMainHeader>
                </S.StyledTopBar>
                <S.StyledCenterContainer>
                    <S.StyledProfileHeader>
                        <S.StyledHeaderCaption>Organizations</S.StyledHeaderCaption>
                    </S.StyledProfileHeader>
                    <S.StyledTableWrapper>
                        <Table style={{tableLayout: "fixed"}}>
                            <thead>
                            <S.StyledOrgsTR>
                                <S.StyledUsersTH style={headerStyle}>
                                    <SortHeader caption={"Name"}
                                                orderBy={orderBy}
                                                order={order}
                                                orderField={QueryOrderByOrgParam.NAME}
                                                onChangeOrder={onChangeOrder}
                                    />
                                </S.StyledUsersTH>
                                <S.StyledUsersTH style={headerStyle}>
                                    <SortHeader caption={"Country"}
                                                orderBy={orderBy}
                                                order={order}
                                                orderField={QueryOrderByOrgParam.COUNTRY}
                                                onChangeOrder={onChangeOrder}
                                    />
                                </S.StyledUsersTH>
                                <S.StyledUsersTH style={headerStyle}>
                                    <SortHeader caption={"Address"}
                                                orderBy={orderBy}
                                                order={order}
                                                orderField={QueryOrderByOrgParam.ADDRESS}
                                                onChangeOrder={onChangeOrder}
                                    />
                                </S.StyledUsersTH>
                                <S.StyledUsersTH style={headerStyle}>
                                    <SortHeader caption={"Phone"}
                                                orderBy={orderBy}
                                                order={order}
                                                orderField={QueryOrderByOrgParam.PHONE_NUMBER}
                                                onChangeOrder={onChangeOrder}
                                    />
                                </S.StyledUsersTH>
                                <S.StyledUsersTH style={headerStyle}>
                                    <SortHeader caption={"Email"}
                                                orderBy={orderBy}
                                                order={order}
                                                orderField={QueryOrderByOrgParam.EMAIL}
                                                onChangeOrder={onChangeOrder}
                                    />
                                </S.StyledUsersTH>
                                <S.StyledUsersTH style={headerStyle}>
                                    <SortHeader caption={"Status"}
                                                orderBy={orderBy}
                                                order={order}
                                                orderField={QueryOrderByOrgParam.DELETED_AT}
                                                onChangeOrder={onChangeOrder}
                                    />
                                </S.StyledUsersTH>
                                <S.StyledUsersActionTH style={{padding: "16px 8px 16px 8px", gap: "4px"}}/>
                            </S.StyledOrgsTR>
                            </thead>
                            <tbody>
                            {isLoading ? (
                                <tr>
                                    <td rowSpan={7} colSpan={7}>
                                        <div className="text-center py-5">
                                            <Spinner animation="border"/>
                                        </div>
                                    </td>
                                </tr>
                            ) : (
                                organizationsList.map((org, index) => {
                                    const active = isActiveOrg(org);
                                    return (
                                        <S.StyledOrgsTR key={"org " + org.id} className={getClassForRow(active)}>
                                            <S.StyledUsersTD style={{padding: "22px 8px 22px 22px", gap: "4px"}}>
                                                {org.name}
                                            </S.StyledUsersTD>
                                            <S.StyledUsersTD style={{padding: "22px 8px 22px 22px", gap: "4px"}}>
                                                {org.country ? getCountryName(org.country) : "Not provided"}
                                            </S.StyledUsersTD>
                                            <S.StyledUsersTD style={{padding: "22px 8px 22px 22px", gap: "4px"}}>
                                                {getFullAddress(org)}
                                            </S.StyledUsersTD>
                                            <S.StyledUsersTD style={{padding: "22px 8px 22px 22px", gap: "4px"}}>
                                                {org.phoneNumber}
                                            </S.StyledUsersTD>
                                            <S.StyledUsersTD style={{padding: "22px 8px 22px 22px", gap: "4px"}}>
                                                {org.email}
                                            </S.StyledUsersTD>
                                            <S.StyledUsersTD style={{padding: "22px 8px 22px 22px", gap: "4px"}}>
                                                {active ? "Active" : "Deactivated"}
                                            </S.StyledUsersTD>
                                            <S.StyledUsersActionTD style={{padding: "22px 8px", gap: "4px"}}>
                                                <OverlayTrigger trigger="click"
                                                                placement="left"
                                                                rootClose={true}
                                                                rootCloseEvent="mousedown"
                                                                key={index}
                                                                show={isEqual(currentPopover, index)}
                                                                onToggle={() => {
                                                                    if (isEqual(currentPopover, index)) {
                                                                        setCurrentPopover(null);
                                                                    } else {
                                                                        setCurrentPopover(index);
                                                                    }
                                                                }}
                                                                overlay={
                                                                    <S.StyledPopoverMenuOrgs id={index}>
                                                                        <div style={{right: "0px"}} id={org.id}>
                                                                            <S.StyledPopoverMenuLink
                                                                                onClick={onEditOrganization}>
                                                                                Edit organization
                                                                            </S.StyledPopoverMenuLink>
                                                                            <S.StyledPopoverMenuLink
                                                                                onClick={onSoftDelete}>
                                                                                Set {active ? "inactive" : "active"}
                                                                            </S.StyledPopoverMenuLink>
                                                                            <S.StyledPopoverMenuLink
                                                                                onClick={onHardDelete}>
                                                                                Delete
                                                                            </S.StyledPopoverMenuLink>
                                                                            <S.StyledPopoverMenuLink
                                                                                onClick={onCheckSubscription}>
                                                                                Check subscription
                                                                            </S.StyledPopoverMenuLink>
                                                                        </div>
                                                                    </S.StyledPopoverMenuOrgs>
                                                                }
                                                >
                                                    <S.StyledTableActionElement>
                                                        <div>
                                                            <S.StyledIcon id={org.id} onClick={() => {
                                                            }} className="fa-solid fa-ellipsis-vertical"/>
                                                        </div>
                                                    </S.StyledTableActionElement>
                                                </OverlayTrigger>
                                            </S.StyledUsersActionTD>
                                        </S.StyledOrgsTR>
                                    );
                                })
                            )
                            }
                            </tbody>
                        </Table>
                    </S.StyledTableWrapper>
                    <PaginationFooter setPageNumber={setPageNumber}
                                      pageNumber={pageNumber}
                                      lastPage={lastPage}
                                      offset={offset}
                                      endRange={endRange}
                                      quantity={organizationsQuantity}
                    />
                </S.StyledCenterContainer>
            </S.StyledMainContainer>
        </S.StyledDashboardContainer>
    );
}

export default OrganizationsList;
