import React, {useEffect, useState} from 'react';
import {BrowserRouter, useNavigate} from "react-router-dom";
import {Routes, Route, Navigate} from 'react-router-dom';
import './App.css';
import SignIn from "./view/user/signIn";
import {useAppDispatch, useAppSelector} from "./app/hooks";
import {selectAuthorizedUser} from "./features/user/userSlice"
import {getMe} from "./features/user/userThunks";
import Registration from "./view/user/registration";
import ForgotPassword from "./view/user/forgotPassword";
import CreateOrganisation from "./view/dashboard/createOrganisation";
import Dashboard from "./view/dashboard/dashboard";
import {useCookies} from "react-cookie";
import {saveToken} from "./utils"
import {isEmpty} from "lodash";
import Profile from "./view/dashboard/profile";
import PageNotFoundView from "./view/page-not-found";
import {LoadingPanel} from "./view/loading-panel";
import Devices from "./view/dashboard/devicesList";
import Users from "./view/dashboard/users";
import EventLog from "./view/dashboard/eventLog";
import OrganizationSettings from "./view/dashboard/organizationSettings";
import Home from "./view/home";
import InviteUser from "./view/dashboard/inviteUser";
import OrganizationsList from "./view/dashboard/organizationsList";
import AddOrganization from "./view/dashboard/addOrganization";
import DevicesList from "./view/dashboard/devicesList";

function App() {
    // const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const user = useAppSelector(selectAuthorizedUser);
    const [cookie, setCookie] = useCookies(["token"]);
    const [showLoadingPanel, setShowLoadingPanel] = useState(false);

    // @ts-ignore
    const ProtectedRoute = ({user, children}) => {
        if (!user) {
            return <Navigate to="/sign-in" replace/>;
        }

        return children;
    };

    useEffect(() => {
        if (cookie.token) {
            saveToken(cookie.token);
        }
        if (cookie.token && isEmpty(user)) {
            dispatch(getMe(null));
        }
    }, []);

    if (!user) {

    }

    return (
        <BrowserRouter>
            {showLoadingPanel ? (<LoadingPanel/>) : (
                <Routes>

                    <Route index element={
                        <ProtectedRoute user={user}>
                            <Navigate to="/sign-in" replace/>
                        </ProtectedRoute>}/>
                    <Route path="/" element={
                        <ProtectedRoute user={user}>
                            <Navigate to="/dashboard" replace/>
                        </ProtectedRoute>
                    }/>

                    <Route path="sign-in" element={<SignIn/>}/>

                    <Route path="registration" element={<Registration/>}/>

                    <Route path="password-forgot" element={<ForgotPassword/>}/>

                    <Route path="create-organisation" element={
                        <ProtectedRoute user={user}>
                            <CreateOrganisation/>
                        </ProtectedRoute>
                    }/>

                    <Route path="dashboard" element={
                        <ProtectedRoute user={user}>
                            <Dashboard/>
                        </ProtectedRoute>
                    }/>
                    <Route path="devices" element={
                        <ProtectedRoute user={user}>
                            <DevicesList/>
                        </ProtectedRoute>
                    }/>
                    <Route path="users" element={
                        <ProtectedRoute user={user}>
                            <Users/>
                        </ProtectedRoute>
                    }/>
                    <Route path="events" element={
                        <ProtectedRoute user={user}>
                            <EventLog/>
                        </ProtectedRoute>
                    }/>
                    <Route path="profile" element={
                        <ProtectedRoute user={user}>
                            <Profile/>
                        </ProtectedRoute>
                    }/>
                    <Route path="organization/:tab" element={
                        <ProtectedRoute user={user}>
                            <OrganizationSettings/>
                        </ProtectedRoute>
                    }/>
                    <Route path="organizations-list" element={
                        <ProtectedRoute user={user}>
                            <OrganizationsList/>
                        </ProtectedRoute>
                    }/>
                    <Route path="add-organization" element={
                        <ProtectedRoute user={user}>
                            <AddOrganization/>
                        </ProtectedRoute>
                    }/>
                    <Route path="invite" element={
                        <ProtectedRoute user={user}>
                            <InviteUser/>
                        </ProtectedRoute>
                    }/>

                    <Route path="*" element={<PageNotFoundView/>}/>

                </Routes>)}
        </BrowserRouter>
    );
}

export default App;
