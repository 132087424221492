import * as React from 'react';
import * as S from './styled-components';

export const PageNotFoundView = () => {
    return (
        <S.PageNotFoundWrapper>
            <h1>Bad URL</h1>
            <i className="fas fa-unlink" />
            <p>It appears you have arrived at a bad URL. Please check the URL you used to ensure it has a global context.</p>
        </S.PageNotFoundWrapper>
    );
};

export default PageNotFoundView;
