import {Organization} from "./organization";
import {BasicModel, IPaginationParams} from "./index";

export interface Device extends BasicModel {
    serialNumber: string;
    imei: string;
    status: DeviceStatus;
    logisticStatus: LogisticStatus;
    executionMode: ExecutionModeType;
    deviceOrganization?: {
        id: string;
        organization: {
            id: string;
            name: string
        }
    }
    deviceOrder?: {
        orderId: string
    }
    calibrationExpires: Date;
    reservedDeviceGroup?: any;
    deviceOrganizations?: Organization[];
    returned?: boolean
}

export interface DeviceFeaturePrice extends BasicModel {
    deviceFeatureName: string;
    stripeProductId: string;
}

export interface OrderData {
    quantity: number;
    deviceFeatureName: string;
}

export interface CreateOrder {
    orderData: OrderData[];
    orderType: OrderType;
    orgId?: string;
    orderId?: string;
}

export enum DeviceStatus {
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED',
    RETURNED = 'RETURNED',
}

export enum ExecutionModeType {
    MOBILE = 'MOBILE',
    STATIONARY = 'STATIONARY',
}

export enum LogisticStatus {
    AT_STORAGE = 'AT_STORAGE',
    IN_TRANSIT_TO_CUSTOMER = 'IN_TRANSIT_TO_CUSTOMER',
    IN_CUSTOMER_USE = 'IN_CUSTOMER_USE',
    IN_TRANSIT_FROM_CUSTOMER = 'IN_TRANSIT_FROM_CUSTOMER',
    RETURNED = 'RETURNED',
    LOST = 'LOST',
    REFURBISH = 'REFURBISH'
}

export enum OrderType {
    INVOICE = 'INVOICE',
    CHECKOUT_SESSION = 'CHECKOUT_SESSION',
}

export interface DevicesErrors {
    quantity?: string;
    organizationId?: string;
    order?: string;
}

export interface DevicePaginationParams extends IPaginationParams {
    orgIds?: string[]
}

export interface AddDevicesParams {
    orgId: string;
    serialNumbers: string[];
    orderId?: string;
}

export interface UnlinkDeviceFromOrg {
    deviceId: string;
}

export interface PreOrderData {
    fullPrice: number;
    orderId: string;
    notEnough: boolean;
}

export interface CancelOrderParams {
    orderId: string;
    order: boolean;
}
