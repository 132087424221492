import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../../app/store";

export interface GlobalState {
    message: string | null;
    loading: boolean
    status: 'idle' | 'loading' | 'failed';
}

const initialState: GlobalState = {
    message: null,
    loading: false,
    status: "idle",
};

export const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setMessage: (state, action: PayloadAction<string | null>) => {
            state.message = action.payload
        },
        clearGlobalData: (state) => {
            state.loading = false;
            state.message = null;
            state.status = "idle";
        }
    }
});

export const {setLoading, setMessage, clearGlobalData} = globalSlice.actions;

export const selectLoading = (state: RootState) => state.global.loading;
export const selectMessage = (state: RootState) => state.global.message;

export default globalSlice.reducer;
