import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {selectAuthorizedUser} from "../../features/user/userSlice"
import {registerUser} from "../../features/user/userThunks";
import Form from 'react-bootstrap/Form';
import {useCookies} from "react-cookie";
import * as S from './styled-components';
import {
    formBasicEmail,
    formBasicFullName,
    formBasicPassword,
    formBasicPasswordConfirm, formBasicPhoneNumber,
    passwordValidationError
} from "../../constants"
import {RegistrationErrors} from "../../types";
import {get, isEmpty, isEqual} from "lodash";
import {getInvalidFiledName, saveToken, validateEmail, validateMobileNumber, validatePassword} from "../../utils";
import {selectLoading, selectMessage, setMessage} from "../../features/global/globalSlice";

function Registration() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [cookie, setCookie] = useCookies(["token"]);

    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [mobileNumber, setMobileNumber] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");
    const [errors, setErrors] = useState<RegistrationErrors>({});

    const authorizedUser = useAppSelector(selectAuthorizedUser);
    const isLoading = useAppSelector(selectLoading);
    const errorMessage = useAppSelector(selectMessage);

    useEffect(() => {
        if (authorizedUser) {
            navigate("/create-organisation");
        }

        return () => {
            dispatch(setMessage(null));
        }
    }, [authorizedUser]);

    useEffect(() => {
        setErrors({
            ...errors,
            [getInvalidFiledName(errorMessage)]: errorMessage
        })
    }, [errorMessage]);

    const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {id, value} = event.target;
        switch (id) {
            case formBasicFullName:
                setName(value);
                break;
            case formBasicEmail:
                setEmail(value);
                break;
            case formBasicPhoneNumber:
                setMobileNumber(value);
                break;
            case formBasicPassword:
                setPassword(value);
                break;
            case formBasicPasswordConfirm:
                setPasswordConfirmation(value);
                break
        }
    }

    const validateData = (): boolean => {
        let tmpErrors = {};

        if (isEmpty(name)) {
            tmpErrors = {...tmpErrors, fullName: "Name cannot be empty"};
        } else if (name.length > 20) {
            tmpErrors = {...tmpErrors, fullName: "Full name should contain no more than 20 characters"}
        }
        if (isEmpty(email)) {
            tmpErrors = {...tmpErrors, email: "Email cannot be empty"};
        } else if (email.length > 100) {
            tmpErrors = {...tmpErrors, email: "Email should contain no more than 100 characters"};
        } else if (!validateEmail(email)) {
            tmpErrors = {
                ...tmpErrors,
                email: "Email contains invalid characters"
            };
        }
        if (!isEmpty(mobileNumber)) {
            if (mobileNumber.length > 13) {
                tmpErrors = {...tmpErrors, mobileNumber: "Phone should contains no more 13 symbols"}
            } else if (!validateMobileNumber(mobileNumber)) {
                tmpErrors = {...tmpErrors, mobileNumber: "Phone number is not valid"}
            }
        }

        if (isEmpty(password)) {
            tmpErrors = {...tmpErrors, password: "Password cannot be empty"}
        } else if (password.length > 255) {
            tmpErrors = {...tmpErrors, password: "Password should contain no more than 255 characters"}
        } else if (!validatePassword(password)) {
            tmpErrors = {...tmpErrors, password: passwordValidationError}
        } else if (isEmpty(passwordConfirmation)) {
            tmpErrors = {...tmpErrors, passwordConfirm: "Password confirm cannot be empty"};
        } else if (!isEqual(password, passwordConfirmation)) {
            tmpErrors = {...tmpErrors, passwordConfirm: "Password and Confirm Password fields are different"};
        }

        let notValid = Object.keys(tmpErrors).length > 0;
        if (notValid) {
            setErrors(tmpErrors);
        } else {
            setErrors({});
        }

        return notValid;
    }

    const onCreateUser = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!validateData()) {
            const response = await dispatch(registerUser({email, name, password, passwordConfirmation, mobileNumber}));
            if (response.payload) {
                setCookie("token", get(response, "payload.accessToken"), {path: '/'});
                saveToken(get(response, "payload.accessToken", ""));
            }
        }
    }

    return (
        <S.RegistrationContainer>
            <S.StyledRegistrationForm>
                <S.StyledRegistrationFrame>
                    <S.StyledRegistrationGroup>
                        <S.StyledTopLogo>
                            <S.StyledCompanyLogo/>
                            <S.StyledCompanyName/>
                        </S.StyledTopLogo>
                        <Form onSubmit={onCreateUser}>
                            <S.StyledRegistrationData>
                                <S.StyledCaption>Create Account</S.StyledCaption>
                                <Form.Group controlId={formBasicFullName}>
                                    <S.StyledRegistrationLabel>Full Name</S.StyledRegistrationLabel>
                                    <S.StyledRegistrationInput type="input"
                                                            placeholder="e.g John Appleseed"
                                                            isInvalid={!isEmpty(errors.fullName)}
                                                            disabled={isLoading}
                                                            value={name}
                                                            onChange={onChangeValue}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.fullName}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId={formBasicEmail}>
                                    <S.StyledRegistrationLabel>Email</S.StyledRegistrationLabel>
                                    <S.StyledRegistrationInput type="email"
                                                            placeholder="e.g. john@appleseed.com"
                                                            isInvalid={!isEmpty(errors.email)}
                                                            disabled={isLoading}
                                                            value={email}
                                                            onChange={onChangeValue}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId={formBasicPhoneNumber}>
                                    <S.StyledRegistrationLabel>Phone</S.StyledRegistrationLabel>
                                    <S.StyledRegistrationInput type="input"
                                                               isInvalid={!isEmpty(errors.mobileNumber)}
                                                               disabled={isLoading}
                                                               value={mobileNumber}
                                                               onChange={onChangeValue}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.mobileNumber}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId={formBasicPassword}>
                                    <S.StyledRegistrationLabel>Password</S.StyledRegistrationLabel>
                                    <S.StyledRegistrationInput type="password"
                                                            placeholder="Enter your password"
                                                            isInvalid={!isEmpty(errors.password)}
                                                            disabled={isLoading}
                                                            value={password}
                                                            onChange={onChangeValue}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId={formBasicPasswordConfirm}>
                                    <S.StyledRegistrationLabel>Password Confirmation</S.StyledRegistrationLabel>
                                    <S.StyledRegistrationInput type="password"
                                                            placeholder="Confirm your password"
                                                            isInvalid={!isEmpty(errors.passwordConfirm)}
                                                            disabled={isLoading}
                                                            value={passwordConfirmation}
                                                            onChange={onChangeValue}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.passwordConfirm}</Form.Control.Feedback>
                                </Form.Group>
                                <S.StyledSubmitButton variant="primary" type='submit' disabled={isLoading}>
                                    {isLoading && (<S.StyledSingInSpinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />)}
                                    Get Started
                                </S.StyledSubmitButton>
                                <S.StyledBottomText>
                                    Already have an account?<S.StyledLink to="/sign-in"> Sign in</S.StyledLink>
                                </S.StyledBottomText>
                            </S.StyledRegistrationData>
                        </Form>
                    </S.StyledRegistrationGroup>
                </S.StyledRegistrationFrame>

                <S.StyledRegistrationImageBlock>
                    <S.StyledRegistrationImage/>
                    <S.StyledImageText>
                        Online asset tracing service made of recycled ocean plastic
                    </S.StyledImageText>
                </S.StyledRegistrationImageBlock>
            </S.StyledRegistrationForm>
        </S.RegistrationContainer>
    )
}

export default Registration;
