import React, {useEffect, useRef, useState} from "react";
import {Modal, Button, Form, Overlay, Tooltip} from 'react-bootstrap';
import {User} from "../../types";
import {
    formBasicFullName,
    formBasicOrganizationName,
    formBasicPhoneNumber,
    formBasicRegistrationDate,
    formBasicUserRole
} from "../../constants";
import * as SU from "../user/styled-components";
import * as S from "./styled-components";
import {isEqual} from "lodash";
import moment from "moment"
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectLoading} from "../../features/global/globalSlice";
import {selectUserOperationStatus, setStatus} from "../../features/user/userSlice";
import {ORG_ADMIN, ORG_MEMBER} from "../../constants/dashboard";
import validator from "validator";
import isEmpty = validator.isEmpty;

interface EditProfileModalProps {
    user: User | null;
    organizationName: string | null;
    show: boolean;
    onClose: (withUpdate: boolean) => void;
    onSave: () => void;
    onChangeRole: (event: any) => void;
}

function EditProfileModal(props: EditProfileModalProps) {
    const dispatch = useAppDispatch();

    const target = useRef(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [touch, setTouch] = useState(false);
    const role = props.user && props.user.role || ORG_MEMBER;

    const isLoading = useAppSelector(selectLoading);
    const status = useAppSelector(selectUserOperationStatus);

    useEffect(() => {
        if (isEqual(status, "updated")) {
            setShowSuccess(true);
        }
    }, [status]);

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                props.onClose(true);
            }, 800);
        }
    }, [showSuccess]);

    const onChangeRole = (event: any) => {
        props.onChangeRole(event);
        setTouch(true);
    }

    return (
        <>
            <S.StyledEditRoleModal show={props.show} onHide={() => props.onClose(false)}>
                <Modal.Header closeButton onClick={() => {
                }}>
                    <Modal.Title>Edit role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group id={formBasicFullName}>
                            <SU.StyledRegistrationLabel>Full Name</SU.StyledRegistrationLabel>
                            <SU.StyledRegistrationInput type="input"
                                                        placeholder="e.g John Appleseed"
                                                        value={props.user && props.user.name}
                                                        disabled={true}
                            />
                        </Form.Group>
                        <Form.Group id={formBasicPhoneNumber}>
                            <SU.StyledRegistrationLabel>Mobile number</SU.StyledRegistrationLabel>
                            <SU.StyledRegistrationInput type="input"
                                                        placeholder="Mobile number not provided"
                                                        value={props.user && props.user.mobileNumber}
                                                        disabled={true}
                            />
                        </Form.Group>
                        {props.organizationName && !isEmpty(props.organizationName) && (
                            <Form.Group controlId={formBasicOrganizationName}>
                                <SU.StyledRegistrationLabel>Organization</SU.StyledRegistrationLabel>
                                <SU.StyledRegistrationInput type="input"
                                                            placeholder="Organization name"
                                                            value={props.organizationName}
                                                            disabled={true}
                                />
                            </Form.Group>)
                        }
                        <Form.Group id={formBasicRegistrationDate}>
                            <SU.StyledRegistrationLabel>Registration date</SU.StyledRegistrationLabel>
                            <SU.StyledRegistrationInput type="input"
                                                        placeholder="Registration date"
                                                        value={props.user && moment(props.user.createdAt).format("DD-MM-YYYY")}
                                                        disabled={true}
                            />
                        </Form.Group>
                        <Form.Group id={formBasicUserRole}>
                            <SU.StyledRegistrationLabel>Role</SU.StyledRegistrationLabel>
                            <S.StyledUserRoleSelect value={role} onChange={onChangeRole} disabled={isLoading}>
                                <S.StyledUserRoleOption value={ORG_MEMBER}>
                                    Organization member
                                </S.StyledUserRoleOption>
                                <S.StyledUserRoleOption value={ORG_ADMIN}>Organization admin</S.StyledUserRoleOption>
                            </S.StyledUserRoleSelect>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{justifyContent: "center"}}>
                    <S.StyledProfileResetPassword variant="secondary" disabled={isLoading}
                                                  onClick={() => props.onClose(false)}>
                        Close
                    </S.StyledProfileResetPassword>
                    <S.StyledProfileSaveButton variant="primary"
                                               ref={target}
                                               disabled={isLoading || !touch}
                                               onClick={props.onSave}>
                        {isLoading && (<SU.StyledSingInSpinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />)}
                        Save changes
                    </S.StyledProfileSaveButton>
                    <Overlay target={target.current} show={showSuccess} placement="right">
                        {(props) => (
                            <Tooltip id="overlay-example" {...props}>
                                Saved
                            </Tooltip>
                        )}
                    </Overlay>
                </Modal.Footer>
            </S.StyledEditRoleModal>
        </>
    )
}

export default EditProfileModal;
