import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import * as S from "./styled-components";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {isEmpty, isEqual} from "lodash";
import {selectOrganization, selectOrganizationStatus, selectOrganizationsLoaded} from "../../features/organization/organizationSlice";
import {selectDevicesLoaded} from "../../features/device/deviceSlice";
import {getAllOrganizations, getUserOrganization} from "../../features/organization/organizationThunks";
import SideBar from "./sideBar";
import {dashBoard, ORG_ADMIN, SUPERUSER} from "../../constants/dashboard";
import {isOrgAdmin, isSuperAdmin} from "../../utils";
import {selectAuthorizedUser} from "../../features/user/userSlice";
import {getOrganizationDevices} from "../../features/device/deviceThunks";


function Dashboard() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const user = useAppSelector(selectAuthorizedUser);
    const organization = useAppSelector(selectOrganization);
    const organizationStatus = useAppSelector(selectOrganizationStatus);
    const organizationsLoaded = useAppSelector(selectOrganizationsLoaded);
    const devicesLoaded = useAppSelector(selectDevicesLoaded);

    useEffect(() => {
        if (isEqual(organizationStatus, "loaded") && isEmpty(organization) && !isSuperAdmin(user)) {
            navigate("/create-organisation");
        }
    }, [organization, organizationStatus]);

    useEffect(() => {
        if ((isEqual(organizationStatus, "loaded") || isEqual(organizationStatus, "organizationsLoaded")) && ((organizationsLoaded && isSuperAdmin(user)) || devicesLoaded)) {
            navigate("/devices")
        }
    }, [organizationsLoaded, devicesLoaded, organizationStatus])

    useEffect(() => {
        if (isEmpty(organization) && !isSuperAdmin(user)) {
            dispatch(getUserOrganization(null));
        }
    }, []);

    useEffect(() => {
        async function fetchOrganizations() {
            await dispatch(getAllOrganizations(null));
        }
        async function fetchOrganizationDevices() {
            await dispatch(getOrganizationDevices({limit: 8, offset: 1}));
        }
        if (user && isSuperAdmin(user)) {
            fetchOrganizations();
        } else if (isOrgAdmin(user)) {
            fetchOrganizationDevices();
        }
    }, [user]);

    return (
        <S.StyledProfileContainer>
            <SideBar currentComponent={dashBoard}/>
        </S.StyledProfileContainer>
    )
}

export default Dashboard;
