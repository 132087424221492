import { createAsyncThunk } from "@reduxjs/toolkit";
import { isArray, head } from "lodash";
import { getTermsOfServiceAPI } from "../../api";
import { setMessage, setLoading } from "../global/globalSlice";

export const getTermsOfServiceFile = createAsyncThunk("user/termsOfService", async (_: null, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));

        const response = await getTermsOfServiceAPI();

        thunkAPI.dispatch(setLoading(false));

        if (response.success && response.data) {
            return response.data;
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during fetching file(thunk)" : "An error occurred during fetching file(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch(error) {
        thunkAPI.dispatch(setLoading(false));
        console.error("An error occurred during fetching file(thunk)",error);
    }
});
