import axios, {isAxiosError} from "axios"
import config from "../config/api"
import {get, isEqual, isNull} from "lodash";
import {
    AddDevicesParams, CancelOrderParams,
    CreateOrder,
    Device,
    DeviceFeaturePrice,
    DevicePaginationParams,
    IResponse, PreOrderData,
    UnlinkDeviceFromOrg
} from "../types";

const {apiURL} = config.apiConfig;

interface IGetOrganizationDevicesResponse extends IResponse {
    data?: Device[],
    quantity?: number
}

export const getOrganizationDevicesAPI = async (pagination: DevicePaginationParams): Promise<IGetOrganizationDevicesResponse> => {
    try {
        let URL = `${apiURL}/api/devices?limit=${pagination.limit}&offset=${pagination.offset}`;
        if (!!pagination.order && !!pagination.orderBy) {
            URL = URL.concat(`&order=${pagination.order}&orderBy=${pagination.orderBy}`);
        }
        if (pagination.orgIds) {
            let orgIdsQuery = "";
            if (pagination.orgIds.length > 0) {
                pagination.orgIds.forEach((org) => {
                    orgIdsQuery = orgIdsQuery.concat(`&orgIds=${org}`)
                })
            }
            URL = URL.concat(orgIdsQuery);
        }

        const axiosResponse = await axios.get(URL);

        if (isEqual(axiosResponse.status, 200)) {
            return {
                success: true,
                data: get(axiosResponse, "data.data"),
                quantity: get(axiosResponse, "data.count")
            }
        } else {
            return {
                success: false,
                errors: [axiosResponse.statusText]
            }
        }
    } catch (error) {
        console.error("An error occurred during devices fetching(api)", error);
        let errorResponse: IGetOrganizationDevicesResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during devices fetching"]
        };
    }
}

interface IGetDevicesFeaturesResponse extends IResponse {
    freeDevices?: number;
    data?: DeviceFeaturePrice[];
}

export const getDevicesFeaturesAPI = async (): Promise<IGetDevicesFeaturesResponse> => {
    try {
        const URL = `${apiURL}/api/devices/features`;

        const axiosResponse = await axios.get(URL);

        if (isEqual(axiosResponse.status, 200)) {
            return {
                success: true,
                data: get(axiosResponse, "data.data"),
                freeDevices: get(axiosResponse, "data.freeDevicesCount")
            }
        } else {
            return {
                success: false,
                errors: [axiosResponse.statusText]
            }
        }
    } catch (error) {
        console.error("An error occurred during devices fetching(api)", error);
        let errorResponse: IGetDevicesFeaturesResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during devices features fetching"]
        };
    }
}

interface IGetPreOrderInformation extends IResponse {
    data?: PreOrderData;
}

export const getPriceAPI = async (arg: CreateOrder, preOrder: boolean): Promise<IGetPreOrderInformation> => {
    try {
        const URL = `${apiURL}/api/devices/${preOrder ? "pre-" : ""}order`;

        const axiosResponse = await axios.post(URL, arg);

        if (isEqual(axiosResponse.status, 201)) {
            return {
                success: true,
                data: axiosResponse.data
            }
        } else if (isEqual(axiosResponse.status, 200)) {
            return {
                success: false,
                errors: [axiosResponse.data]
            }
        } else {
            return {
                success: false,
                errors: [axiosResponse.statusText]
            }
        }
    } catch (error) {
        console.error("An error occurred during pre-order\\order generating(api)", error);
        let errorResponse: IGetPreOrderInformation = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : `An error occurred during ${preOrder ? "pre-order information fetching" : "order creation"}`]
        };
    }
}

interface IAddDevicesResponse extends IResponse {
    message?: string
}

export const addDevicesAPI = async (arg: AddDevicesParams): Promise<IAddDevicesResponse> => {
    try {
        const URL = `${apiURL}/api/devices/link-with-org`;

        const axiosResponse = await axios.post(URL, arg);

        if (isEqual(axiosResponse.status, 201)) {
            return {
                success: true,
                data: axiosResponse.data
            }
        } else if (isEqual(axiosResponse.status, 200)) {
            return {
                success: false,
                errors: [axiosResponse.data]
            }
        } else {
            return {
                success: false,
                errors: [axiosResponse.statusText]
            }
        }
    } catch (error) {
        console.error("An error occurred during adding devices(api)", error);
        let errorResponse: IGetPreOrderInformation = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : `An error occurred during adding devices`]
        };
    }
}

interface IGetFreeDevices extends IResponse {
    data?: Device[];
}

export const getFreeDevicesAPI = async (): Promise<IGetFreeDevices> => {
    try {
        const URL = `${apiURL}/api/devices/free`;

        const axiosResponse = await axios.get(URL);

        if (isEqual(axiosResponse.status, 200)) {
            return {
                success: true,
                data: get(axiosResponse, "data.data")
            }
        } else {
            return {
                success: false,
                errors: [axiosResponse.statusText]
            }
        }
    } catch (error) {
        console.error("An error occurred during fetching free devices(api)", error);
        let errorResponse: IGetFreeDevices = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : `An error occurred during fetching free devices`]
        };
    }
}

interface IRemoveDevice extends IResponse {}

export const removeDeviceFormOrganizationAPI = async (params: UnlinkDeviceFromOrg): Promise<IRemoveDevice> => {
    try {
        const URL = `${apiURL}/api/devices/unlink-from-org`;

        const axiosResponse = await axios.post(URL, params);

        return {
            success: isEqual(axiosResponse.status, 201)
        }
    } catch(error) {
        console.error("An error occurred during unlinking devices(api)", error);
        let errorResponse: IRemoveDevice = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : `An error occurred during unlinking devices`]
        };
    }
}

interface IReturnDeviceResponse extends IResponse {}

export const returnDeviceAPI = async (serialNumbers: string[]): Promise<IReturnDeviceResponse> => {
    try {
        const URL = `${apiURL}/api/devices/return/notify`;

        const axiosResponse = await axios.post(URL, {serialNumbers: serialNumbers});

        return {
            success: isEqual(axiosResponse.status, 201)
        }
    } catch(error) {
        console.error("An error occurred during returning device(api)", error);
        let errorResponse: IReturnDeviceResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : `An error occurred during returning device`]
        };
    }
}

interface ICancelPreOrderResponse extends IResponse {}

export const cancelPreOrderAPI = async (params: CancelOrderParams): Promise<ICancelPreOrderResponse> => {
    try {
        const URL = `${apiURL}/api/devices/${params.order? "" : "pre-"}order/cancel`;

        const axiosResponse = await axios.post(URL, {orderId: params.orderId});

        return {
            success: isEqual(axiosResponse.status, 201)
        }
    } catch(error) {
        console.error("An error occurred during canceling pre order(api)", error);
        let errorResponse: ICancelPreOrderResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : `An error occurred during canceling pre order`]
        };
    }
}

interface IUpdateDeviceResponse extends IResponse {
    data?: Device;
}

export const updateDeviceAPI = async (device: Device): Promise<IUpdateDeviceResponse> => {
    try {
        const URL = `${apiURL}/api/devices/${device.id}`;

        const axiosResponse = await axios.patch(URL, device);

        if (isEqual(axiosResponse.status, 200)) {
            return {
                success: true,
                data: axiosResponse.data
            }
        } else {
            return {
                success: false,
                errors: [axiosResponse.statusText]
            }
        }
    } catch (error) {
        console.error("An error occurred during patching the device(api)", error);
        let errorResponse: IUpdateDeviceResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : `An error occurred during patching the device`]
        };
    }
}

interface IDeleteDeviceResponse extends IResponse {
    data?: string;
}

export const deleteDeviceAPI = async (deviceId: string): Promise<IDeleteDeviceResponse> => {
    try {
        const URL = `${apiURL}/api/devices/${deviceId}`;

        const axiosResponse = await axios.delete(URL);

        if (isEqual(axiosResponse.status, 200)) {
            return {
                success: true,
                data: axiosResponse.data
            }
        } else {
            return {
                success: false,
                errors: [axiosResponse.statusText]
            }
        }
    } catch(error) {
        console.error("An error occurred during deleting the device(api)", error);
        let errorResponse: IDeleteDeviceResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : `An error occurred during deleting the device`]
        };
    }
}


