export * from "./registration";
export * from "./api"

export const sliceStatus = {
    idle: "idle",
    loading: "loading",
    failed: "failed"
};

export enum CountryCodes {
    'Afghanistan' = 'AF',
    'AlandIslands' = 'AX',
    'Albania' = 'AL',
    'Algeria' = 'DZ',
    'American Samoa' = 'AS',
    'Andorra' = 'AD',
    'Angola' = 'AO',
    'Anguilla' = 'AI',
    'Antarctica' = 'AQ',
    'Antigua and Barbuda' = 'AG',
    'Argentina' = 'AR',
    'Armenia' = 'AM',
    'Aruba' = 'AW',
    'Australia' = 'AU',
    'Austria' = 'AT',
    'Azerbaijan' = 'AZ',
    'Bahamas' = 'BS',
    'Bahrain' = 'BH',
    'Bangladesh' = 'BD',
    'Barbados' = 'BB',
    'Belarus' = 'BY',
    'Belgium' = 'BE',
    'Belize' = 'BZ',
    'Benin' = 'BJ',
    'Bermuda' = 'BM',
    'Bhutan' = 'BT',
    'Bolivia' = 'BO',
    'Bosnia and Herzegovina' = 'BA',
    'Botswana' = 'BW',
    'Bouvet Island' = 'BV',
    'Brazil' = 'BR',
    'British Indian Ocean Territory' = 'IO',
    'Brunei Darussalam' = 'BN',
    'Bulgaria' = 'BG',
    'Burkina Faso' = 'BF',
    'Burundi' = 'BI',
    'Cambodia' = 'KH',
    'Cameroon' = 'CM',
    'Canada' = 'CA',
    'Cape Verde' = 'CV',
    'Cayman Islands' = 'KY',
    'Central African Republic' = 'CF',
    'Chad' = 'TD',
    'Chile' = 'CL',
    'China' = 'CN',
    'Christmas Island' = 'CX',
    'Cocos Keeling Islands' = 'CC',
    'Colombia' = 'CO',
    'Comoros' = 'KM',
    'Congo' = 'CG',
    'Congo Democratic Republic' = 'CD',
    'Cook Islands' = 'CK',
    'Costa Rica' = 'CR',
    'CoteDIvoire' = 'CI',
    'Croatia' = 'HR',
    'Cuba' = 'CU',
    'Curaçao' = 'CW',
    'Cyprus' = 'CY',
    'CzechRepublic' = 'CZ',
    'Denmark' = 'DK',
    'Djibouti' = 'DJ',
    'Dominica' = 'DM',
    'Dominican Republic' = 'DO',
    'Ecuador' = 'EC',
    'Egypt' = 'EG',
    'El Salvador' = 'SV',
    'Equatorial Guinea' = 'GQ',
    'Eritrea' = 'ER',
    'Estonia' = 'EE',
    'Ethiopia' = 'ET',
    'Falkland Islands' = 'FK',
    'Faroe Islands' = 'FO',
    'Fiji' = 'FJ',
    'Finland' = 'FI',
    'France' = 'FR',
    'French Guiana' = 'GF',
    'French Polynesia' = 'PF',
    'French Southern Territories' = 'TF',
    'Gabon' = 'GA',
    'Gambia' = 'GM',
    'Georgia' = 'GE',
    'Germany' = 'DE',
    'Ghana' = 'GH',
    'Gibraltar' = 'GI',
    'Greece' = 'GR',
    'Greenland' = 'GL',
    'Grenada' = 'GD',
    'Guadeloupe' = 'GP',
    'Guam' = 'GU',
    'Guatemala' = 'GT',
    'Guernsey' = 'GG',
    'Guinea' = 'GN',
    'Guinea Bissau' = 'GW',
    'Guyana' = 'GY',
    'Haiti' = 'HT',
    'Heard Island Mcdonald Islands' = 'HM',
    'Holy See Vatican City State' = 'VA',
    'Honduras' = 'HN',
    'HongKong' = 'HK',
    'Hungary' = 'HU',
    'Iceland' = 'IS',
    'India' = 'IN',
    'Indonesia' = 'ID',
    'Iran' = 'IR',
    'Iraq' = 'IQ',
    'Ireland' = 'IE',
    'Isle Of Man' = 'IM',
    'Israel' = 'IL',
    'Italy' = 'IT',
    'Jamaica' = 'JM',
    'Japan' = 'JP',
    'Jersey' = 'JE',
    'Jordan' = 'JO',
    'Kazakhstan' = 'KZ',
    'Kenya' = 'KE',
    'Kiribati' = 'KI',
    'Korea, Republic of' = 'KR',
    'Korea, Democratic People`s Republic of' = 'KP',
    'Kuwait' = 'KW',
    'Kyrgyzstan' = 'KG',
    'Lao Peoples Democratic Republic' = 'LA',
    'Latvia' = 'LV',
    'Lebanon' = 'LB',
    'Lesotho' = 'LS',
    'Liberia' = 'LR',
    'Libyan Arab Jamahiriya' = 'LY',
    'Liechtenstein' = 'LI',
    'Lithuania' = 'LT',
    'Luxembourg' = 'LU',
    'Macao' = 'MO',
    'Madagascar' = 'MG',
    'Malawi' = 'MW',
    'Malaysia' = 'MY',
    'Maldives' = 'MV',
    'Mali' = 'ML',
    'Malta' = 'MT',
    'Marshall Islands' = 'MH',
    'Martinique' = 'MQ',
    'Mauritania' = 'MR',
    'Mauritius' = 'MU',
    'Mayotte' = 'YT',
    'Mexico' = 'MX',
    'Micronesia' = 'FM',
    'Moldova' = 'MD',
    'Monaco' = 'MC',
    'Mongolia' = 'MN',
    'Montenegro' = 'ME',
    'Montserrat' = 'MS',
    'Morocco' = 'MA',
    'Mozambique' = 'MZ',
    'Myanmar' = 'MM',
    'Namibia' = 'NA',
    'Nauru' = 'NR',
    'Nepal' = 'NP',
    'Netherlands' = 'NL',
    'New Caledonia' = 'NC',
    'New Zealand' = 'NZ',
    'Nicaragua' = 'NI',
    'Niger' = 'NE',
    'Nigeria' = 'NG',
    'Niue' = 'NU',
    'Norfolk Island' = 'NF',
    'Northern Mariana Islands' = 'MP',
    'Norway' = 'NO',
    'Oman' = 'OM',
    'Pakistan' = 'PK',
    'Palau' = 'PW',
    'Palestinian Territory' = 'PS',
    'Panama' = 'PA',
    'Papua New Guinea' = 'PG',
    'Paraguay' = 'PY',
    'Peru' = 'PE',
    'Philippines' = 'PH',
    'Pitcairn' = 'PN',
    'Poland' = 'PL',
    'Portugal' = 'PT',
    'Puerto Rico' = 'PR',
    'Qatar' = 'QA',
    'Reunion' = 'RE',
    'M`Republic of North Macedonia' = 'MKD',
    'Romania' = 'RO',
    'Russian Federation' = 'RU',
    'Rwanda' = 'RW',
    'Saint Barthelemy' = 'BL',
    'Saint Helena' = 'SH',
    'Saint Kitts And Nevis' = 'KN',
    'Saint Lucia' = 'LC',
    'Saint Martin' = 'MF',
    'Saint Pierre And Miquelon' = 'PM',
    'Saint Vincent And Grenadines' = 'VC',
    'Samoa' = 'WS',
    'San Marino' = 'SM',
    'Sao Tome And Principe' = 'ST',
    'Saudi Arabia' = 'SA',
    'Senegal' = 'SN',
    'Serbia' = 'RS',
    'Seychelles' = 'SC',
    'Sierra Leone' = 'SL',
    'Singapore' = 'SG',
    'Slovakia' = 'SK',
    'Slovenia' = 'SI',
    'Solomon Islands' = 'SB',
    'Somalia' = 'SO',
    'South Africa' = 'ZA',
    'South Georgia And Sandwich Island' = 'GS',
    'Spain' = 'ES',
    'Sri Lanka' = 'LK',
    'Sudan' = 'SD',
    'South Sudan' = 'SS',
    'Suriname' = 'SR',
    'Svalbard And Jan Mayen' = 'SJ',
    'Swaziland' = 'SZ',
    'Sweden' = 'SE',
    'Switzerland' = 'CH',
    'Sint Maarten (Dutch part)' = 'SX',
    'Syrian Arab Republic' = 'SY',
    'Taiwan' = 'TW',
    'Tajikistan' = 'TJ',
    'Tanzania' = 'TZ',
    'Thailand' = 'TH',
    'TimorLeste' = 'TL',
    'Togo' = 'TG',
    'Tokelau' = 'TK',
    'Tonga' = 'TO',
    'Trinidad And Tobago' = 'TT',
    'Tunisia' = 'TN',
    'Turkey' = 'TR',
    'Turkmenistan' = 'TM',
    'Turks And Caicos Islands' = 'TC',
    'Tuvalu' = 'TV',
    'Uganda' = 'UG',
    'Ukraine' = 'UA',
    'United Arab Emirates' = 'AE',
    'United Kingdom' = 'GB',
    'United States' = 'US',
    'United States Outlying Islands' = 'UM',
    'Uruguay' = 'UY',
    'Uzbekistan' = 'UZ',
    'Vanuatu' = 'VU',
    'Venezuela' = 'VE',
    'Vietnam' = 'VN',
    'Virgin Islands British' = 'VG',
    'Virgin Islands US' = 'VI',
    'Wallis And Futuna' = 'WF',
    'Western Sahara' = 'EH',
    'Yemen' = 'YE',
    'Zambia' = 'ZM',
    'Zimbabwe' = 'ZW',
}
