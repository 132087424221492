let apiURL = "";
let fakeMode = false;
let token = "";

if (process.env.REACT_APP_NODE_ENV === 'production' && process.env.REACT_APP_API_URL_PROD) {
    apiURL = process.env.REACT_APP_API_URL_PROD;
} else  if (process.env.REACT_APP_API_URL){
    apiURL = process.env.REACT_APP_API_URL;
}

if (process.env.REACT_APP_API_URL) {
    fakeMode = (
        process.env.REACT_APP_FAKE_MODE === 'true'
    );
}

export const apiConfig = {
    apiURL,
    token,
    fakeMode,
}

const config = {
    apiConfig
};

export default config;
