import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {User} from "../../types";
import {
    authorizeUser,
    getMe,
    registerUser,
    restorePasswordRequest,
    updateProfile,
    sendInvite,
    deleteUser
} from "./userThunks"

export interface UserState {
    user: User | null;
    token: string | null;
    restoreToken: string | null;
    status: "idle" | "loading" | "failed" | "loaded" | "deleted" | "updated";
}

const initialState: UserState = {
    user: null,
    token: null,
    restoreToken: null,
    status: "idle",
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
        setRestoreToken: (state, action: PayloadAction<string | null>) => {
            state.restoreToken = action.payload;
        },
        setStatus: (state, action: PayloadAction<string>) => {
            state.status = "idle";
        },
        clearUserData: (state) => {
            state.user = null;
            state.token = null;
            state.restoreToken = null;
            state.status = "idle";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(authorizeUser.pending, (state) => {
                state.status = "loading";
            })
            .addCase(authorizeUser.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) state.user = action.payload.user;
            })
            .addCase(authorizeUser.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(getMe.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getMe.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) state.user = action.payload;
            })
            .addCase(getMe.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(registerUser.pending, (state) => {
                state.status = "loading";
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) {
                    state.user = action.payload.user;
                    state.token = action.payload.accessToken;
                }
            })
            .addCase(registerUser.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(restorePasswordRequest.pending, (state) => {
                state.status = "loading";
            })
            .addCase(restorePasswordRequest.fulfilled, (state) => {
                state.status = "idle";
            })
            .addCase(restorePasswordRequest.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(updateProfile.pending, (state) => {
                state.status = "loading"
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                if (action.payload && action.payload.data) {
                    state.status = "updated";
                    if (action.payload.updateState) {
                        state.user = action.payload.data;
                    }
                }
            })
            .addCase(updateProfile.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(sendInvite.pending, (state) => {
                state.status = "idle";
            })
            .addCase(sendInvite.fulfilled, (state, action) => {
                if (action.payload) {
                    state.status = "loaded";
                }
            })
            .addCase(sendInvite.rejected, (state) => {
                state.status = "failed"
            })
            .addCase(deleteUser.rejected, (state) => {
                state.status = "failed"
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                if (action.payload) {
                    state.status = "deleted";
                }
            })
            .addCase(deleteUser.pending, (state) => {
                state.status = "idle"
            })
    }
});

export const {setUser, setToken, setStatus, clearUserData} = userSlice.actions;

export const selectUserOperationStatus = (state: RootState) => state.user.status;
export const selectAuthorizedUser = (state: RootState) => state.user.user;
export const getUserToken = (state: RootState) => state.user.token;
export const getRestoreToken = (state: RootState) => state.user.restoreToken;

export default userSlice.reducer;
