import React from "react"
import {isEmpty, isEqual} from "lodash";
import * as S from "../dashboard/styled-components";
import {SqlOrder} from "../../constants";

interface ISortHeaderProps {
    caption: string;
    orderBy: string;
    order: string;
    orderField: string;
    style?: object;
    onChangeOrder: (orderFiled: string, orderByField: string) => void
}



export const SortHeader = (props: ISortHeaderProps) => {
    const getOrderIcon = () => "fa-solid ".concat(isEqual(props.order, SqlOrder.ASС) ? "fa-sort-down" : "fa-sort-up");
    const onClickHandler = () => {
        props.onChangeOrder(props.orderField, props.order);
    }
    return (
        <S.StyledFilterSection onClick={onClickHandler}>
            <S.StyledFilterText style={props.style}>{props.caption}</S.StyledFilterText>
            {(!isEmpty(props.order) && !isEmpty(props.orderBy) && isEqual(props.orderBy, props.orderField))&& (
                <S.StyledIcon style={{order: 1}} className={getOrderIcon()}/>
            )}
        </S.StyledFilterSection>
    )
}
