import React, {useEffect, useRef, useState} from "react"
import {Form, InputGroup, Button, Modal, Table, Overlay, Popover, Spinner} from "react-bootstrap";
import useDebounce from '../../utils/debounce';
import * as S from "./styled-components"
import SideBar from "./sideBar";
import {devicesBoard} from "../../constants/dashboard";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    addDevicesToOrganization, cancelPreOder,
    createOrder,
    getDevicesFeatures, getFreeDevices,
    getOrganizationDevices,
    getPreOrderInformation, returnDevice, unlinkDevice, updateDevice,
    deleteDevice
} from "../../features/device/deviceThunks";
import {
    setStatus,
    setOrderURL,
    setPreOrderToDefault,
    selectDeviceFeatures,
    selectDevices, selectDeviceStatus,
    selectOrderURL, selectDevicesQuantity, selectFreeDevicesList, selectPreOrder
} from "../../features/device/deviceSlice";
import {selectListOfOrganizations, selectOrganization} from "../../features/organization/organizationSlice";
import {head, last, isEmpty, isNull, isEqual, isBoolean, get, set} from "lodash"
import {useNavigate} from "react-router-dom";
import {
    AddDevicesParams,
    CreateOrder,
    Device,
    DeviceFeaturePrice, LogisticStatus,
    OrderData,
    OrderType,
    Organization,
    OrganizationOption, User
} from "../../types";
import {selectAuthorizedUser} from "../../features/user/userSlice";
import {getId, isOrgAdmin, isSuperAdmin} from "../../utils";
import {selectLoading, selectMessage, setMessage} from "../../features/global/globalSlice";
import * as SUser from "../user/styled-components";
import {DevicesErrors} from "../../types";
import {formBasicDevicesQuantity, QueryOrderByDeviceParam, SqlOrder} from "../../constants";
import moment from "moment";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import PaginationFooter from "../paginationFooter";
import {MultiSelect} from "react-multi-select-component";
import IDefaultItemRendererProps from "react-multi-select-component"
import {getAllOrganizations} from "../../features/organization/organizationThunks";
import * as SU from "../user/styled-components";
import isUUID from "validator/lib/isUUID";
import {SortHeader} from "../sort-header";
import { countriesSEPA } from "../../types/sepa-countries";

function DevicesList() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const target = useRef(null);

    const [dataLoaded, setDataLoaded] = useState(false);
    const [shippingError, setShippingError] = useState(false);
    const [orderDetails, setOrderDetails] = useState(false);
    const [successInvoice, setSuccessInvoice] = useState(false);
    const [errors, setErrors] = useState<DevicesErrors>({});
    const [showFilter, setShowFilter] = useState(false);
    const [confirmOrderWindow, setConfirmOrderWindow] = useState(false);
    const [showAddDevice, setShowAddDevice] = useState(false);
    const [confirmReturnDevice, setConfirmReturnDevice] = useState(false);
    const [confirmPreOrderPurchase, setConfirmPreOrderPurchase] = useState(false);
    const [confirmChangeStatus, setConfirmChangeStatus] = useState(false);
    const [organizations, setOrganizations] = useState<OrganizationOption[]>([]);
    const [orderOrganization, setOrderOrganization] = useState<OrganizationOption[]>([]);
    const [addingDevices, setAddingDevices] = useState<OrganizationOption[]>([]);
    const [deviceError, setDeviceError] = useState("");
    const [organizationError, setOrganizationError] = useState("");
    const [pickedDevice, setPickedDevice] = useState<Device | null>(null);
    const [currentStatus, setCurrentStatus] = useState("");
    const [newStatus, setNewStatus] = useState<LogisticStatus | null>(null);

    const [paymentMethod, setPaymentMethod] = useState(OrderType.CHECKOUT_SESSION);
    const [deviceModel, setDeviceModel] = useState("");
    const [devicesQuantity, setDevicesQuantity] = useState(10);
    const debounceQuantity = useDebounce<number>(devicesQuantity, 500);
    const debounceModel = useDebounce<string>(deviceModel, 500);
    const debounceOrganizations = useDebounce<OrganizationOption[]>(organizations, 250);

    const [organizationId, setOrganizationId] = useState("");
    const [currentPopover, setCurrentPopover] = useState<number | null>(null);

    const [deviceId, setDeviceId] = useState<string | null>(null);
    const [confirmDeleteDevice, setConfirmDeleteDevice] = useState(false);
    const [confirmUnassingDevice, setConfirmUnassignDevice] = useState(false);

    const [orderId, setOrderId] = useState<string>("");

    const [order, setOrder] = useState("");
    const [orderBy, setOrderBy] = useState("");

    const emptyArray = [1, 2, 3, 4, 5, 6, 7, 8];

    const devices = useAppSelector(selectDevices);
    const organization = useAppSelector(selectOrganization);
    const devicesFeatures = useAppSelector(selectDeviceFeatures);
    const user = useAppSelector(selectAuthorizedUser);
    const organizationsList = useAppSelector(selectListOfOrganizations);
    const orderURL = useAppSelector(selectOrderURL);
    const status = useAppSelector(selectDeviceStatus);
    const isLoading = useAppSelector(selectLoading);
    const errorMessage = useAppSelector(selectMessage);
    const devicesCount = useAppSelector(selectDevicesQuantity);
    const freeDevicesList = useAppSelector(selectFreeDevicesList);
    const preOrder = useAppSelector(selectPreOrder);

    const limit = 8;
    const [pageNumber, setPageNumber] = useState(1);
    const lastPage = Math.ceil(devicesCount / limit);
    const offset = (pageNumber - 1) * limit; //+ 1;
    let endRange = (offset + limit - 1) > devicesCount ? devicesCount - 1 : offset + limit - 1;

    useEffect(() => {
        loadDevices();
    }, [pageNumber, order, orderBy]);

    useEffect(() => {
        if (!dataLoaded) {
            setDataLoaded(true);
            setTimeout(() => {
                loadDevices();
                if (isSuperAdmin(user) || isOrgAdmin(user)) {
                    loadDeviceFeatures();
                }

                if (isSuperAdmin(user)) {
                    fetchOrganizations();
                    loadFreeDevice();
                }
            }, 200);
        }
    }, []);

    useEffect(() => {
        if (orderDetails) {
            GetPreOrderPrice();
        }
    }, [debounceQuantity, debounceModel, orderDetails]);

    useEffect(() => {
        loadDevices();
    }, [debounceOrganizations]);

    useEffect(() => {
        if (isEqual(status, "invoiceSend") || isEqual(status, "devicesAdded") || isEqual(status, "unlinked") ||
            isEqual(status, "returned") || isEqual(status, "updated") || isEqual(status, "deleted")) {
            if (isEqual(status, "unlinked")) {
                setConfirmUnassignDevice(false);
            }
            if (isEqual(status, "deleted")) {
                setConfirmDeleteDevice(false);
            }
            dropState();
            if (isEqual(status, "invoiceSend")) {
                setSuccessInvoice(true);
            }
        } else if (isEqual(status, "orderCreated") && !isEqual(orderURL, "")) {
            window.open(orderURL, "_blank", "noopener,noreferrer");
            dropState();
        } else if (isEqual(status, "failed")) {
        }
    }, [orderURL, status]);

    useEffect(() => {
        if (!isEmpty(errorMessage)) {
            if (errorMessage && errorMessage.indexOf("device") > 0) {
                setErrors({
                    quantity: errorMessage
                })
            } else if (errorMessage && errorMessage.indexOf("order") > 0) {
                setErrors({
                    order: errorMessage
                })
            }
        }
    }, [errorMessage]);

    async function GetPreOrderPrice() {
        if (!isEmpty(preOrder.orderId)) {
            await cancelPreOrderInfo(false);
        }
        const orderInfo = generateOrderInfo(true)
        await dispatch(getPreOrderInformation(orderInfo));
    }

    const cancelPreOrderInfo = async (order: boolean) => {
        const orderId = preOrder.orderId;
        await dispatch(cancelPreOder({orderId, order}))
    }

    const onChangeOrder = (orderFiled: string, orderByField: string) => {
        let newOrder = "";
        if (isEqual(orderFiled, orderBy)) {
            switch (orderByField) {
                case SqlOrder.ASС:
                    newOrder = SqlOrder.DESC;
                    break;
                case SqlOrder.DESC:
                    newOrder = "";
                    break;
                case "":
                    newOrder = SqlOrder.ASС
                    break;
            }
        } else {
            newOrder = SqlOrder.ASС;
        }

        if (isEmpty(newOrder)) {
            setOrder("");
            setOrderBy("");
        } else {
            setOrder(newOrder);
            setOrderBy(orderFiled);
        }
    }

    const generateOrderInfo = (preOrderStage: boolean): CreateOrder => {
        const pickedDevice: DeviceFeaturePrice = !isEmpty(deviceModel) ? devicesFeatures.filter((df) => isEqual(df.stripeProductId, deviceModel))[0] : devicesFeatures[0];
        const orderData: OrderData = {
            deviceFeatureName: pickedDevice.deviceFeatureName,
            quantity: devicesQuantity
        };
        let order: CreateOrder = {
            orderData: [orderData],
            orderType: paymentMethod,
        };

        if (!preOrderStage) {
            order = {...order, orderId: preOrder.orderId}
        }

        if (isSuperAdmin(user)) {
            order = {
                ...order,
                orgId: organizationId
            }
        }

        return order;
    }

    const dropState = async () => {
        setShippingError(false);
        setOrderDetails(false);
        setShowAddDevice(false);
        setConfirmReturnDevice(false);
        setConfirmChangeStatus(false);
        setConfirmUnassignDevice(false);
        setDeviceModel("");
        setDevicesQuantity(10);
        setOrganizationId("");
        setAddingDevices([]);
        setOrderOrganization([]);
        setDeviceError("");
        setOrganizationError("");
        setDeviceId(null);
        setPickedDevice(null);
        setOrderId("");
        setCurrentStatus("");
        setNewStatus(null);

        dispatch(setStatus("idle"));
        dispatch(setOrderURL(""));
        dispatch(setMessage(null));
        dispatch(setPreOrderToDefault());

        loadDevices();
        loadDeviceFeatures();

        if (isSuperAdmin(user)) {
            loadFreeDevice();
        }
    }

    const loadDevices = async () => {
        const params = {limit, offset, order, orderBy};
        if (isSuperAdmin(user)) {
            const orgIds = debounceOrganizations.map((org) => org.value);
            set(params, "orgIds", orgIds);
        }
        await dispatch(getOrganizationDevices(params));
    }

    const loadDeviceFeatures = async () => {
        await dispatch(getDevicesFeatures(null));
    }

    async function fetchOrganizations() {
        await dispatch(getAllOrganizations(null));
    }

    async function loadFreeDevice() {
        await dispatch(getFreeDevices(null));
    }

    const noDevices = () => devices.length === 0 && dataLoaded && !isSuperAdmin(user);

    const addDevices = () => setDevicesQuantity(devicesQuantity + 1);

    const reduceDevices = () => setDevicesQuantity(devicesQuantity > 1 ? devicesQuantity - 1 : devicesQuantity);

    const buttonStyle = () => {
        return {borderColor: "#FFFF", background: "#FFFF", color: "black"}

    }

    const onOrderDevice = () => {
        if ((validateShippingAddress() || isSuperAdmin(user)) && dataLoaded) {
            setOrderDetails(true);
        }
    }

    const validateShippingAddress = () => {
        let tmpOrganization;
        const isSuperUser = isSuperAdmin(user);

        if (isSuperUser) {
            if (orderDetails) {
                if (isEmpty(organizationId)) {
                    setErrors({...errors, organizationId: "Organization is required."})
                    return false;
                } else {
                    setErrors({});
                    const org = organizationsList.filter((or) => isEqual(or.id, organizationId))[0];
                    tmpOrganization = org;
                }
            } else {
                return true;
            }
        } else {
            tmpOrganization = organization
        }
        if (!isEmpty(tmpOrganization)) {
            const {shippingAddress} = tmpOrganization;
            if (isEmpty(shippingAddress)) {
                if (isSuperUser) {
                    setErrors({...errors, organizationId: "Organization has no shipping address."});
                } else {
                    setShippingError(true);
                }
                return false;
            } else {
                const result = isEmpty(shippingAddress.postalCode) || isEmpty(shippingAddress.city) || isEmpty(shippingAddress.street) || isEmpty(tmpOrganization.country)

                if (isSuperUser) {
                    setErrors({...errors, organizationId: "Organization has no shipping address."});
                } else {
                    setShippingError(result);
                }
                return !result;
            }
        } else {
            setErrors({});
            return false;
        }
    }

    const onNavigateToSettings = () => {
        setShippingError(false);
        navigate("/organization/shippingAddress");
    }

    const getStyleForPaymentMethod = (current: string) => {
        return {background: isEqual(current, paymentMethod) ? "#FAFAFA" : "white"}
    }

    const getOrderModalHeight = () => isSuperAdmin(user) ? "528px" : "448px";

    const getApproximatePrice = () => {
        if (isLoading) {
            return "Pending"
        } else {
            return (<>{(preOrder.fullPrice >= 0) ? preOrder.fullPrice : "Pending "}<span>&#8364;</span></>)
        }
    };

    const onSubmitOrder = async () => {
        if (isSuperAdmin(user)) {
            if (validateShippingAddress()) {
                setOrderDetails(false);
                setConfirmOrderWindow(true);
            }
            return;
        }

        if (!preOrder.notEnough) {
            await makeOrder();
        } else {
            setOrderDetails(false);
            setConfirmPreOrderPurchase(true);
        }
    }

    const onAddDevices = async () => {
        if (addingDevices.length > 0 && !isEmpty(organizationId)) {
            setDeviceError("");
            setOrganizationError("");
            let params: AddDevicesParams = {
                orgId: organizationId,
                serialNumbers: addingDevices.map((d) => d.value)
            }

            if (!isEmpty(orderId)) {
                if (isUUID(orderId)) {
                    params = {...params, orderId}
                    setErrors({});
                } else {
                    setErrors({order: "Order ID is not valid UUID"});
                    return;
                }
            }

            await dispatch(addDevicesToOrganization(params));
        } else {
            if (addingDevices.length > 0) {
                setDeviceError("");
            } else {
                setDeviceError("No device picked")
            }
            if (isEmpty(organizationId)) {
                setOrganizationError("Organization is required")
            } else {
                setOrganizationError("");
            }
        }
    }

    const makeOrder = async () => {
        const orderInfo = generateOrderInfo(false)
        await dispatch(createOrder(orderInfo));
    }

    const onReturnTheDevice = async (event: React.MouseEvent<HTMLDivElement>) => {
        dispatch(setStatus("idle"));
        const id = getId(event);
        if (isUUID(id)) {
            const device = devices.filter((d) => isEqual(d.id, id))[0];
            if (!isEmpty(device)) {
                setPickedDevice(device);
                setDeviceId(id);
                setConfirmReturnDevice(true);
                setCurrentPopover(null);
            }
        }
    }

    const onUnassignDevice = (event: React.MouseEvent<HTMLDivElement>) => {
        dispatch(setStatus("idle"));
        const id = getId(event);
        if (isUUID(id)) {
            const device = devices.filter((d) => isEqual(d.id, id))[0];
            if (!isEmpty(device) && !isNull(device.deviceOrganization)) {
                setDeviceId(id);
                setConfirmUnassignDevice(true);
                setCurrentPopover(null);
            }
        }
    }

    const onDeleteDevice = (event: React.MouseEvent<HTMLDivElement>) => {
        dispatch(setStatus("idle"));
        const id = getId(event);
        if (isUUID(id)) {
            const device = devices.filter((d) => isEqual(d.id, id))[0];
            if (!isEmpty(device)) {
                setDeviceId(id);
                setConfirmDeleteDevice(true);
                setCurrentPopover(null);
            }
        }
    }

    const onUnassingDeviceConfirm = async () => {
        if (deviceId && isUUID(deviceId)) {
            await dispatch(unlinkDevice({deviceId}));
        }
    }

    const onDeleteDeviceConfirm = async () => {
        if (deviceId && isUUID(deviceId)) {
            await dispatch(deleteDevice(deviceId));
        }
    }

    const onReturnDeviceConfirm = async () => {
        if (!isNull(deviceId) && isUUID(deviceId)) {
            await dispatch(returnDevice([deviceId]))
        }
    }

    const onFilter = () => {
        // setCurrentPopover(999);
    }
    const onClearFilter = () => {
        setTimeout(() => {
            setOrganizations([]);
            setShowFilter(false);
        }, 100);
    }

    const onSelectOrganizations = (selectedList: any, selectedItem: any) => {
        setOrganizations(selectedList);
    }

    const onSelectOrganizationForOrder = (selectedList: any, selectedItem: any) => {
        if (selectedList.length === 1) {
            setErrors({});
            setOrganizationId(get(head(selectedList), "value", ""));
            setOrderOrganization(selectedList);
        } else if (selectedList.length > 0) {
            setErrors({});
            setOrganizationId(get(last(selectedList), "value", ""));
            // @ts-ignore
            setOrderOrganization([last(selectedList)]);
        } else if (selectedList.length === 0) {
            setOrganizationId("");
            setOrderOrganization([]);
            setErrors({});
        }
    }


    const CustomItemRenderer = ({
                                    checked,
                                    option,
                                    onClick,
                                    disabled,
                                    // @ts-ignore
                                }: IDefaultItemRendererProps) => (
        <div className={`item-renderer ${disabled ? "disabled" : ""}`}>
            <input
                type="checkbox"
                onChange={onClick}
                checked={checked}
                tabIndex={-1}
                disabled={disabled}
                style={{opacity: 0}}
            />
            <span>{option.label}</span>
        </div>
    );

    const onSelectDevices = (selectedList: any, selectedItem: any) => {
        setAddingDevices(selectedList);
    }

    const onSelectAll = () => {
        setTimeout(() => {
            setOrganizations(mapOrganizationsToOptions(organizationsList));
            setShowFilter(false);
        }, 100);
    }

    const onApplyFilters = () => {
        setTimeout(() => {
            setShowFilter(false);
        }, 100);
    }

    const mapOrganizationsToOptions = (list: Organization[]): OrganizationOption[] => list.map((org) => {
        return {
            value: org.id,
            label: org.name,
            key: `org-opt-${org.id}`
        }
    });

    const mapDevicesToOptions = (list: Device[]): OrganizationOption[] => list.map((dev) => {
        return {
            value: dev.serialNumber,
            label: dev.serialNumber,
            key: `org-opt-${dev.serialNumber}`
        }
    });


    const onSubmitSuperAdminOrder = async () => {
        setConfirmOrderWindow(false);
        setOrganizationId("");
        setOrderOrganization([]);
        setErrors({});

        await makeOrder();
    }

    const onSubmitStatusChange = async () => {
        if (!isNull(newStatus) && !isNull(pickedDevice)) {
            await dispatch(updateDevice({...pickedDevice, logisticStatus: newStatus}))
        }
    }

    const declineStatusChange = () => {
        setPickedDevice(null);
        setCurrentStatus("");
        setNewStatus(null);
        setConfirmChangeStatus(false);
    }

    const declineSuperAdminOrder = async () => {
        setConfirmOrderWindow(false);
        setOrganizationId("");
        setOrderOrganization([]);
        setErrors({});

        if (!isEmpty(preOrder.orderId)) {
            await cancelPreOrderInfo(true);
        }
    }

    const onDeclineUnassignDevice = () => {
        setConfirmUnassignDevice(false);
        setDeviceId(null);
    }

    const onDeclineDeleteDevice = () => {
        setConfirmDeleteDevice(false);
        setDeviceId(null);
    }

    const declineReturnDevice = () => {
        setConfirmReturnDevice(false);
        setPickedDevice(null);
    }

    const declinePreorderPurchase = async () => {
        setConfirmPreOrderPurchase(false);
        await cancelPreOrderInfo(true);
    }

    const onSubmitPreOrderPurchase = async () => {
        setConfirmPreOrderPurchase(false);
        await makeOrder();
    }

    const onChangeDevicesQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const value = Number(event.target.value);
            if (value >= 1) {
                setDevicesQuantity(value);
            }
        } catch (error) {
        }
    }

    const renderPreOrderError = () => preOrder.fullPrice > 0 && preOrder.notEnough;

    const renderModalWithError = () => {
        return (
            <S.StyledEditRoleModal show={shippingError}
                                   centered={true}
                                   onHide={() => setShippingError(false)}>
                <Modal.Header className="border-0" closeButton={true}/>
                <Modal.Body>
                    Unfortunately, you have not <strong>Shipping</strong> info saved, please fill in the information to
                    proceed
                </Modal.Body>
                <Modal.Footer className="border-0" style={{justifyContent: "center"}}>
                    <S.StyledNoDevicesOrderButton onClick={onNavigateToSettings}>Go to
                        Shipping</S.StyledNoDevicesOrderButton>
                </Modal.Footer>
            </S.StyledEditRoleModal>
        )
    }

    const renderSuccessInvoice = () => {
        return (
            <S.StyledEditRoleModal show={successInvoice}
                                   centered={true}
                                   onHide={() => setSuccessInvoice(false)}>
                <Modal.Body>
                    Invoice has been sent to your Organization email. The order is valid for payment within 14 days. Your order will be deleted without payment after 14 days.
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <S.StyledNoDevicesOrderButton
                        onClick={() => setSuccessInvoice(false)}>Ok</S.StyledNoDevicesOrderButton>
                </Modal.Footer>
            </S.StyledEditRoleModal>
        )
    }

    const renderUnassignDeviceConfirm = (isDelete: boolean) => {
        return (
            <S.StyledEditRoleModal show={isDelete ? confirmDeleteDevice : confirmUnassingDevice}
                                   centered={true}
                                   onHide={() => isDelete ? setConfirmDeleteDevice(false) : setConfirmUnassignDevice(false)}>
                <Modal.Body>
                    Are sure that you want to remove device from {isDelete ? "DB" : "organization"}?
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="secondary" onClick={isDelete ? onDeclineDeleteDevice : onDeclineUnassignDevice}>
                        No
                    </Button>
                    <Button variant="primary" onClick={isDelete ? onDeleteDeviceConfirm : onUnassingDeviceConfirm}>
                        Yes
                        {isLoading && (<SUser.StyledSingInSpinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />)}
                    </Button>
                </Modal.Footer>
            </S.StyledEditRoleModal>
        );
    }

    const renderConfirmReturnDevice = () => {
        const serialNumber = get(pickedDevice, "serialNumber", "");
        return (
            <S.StyledEditRoleModal show={confirmReturnDevice}
                                   centered={true}
                                   onHide={declineReturnDevice}>
                <Modal.Body>
                    Are you sure, you want to return device <strong>{serialNumber}</strong>?
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="secondary" onClick={declineReturnDevice}>
                        No
                    </Button>
                    <Button variant="primary" onClick={onReturnDeviceConfirm}>
                        Yes
                        {isLoading && (<SUser.StyledSingInSpinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />)}
                    </Button>
                </Modal.Footer>
            </S.StyledEditRoleModal>
        )
    }

    const renderPreOrderConfirmation = () => {
        return (
            <S.StyledEditRoleModal show={confirmPreOrderPurchase}
                                   centered={true}
                                   onHide={declineReturnDevice}>
                <Modal.Body>
                    Unfortunately, we do not have the corresponding number of devices in stock, but you can make a
                    preorder and we will contact you shortly?
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="secondary" onClick={declinePreorderPurchase}>
                        No, I don’t want preorder
                    </Button>
                    <Button variant="primary" onClick={onSubmitPreOrderPurchase}>
                        Yes, I want preorder
                        {isLoading && (<SUser.StyledSingInSpinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />)}
                    </Button>
                </Modal.Footer>
            </S.StyledEditRoleModal>
        );
    }

    const headerAdditionalStyles = {padding: "16px 8px", gap: "4px"};
    const tdAdditionalStyled = {padding: "22px 8px 22px 8px", gap: "4px"};
    const errorStyle = {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "16px",
        color: "red",
        marginTop: "5px"
    }

    const preLoaderWidth = (): number => isSuperAdmin(user) ? 7 : 5;


    const onHideOrderDetails = async () => {
        setOrderDetails(false);
        setOrganizationId("");
        setErrors({});
        setOrderOrganization([]);
        await cancelPreOrderInfo(false);
    }

    const getListOfStatuses = () => {
        return Object.values(LogisticStatus).map((ls) => {
            return (
                <S.StyledUserRoleOption value={ls}>
                    {ls}
                </S.StyledUserRoleOption>
            )
        })
    }

    const handleDeviceStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value, id} = event.target;

        if (isUUID(id) && !isEmpty(value)) {
            const currDevice = devices.filter((d) => isEqual(d.id, id))[0];
            const newValue = Object.values(LogisticStatus).filter((lv) => isEqual(lv, value))[0];
            if (!isEmpty(currDevice) && !isEmpty(newValue)) {
                setNewStatus(newValue);
                setPickedDevice(currDevice);
                setCurrentStatus(get(currDevice, "logisticStatus", ""))
                setConfirmChangeStatus(true);
            }
        }
    }

    const handleSEPACountries = () => {
    if (organization !== null && organization.country && countriesSEPA.hasOwnProperty(organization.country)) 
        return true;
        
        return false
    }

    const renderOrderDetails = () => {
        const topMargin = () => {
            return {marginTop: `${isSuperAdmin(user) ? "30px" : "0px"}`}
        }
        return (
            <S.StyledEditRoleModal show={orderDetails}
                                   centered={true}
                                   onHide={onHideOrderDetails}
                                   style = {{minWidth: '340px'}}
            >
                <Modal.Header closeButton={true} className="border-0">
                    <Modal.Title>Order details</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{width: "100%", height: getOrderModalHeight(), marginBottom: '20px'}}>
                    <Form>
                        <S.StyledPaymentDetailsCaption>Set payment details</S.StyledPaymentDetailsCaption>
                        <S.StyledPaymentMethodFrame>
                            <S.StyledPaymentMethodSection>
                                <S.StyledPaymentOrderCaption>Select payment method</S.StyledPaymentOrderCaption>
                                <S.StyledPaymentMethodsSectionFrame>
                                    <S.StyledPaymentCardMethod
                                        style={getStyleForPaymentMethod(OrderType.CHECKOUT_SESSION)}>
                                        <Form.Check type="radio"
                                                    label="Card"
                                                    disabled={isLoading}
                                                    value={OrderType.CHECKOUT_SESSION}
                                                    checked={isEqual(paymentMethod, OrderType.CHECKOUT_SESSION)}
                                                    onChange={() => setPaymentMethod(OrderType.CHECKOUT_SESSION)}
                                        />
                                    </S.StyledPaymentCardMethod>
                                    { (isSuperAdmin(user) || handleSEPACountries()) && <S.StyledPaymentInvoiceMethod style={getStyleForPaymentMethod(OrderType.INVOICE)}>
                                        <Form.Check type="radio"
                                                    label="Invoice"
                                                    disabled={isLoading}
                                                    value={OrderType.INVOICE}
                                                    checked={isEqual(paymentMethod, OrderType.INVOICE)}
                                                    onChange={() => setPaymentMethod(OrderType.INVOICE)}
                                        />
                                    </S.StyledPaymentInvoiceMethod> }
                                </S.StyledPaymentMethodsSectionFrame>
                            </S.StyledPaymentMethodSection>
                            <S.StyledDeviceModelsSection>
                                <S.StyledPaymentOrderCaption>Model of device</S.StyledPaymentOrderCaption>
                                <S.StyledDeviceModelSelect value={deviceModel}
                                                           onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDeviceModel(event.target.value)}
                                                           disabled={isLoading}
                                >
                                    {devicesFeatures.map((df: DeviceFeaturePrice) => {
                                        return (
                                            <S.StyledUserRoleOption value={df.stripeProductId}
                                                                    style={{height: "16px"}}
                                                                    key={`feature-${df.id}`}
                                            >
                                                {df.deviceFeatureName}
                                            </S.StyledUserRoleOption>
                                        )
                                    })}
                                </S.StyledDeviceModelSelect>
                            </S.StyledDeviceModelsSection>
                            <S.StyledDeviceQuantitySection>
                                <S.StyledPaymentOrderCaption>Quantity</S.StyledPaymentOrderCaption>
                                <Form.Group id={formBasicDevicesQuantity}>
                                    {renderDeviceQuantityGroup(418, 48)}
                                    <Form.Control.Feedback type="invalid">{errors.quantity}</Form.Control.Feedback>
                                </Form.Group>
                            </S.StyledDeviceQuantitySection>
                            {isSuperAdmin(user) && (
                                <S.StyledPaymentOrganizationSection>
                                    <S.StyledPaymentOrderCaption>Organization name</S.StyledPaymentOrderCaption>
                                    <Form.Group style={{width: "100%"}}>
                                        <MultiSelect
                                            options={mapOrganizationsToOptions(organizationsList)}
                                            value={orderOrganization}
                                            labelledBy="Select organizations..."
                                            onChange={onSelectOrganizationForOrder}
                                            debounceDuration={0}
                                            hasSelectAll={false}
                                            closeOnChangedValue={true}
                                            overrideStrings={{selectSomeItems: "Select organization"}}
                                            ItemRenderer={CustomItemRenderer}
                                        />
                                        {!isEmpty(errors.organizationId) && (
                                            <div style={errorStyle}>
                                                {errors.organizationId}
                                            </div>
                                        )}
                                    </Form.Group>
                                </S.StyledPaymentOrganizationSection>
                            )}
                            <S.StyledPaymentSummarySection style={topMargin()}>
                                <S.StyledOrderSummaryCaption
                                    style={{marginBottom: "10px"}}>Summary:</S.StyledOrderSummaryCaption>
                                <S.StyledPaymentSummaryDetails>
                                    <S.StyledSummaryCaption>Devices: </S.StyledSummaryCaption>
                                    <S.StyledSummaryValue>{devicesQuantity}</S.StyledSummaryValue>
                                </S.StyledPaymentSummaryDetails>
                                <S.StyledPaymentSummaryDetails style={{flexGrow: 2}}>
                                    <S.StyledSummaryCaption>Total amount: </S.StyledSummaryCaption>
                                    <S.StyledSummaryValue>{getApproximatePrice()}</S.StyledSummaryValue>
                                </S.StyledPaymentSummaryDetails>
                                <S.StyledPaymentSummaryDetails>
                                    <S.StyledPaymentOrderText>
                                        You pay for the first 30 days of use now. After using the device(s) for 30 days, you will be billed for another 30 days in advance.
                                    </S.StyledPaymentOrderText>
                                </S.StyledPaymentSummaryDetails>
                                {(isSuperAdmin(user) && renderPreOrderError()) && (
                                    <S.StyledPaymentSummaryDetails style={{flexGrow: 3, marginTop: '0'}}>
                                        <div style={errorStyle}>
                                            Unfortunately, we do not have the corresponding number of devices in stock,
                                            it will be preorder flow
                                        </div>
                                    </S.StyledPaymentSummaryDetails>
                                )}
                            </S.StyledPaymentSummarySection>
                        </S.StyledPaymentMethodFrame>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <S.StyledNoDevicesOrderButton onClick={onSubmitOrder}
                                                  disabled={isLoading}
                    >
                        {isLoading && (<SUser.StyledSingInSpinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />)}
                        Submit order
                    </S.StyledNoDevicesOrderButton>
                </Modal.Footer>
            </S.StyledEditRoleModal>
        )
    };

    const renderAddDevices = () => {
        return (
            <S.StyledEditRoleModal show={showAddDevice}
                                   centered={true}
                                   onHide={dropState}
                                   style = {{minWidth: '340px'}}>
                <Modal.Header closeButton={true} className="border-0">
                    <Modal.Title>Add devices</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{width: "100%", height: "440px"}}>
                    <S.StyledPaymentMethodFrame>
                        <S.StyledDeviceModelsSection style={{height: isEmpty(deviceError) ? "72px" : "97px"}}>
                            <S.StyledPaymentOrderCaption>Devices</S.StyledPaymentOrderCaption>
                            <Form.Group style={{width: "100%"}}>
                                <MultiSelect
                                    options={mapDevicesToOptions(freeDevicesList)}
                                    value={addingDevices}
                                    labelledBy="Select devices..."
                                    onChange={onSelectDevices}
                                    debounceDuration={0}
                                    isCreatable={true}
                                    overrideStrings={{selectSomeItems: "Select devices"}}
                                />
                                {!isEmpty(deviceError) && (
                                    <div style={errorStyle}>
                                        {deviceError}
                                    </div>
                                )}
                            </Form.Group>
                        </S.StyledDeviceModelsSection>
                        <S.StyledPaymentOrganizationSection
                            style={{order: 2, height: isEmpty(deviceError) ? "48px" : "73px"}}>
                            <S.StyledPaymentOrderCaption>Order ID</S.StyledPaymentOrderCaption>
                            <SU.StyledRegistrationInput type="input"
                                                        disabled={isLoading}
                                                        style={{height: "38px"}}
                                                        isInvalid={!!errors.order}
                                                        value={orderId}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOrderId(event.target.value)}
                            />
                            <S.StyledFeedback style={{order: 2}} type="invalid">{errors.order}</S.StyledFeedback>
                        </S.StyledPaymentOrganizationSection>
                        <S.StyledPaymentOrganizationSection style={{
                            height: isEmpty(deviceError) ? "48px" : "73px",
                            marginTop: isEmpty(errors.order) ? "25px" : "40px"
                        }}>
                            <S.StyledPaymentOrderCaption>Organization name</S.StyledPaymentOrderCaption>
                            <Form.Group style={{width: "100%"}}>
                                <MultiSelect
                                    options={mapOrganizationsToOptions(organizationsList)}
                                    value={orderOrganization}
                                    labelledBy="Select organizations..."
                                    onChange={onSelectOrganizationForOrder}
                                    debounceDuration={0}
                                    hasSelectAll={false}
                                    closeOnChangedValue={true}
                                    overrideStrings={{selectSomeItems: "Select organization"}}
                                    ItemRenderer={CustomItemRenderer}
                                />
                                {!isEmpty(organizationError) && (
                                    <div style={errorStyle}>
                                        {organizationError}
                                    </div>
                                )}
                            </Form.Group>
                        </S.StyledPaymentOrganizationSection>
                    </S.StyledPaymentMethodFrame>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <S.StyledNoDevicesOrderButton onClick={onAddDevices}
                                                  disabled={isLoading}
                    >
                        {isLoading && (<SUser.StyledSingInSpinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />)}
                        Add devices
                    </S.StyledNoDevicesOrderButton>
                </Modal.Footer>
            </S.StyledEditRoleModal>
        )
    }

    const renderOrderConfirmation = () => {
        const orgName = get(head(orderOrganization), "label", "");
        return (
            <S.StyledEditRoleModal show={confirmOrderWindow}
                                   centered={true}
                                   onHide={declineSuperAdminOrder}
                                   style = {{minWidth: '340px'}}>
                <Modal.Header className="border-0" closeButton={true}/>
                <Modal.Body>
                    Are you sure you want to initiate the payment for <strong>{orgName}</strong>?
                </Modal.Body>
                <Modal.Footer className="border-0" style={{justifyContent: "center"}}>
                    <S.StyledProfileResetPassword variant="secondary" disabled={isLoading}
                                                  onClick={declineSuperAdminOrder}>
                        No
                    </S.StyledProfileResetPassword>
                    <S.StyledProfileSaveButton variant="primary"
                                               ref={target}
                                               disabled={isLoading}
                                               onClick={onSubmitSuperAdminOrder}>
                        {isLoading && (<SU.StyledSingInSpinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />)}
                        Yes
                    </S.StyledProfileSaveButton>
                </Modal.Footer>
            </S.StyledEditRoleModal>
        )
    }

    const renderConfirmStatusChange = () => {
        return (
            <S.StyledEditRoleModal show={confirmChangeStatus}
                                   centered={true}
                                   onHide={declineStatusChange}>
                <Modal.Header className="border-0" closeButton={true}/>
                <Modal.Body>
                    Do you want to change the <strong>{get(pickedDevice, "serialNumber", "")}</strong> status
                    from <strong>{currentStatus}</strong> to <strong>{newStatus}</strong>?
                </Modal.Body>
                <Modal.Footer className="border-0" style={{justifyContent: "center"}}>
                    <S.StyledProfileResetPassword variant="secondary" disabled={isLoading}
                                                  onClick={declineStatusChange}>
                        No
                    </S.StyledProfileResetPassword>
                    <S.StyledProfileSaveButton variant="primary"
                                               ref={target}
                                               disabled={isLoading}
                                               onClick={onSubmitStatusChange}>
                        {isLoading && (<SU.StyledSingInSpinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />)}
                        Yes
                    </S.StyledProfileSaveButton>
                </Modal.Footer>
            </S.StyledEditRoleModal>
        );
    }

    const renderDeviceQuantityGroup = (width: number = 360, height: number = 56) => {
        return (
            <>
                <S.StyledInputGroup style={{
                    width: `${width}px`,
                    height: `${height}px`,
                    border: "1px solid #E2E8F0",
                    borderRadius: "4px",
                    flex: "none",
                    order: 3,
                    flexGrow: 0,
                }}>
                    <Button variant="secondary" style={buttonStyle()} onClick={reduceDevices}>-</Button>
                    <Form.Control type="input"
                                  value={devicesQuantity}
                                  style={{textAlign: "center", borderColor: "#FFFF"}}
                                  onChange={onChangeDevicesQuantity}
                    />
                    <Button variant="secondary" style={buttonStyle()} onClick={addDevices}>+</Button>
                </S.StyledInputGroup>
            </>
        )
    }

    return (
        <S.StyledDashboardContainer>
            <SideBar currentComponent={devicesBoard}/>

            {shippingError && renderModalWithError()}
            {orderDetails && renderOrderDetails()}
            {successInvoice && renderSuccessInvoice()}
            {confirmOrderWindow && renderOrderConfirmation()}
            {showAddDevice && renderAddDevices()}
            {confirmUnassingDevice && renderUnassignDeviceConfirm(false)}
            {confirmDeleteDevice && renderUnassignDeviceConfirm(true)}
            {confirmReturnDevice && renderConfirmReturnDevice()}
            {confirmPreOrderPurchase && renderPreOrderConfirmation()}
            {confirmChangeStatus && renderConfirmStatusChange()}
            {noDevices() ? (
                <S.StyledNoDevicesCentralFrame>
                    <S.StyledNoDevicesCentralImageFrame>
                        <S.StyledNoDevicesImageGroup>
                            <S.StyledNoDevicesImageWorld/>
                            <S.StyledNoDevicesImagePlants/>
                            <S.StyledNoDevicesImageLocators/>
                            <S.StyleNoDevicesImageCharacter_1/>
                            <S.StyleNoDevicesImageCharacter_2/>
                            <S.StyleNoDevicesImageCharacter_3/>
                            <S.StyleNoDevicesImageCharacter_4/>
                            <S.StyleNoDevicesImageCharacter_5/>
                        </S.StyledNoDevicesImageGroup>
                    </S.StyledNoDevicesCentralImageFrame>

                    <S.StyledNoDevicesCentralMessage>
                        You don't have any devices yet
                    </S.StyledNoDevicesCentralMessage>
                    <S.StyledNoDevicesCentralText>
                    Dear customer, in the box below you must select the number of units you wish to order. Enter the quantity and press the blue button below "order units" to confirm the order.
                     Remember that you can terminate the lease after 3 months without additional costs.
                    </S.StyledNoDevicesCentralText>

                    {(isSuperAdmin(user) || isOrgAdmin(user)) && (
                        <>
                            {renderDeviceQuantityGroup()}
                            <S.StyledNoDevicesOrderButton onClick={onOrderDevice}>
                                Order devices
                            </S.StyledNoDevicesOrderButton>
                        </>

                    )}
                </S.StyledNoDevicesCentralFrame>
            ) : (
                <S.StyledMainContainer>
                    <S.StyledTopBar>
                        <S.StyledDevicesMainHeader>
                            <S.StyledDevicesHeaderCaption>Devices</S.StyledDevicesHeaderCaption>
                            <S.StyledDevicesOrderHeaderButtonContainer>
                                {(isSuperAdmin(user) || isOrgAdmin(user)) && (
                                    <S.StyledDevicesOrderHeaderButton onClick={onOrderDevice}>
                                        Order device
                                    </S.StyledDevicesOrderHeaderButton>
                                )}
                                {isSuperAdmin(user) && (
                                    <S.StyledDevicesOrderHeaderButton style={{order: 1}}
                                                                      onClick={() => setShowAddDevice(true)}
                                    >
                                        Add devices
                                    </S.StyledDevicesOrderHeaderButton>
                                )}
                            </S.StyledDevicesOrderHeaderButtonContainer>
                        </S.StyledDevicesMainHeader>
                    </S.StyledTopBar>
                    <S.StyledCenterContainer>
                        <S.StyledDevicesFormHeader>
                            <S.StyledDevicesFormHeaderCaption>Your devices</S.StyledDevicesFormHeaderCaption>
                            <S.StyledDevicesFormHeaderButtonContainer>
                                <S.StyledDeviceHeaderSearchContainer>
                                    {/*<S.StyledDevicesSearchIcon className="fa-solid fa-magnifying-glass"/>*/}
                                    {/*<S.StyledDevicesSearchCaption>Search</S.StyledDevicesSearchCaption>*/}
                                </S.StyledDeviceHeaderSearchContainer>
                                {isSuperAdmin(user) && (
                                    <>
                                        <S.StyledDeviceHeaderFilterContainer>
                                            <OverlayTrigger trigger="click"
                                                            placement="left"
                                                            rootClose={true}
                                                            rootCloseEvent="mousedown"
                                                            key={999}
                                                            show={isEqual(currentPopover, 999)}
                                                            onToggle={() => {
                                                                if (isEqual(currentPopover, 999)) {
                                                                    setCurrentPopover(null);
                                                                } else {
                                                                    setCurrentPopover(999);
                                                                }
                                                            }}
                                                            overlay={
                                                                <Popover id="popover-basic">
                                                                    <Popover.Header as="h3"
                                                                                    style={{background: "#FFFFFF"}}>
                                                                        Organizations
                                                                    </Popover.Header>
                                                                    <Popover.Body>
                                                                        <S.StyledTopFilterSection
                                                                            style={{height: "1px"}}>
                                                                        </S.StyledTopFilterSection>
                                                                        <MultiSelect
                                                                            options={mapOrganizationsToOptions(organizationsList)}
                                                                            value={debounceOrganizations}
                                                                            labelledBy="Select organizations..."
                                                                            onChange={onSelectOrganizations}
                                                                            debounceDuration={0}
                                                                        />
                                                                    </Popover.Body>
                                                                </Popover>
                                                            }
                                            >
                                                <S.StyledDeviceHeaderFilterContainer style={{cursor: "pointer"}}>
                                                    <S.StyledDevicesFilterIcon className="fa-solid fa-sliders"/>
                                                    <S.StyledDevicesFilterCaption>Filter</S.StyledDevicesFilterCaption>
                                                </S.StyledDeviceHeaderFilterContainer>
                                            </OverlayTrigger>
                                        </S.StyledDeviceHeaderFilterContainer>

                                    </>)}
                            </S.StyledDevicesFormHeaderButtonContainer>
                        </S.StyledDevicesFormHeader>

                        <S.StyledTableWrapper>
                            <Table style={{tableLayout: "fixed"}}>
                                <thead>
                                <S.StyledDevicesTR isSuperAdmin={isSuperAdmin(user)}>
                                    <S.StyledDevicesBulkHeader style={{padding: "12px 8px 12px 16px", gap: "4px"}}/>
                                    <S.StyledDevicesIMEIHeader style={headerAdditionalStyles}>
                                        <SortHeader caption={"Serial number"}
                                                    orderBy={orderBy}
                                                    order={order}
                                                    style={{minWidth: "100px"}}
                                                    orderField={QueryOrderByDeviceParam.SERIAL_NUMBER}
                                                    onChangeOrder={onChangeOrder}
                                        />
                                    </S.StyledDevicesIMEIHeader>
                                    <S.StyledDevicesStatusHeader style={headerAdditionalStyles}>
                                        <SortHeader caption={"Calibration date"}
                                                    orderBy={orderBy}
                                                    order={order}
                                                    style={{minWidth: "100px"}}
                                                    orderField={QueryOrderByDeviceParam.CALIBRATION_EXPIRES}
                                                    onChangeOrder={onChangeOrder}
                                        />
                                    </S.StyledDevicesStatusHeader>
                                    {isSuperAdmin(user) && (
                                        <>
                                            <S.StyledDevicesStatusHeader style={headerAdditionalStyles}>
                                                <SortHeader caption={"Organization name"}
                                                            orderBy={orderBy}
                                                            order={order}
                                                            style={{minWidth: "120px"}}
                                                            orderField={QueryOrderByDeviceParam.ORG_NAME}
                                                            onChangeOrder={onChangeOrder}
                                                />
                                            </S.StyledDevicesStatusHeader>
                                            <S.StyledDevicesStatusHeader style={headerAdditionalStyles}>
                                                <SortHeader caption={"Order ID"}
                                                            orderBy={orderBy}
                                                            order={order}
                                                            style={{minWidth: "100px"}}
                                                            orderField={QueryOrderByDeviceParam.ORDER_ID}
                                                            onChangeOrder={onChangeOrder}
                                                />
                                            </S.StyledDevicesStatusHeader>
                                            <S.StyledDevicesStatusHeader style={headerAdditionalStyles}>
                                                <SortHeader caption={"Logistic status"}
                                                            orderBy={orderBy}
                                                            order={order}
                                                            style={{minWidth: "100px"}}
                                                            orderField={QueryOrderByDeviceParam.LOGISTIC_STATUS}
                                                            onChangeOrder={onChangeOrder}
                                                />
                                            </S.StyledDevicesStatusHeader>
                                        </>
                                    )}
                                    <S.StyledDevicesActionsHeader style={{padding: "12px 8px", gap: "4px"}}/>
                                </S.StyledDevicesTR>
                                </thead>
                                <tbody>
                                {isLoading ? (
                                        <tr>
                                            <td rowSpan={preLoaderWidth()} colSpan={preLoaderWidth()}>
                                                <div className="text-center py-5">
                                                    <Spinner animation="border"/>
                                                </div>
                                            </td>
                                        </tr>
                                    ) :
                                    (devices.length === 0
                                        ?
                                        (emptyArray.map((it, i) => {
                                            return (
                                                <S.StyledDevicesTR key={"emptyRow" + i}>
                                                    <S.StyledDevicesIMEITD colSpan={preLoaderWidth()}
                                                                           style={{
                                                                               width: "100%",
                                                                               textAlign: "center",
                                                                               borderBottom: "0px"
                                                                           }}>
                                                        {isEqual(i, 3) ? (<h1>This organization has no ordered devices
                                                            yet</h1>) : null}
                                                    </S.StyledDevicesIMEITD>
                                                </S.StyledDevicesTR>
                                            )
                                        }))
                                        : (devices.map((device, index) => {
                                            const deviceAvailable = isNull(device.deviceOrganization);
                                            const deviceReturned = deviceAvailable ? true : (!isBoolean(device.returned) ? true : device.returned);
                                            const orgCaption = deviceAvailable
                                                ? ('None')
                                                : (get(device, "deviceOrganization.organization.name", ""));
                                            return (
                                                <S.StyledDevicesTR isSuperAdmin={isSuperAdmin(user)} key={"device+" + device.id}>
                                                    <S.StyledDevicesBulkTD style={tdAdditionalStyled}/>
                                                    <S.StyledDevicesIMEITD style={{...tdAdditionalStyled}}>
                                                        {device.serialNumber}
                                                    </S.StyledDevicesIMEITD>
                                                    <S.StyledDevicesCalibrationDateTD style={tdAdditionalStyled}>
                                                        {isNull(device.calibrationExpires) ? "None" : moment(device.calibrationExpires).format("DD-MM-YYYY")}
                                                    </S.StyledDevicesCalibrationDateTD>
                                                    {isSuperAdmin(user) && (
                                                        <>
                                                            <S.StyledDevicesCalibrationDateTD
                                                                style={tdAdditionalStyled}>
                                                                {orgCaption}
                                                            </S.StyledDevicesCalibrationDateTD>
                                                            <S.StyledDevicesCalibrationDateTD
                                                                style={tdAdditionalStyled}>
                                                                {get(device, "deviceOrder.orderId", "None")}
                                                            </S.StyledDevicesCalibrationDateTD>
                                                            <S.StyledDevicesCalibrationDateTD>
                                                                <S.StyledUserRoleSelect
                                                                    style={{
                                                                        maxWidth: "240px",
                                                                        width: "100%",
                                                                        minWidth: "130px",
                                                                        height: "42px"
                                                                    }}
                                                                    onChange={handleDeviceStatusChange}
                                                                    disables={isLoading.toString()}
                                                                    id={get(device, "id", "")}
                                                                    value={get(device, "logisticStatus", "")}
                                                                >
                                                                    {getListOfStatuses()}
                                                                </S.StyledUserRoleSelect>
                                                            </S.StyledDevicesCalibrationDateTD>
                                                        </>
                                                    )}
                                                    {(isSuperAdmin(user) || isOrgAdmin(user)) && (
                                                        <S.StyledUsersActionTD
                                                            style={{padding: "22px 8px", gap: "4px"}}>
                                                            <OverlayTrigger trigger="click"
                                                                            placement="left"
                                                                            rootClose={true}
                                                                            rootCloseEvent="mousedown"
                                                                            key={index}
                                                                            show={isEqual(currentPopover, index)}
                                                                            onToggle={() => {
                                                                                if (isEqual(currentPopover, index)) {
                                                                                    setCurrentPopover(null);
                                                                                } else {
                                                                                    setCurrentPopover(index);
                                                                                }
                                                                            }}
                                                                            overlay={
                                                                                <S.StyledPopoverMenuOrgs id={index}
                                                                                                         style={{height: "50px", background: deviceReturned ? "#f3f3f3" :null}}>
                                                                                    <div style={{right: "0px"}}
                                                                                         id={device.id}>
                                                                                        {isOrgAdmin(user) && (
                                                                                            <S.StyledPopoverMenuLink
                                                                                                style={{background: deviceReturned ? "#f3f3f3" : ""}}
                                                                                                title={deviceReturned ? "Instructions was sent to admin" : ""}
                                                                                                onClick={ deviceReturned ? () => {setCurrentPopover(null);} : onReturnTheDevice}
                                                                                            >
                                                                                                Return the device
                                                                                            </S.StyledPopoverMenuLink>
                                                                                        )}
                                                                                        {isSuperAdmin(user) && (
                                                                                            <S.StyledPopoverMenuLink
                                                                                                style={{background: deviceReturned ? "#f3f3f3" : ""}}
                                                                                                title={deviceReturned ? (deviceAvailable ? "Not associated with any organization" :"Instructions was sent to admin")  : ""}
                                                                                                onClick={ deviceReturned ? () => {setCurrentPopover(null);} : onUnassignDevice}
                                                                                            >
                                                                                                Unassign device
                                                                                            </S.StyledPopoverMenuLink>
                                                                                        )}
                                                                                        {isSuperAdmin(user) && (
                                                                                            <S.StyledPopoverMenuLink
                                                                                                onClick={onDeleteDevice}
                                                                                            >
                                                                                                Delete device
                                                                                            </S.StyledPopoverMenuLink>
                                                                                        )}
                                                                                    </div>
                                                                                </S.StyledPopoverMenuOrgs>
                                                                            }
                                                            >
                                                                <S.StyledTableActionElement>
                                                                    <div>
                                                                        <S.StyledIcon id={device.id} onClick={() => {
                                                                        }} className="fa-solid fa-ellipsis-vertical"/>
                                                                    </div>
                                                                </S.StyledTableActionElement>
                                                            </OverlayTrigger>
                                                        </S.StyledUsersActionTD>
                                                    )}

                                                </S.StyledDevicesTR>
                                            )
                                        })))}
                                </tbody>
                            </Table>
                        </S.StyledTableWrapper>
                        <PaginationFooter setPageNumber={setPageNumber}
                                          pageNumber={pageNumber}
                                          lastPage={lastPage}
                                          offset={offset}
                                          endRange={endRange}
                                          quantity={devicesCount}
                        />
                    </S.StyledCenterContainer>
                </S.StyledMainContainer>
            )}

        </S.StyledDashboardContainer>
    );
}

export default DevicesList;
