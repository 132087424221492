import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../../app/store";
import {Device, Organization, User} from "../../types";
import {
    createOrganization,
    getUserOrganization,
    getOrganizationUsers,
    getAllUsers,
    updateOrganization,
    getAllOrganizations,
    getOrganizationLogs,
    deleteOrganization,
    createOrganizationAndAdministrator,
    restoreOrganization
} from "./organizationThunks"
import {get} from "lodash";
import {EventLog} from "../../types/event";

export interface OrganizationState {
    organization: Organization | null,
    users: User[],
    organizationsList: Organization[],
    events: EventLog[],
    usersQuantity: number,
    eventsQuantity: number,
    organizationsQuantity: number,
    organizationCreate: boolean,
    organizationsLoaded: boolean,
    customerPortalURL: string,
    status: 'idle' | 'loading' | 'failed' | 'loaded' | "deleted" | "restored" | "organizationsLoaded",
}

const initialState: OrganizationState = {
    organization: null,
    users: [],
    organizationsList: [],
    events: [],
    usersQuantity: 0,
    eventsQuantity: 0,
    organizationsQuantity: 0,
    organizationCreate: false,
    organizationsLoaded: false,
    customerPortalURL: "",
    status: "idle"
}

export const organizationSlice = createSlice({
    name: "organization",
    initialState,
    reducers: {
        setOrganizationCreated: (state, action: PayloadAction<boolean>) => {
            state.organizationCreate = action.payload;
        },
        setOrganizationStatus: (state, action: PayloadAction<'idle' | 'loading' | 'failed'>) => {
            state.status = action.payload;
        },
        setOrganization: (state, action: PayloadAction<Organization | null>) => {
            state.organization = action.payload;
        },
        clearOrganizationData: (state) => {
            state.organization = null;
            state.users = [];
            state.usersQuantity = 0;
            state.organizationCreate = false;
            state.organizationsLoaded = false;
            state.status = "idle";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createOrganization.pending, (state) => {
                state.status = "loading";
            })
            .addCase(createOrganization.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) {
                    state.organizationCreate = true;
                }
            })
            .addCase(createOrganization.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(getUserOrganization.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getUserOrganization.fulfilled, (state, action) => {
                if (action.payload) {
                    state.organization = get(action, "payload.organization", null);
                    state.customerPortalURL = get(action, "payload.portalURL.url", "");
                    state.status = "loaded";
                }
            })
            .addCase(getUserOrganization.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(getOrganizationUsers.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getOrganizationUsers.fulfilled, (state, action) => {
                state.status = "loaded";
                if (action.payload) {
                    state.users = get(action.payload, "users", []);
                    state.usersQuantity = get(action.payload, "quantity", 0);
                }
            })
            .addCase(getOrganizationUsers.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(getAllUsers.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getAllUsers.fulfilled, (state, action) => {
                state.status = "loaded";
                if (action.payload) {
                    state.users = get(action.payload, "users", []);
                    state.usersQuantity = get(action.payload, "quantity", 0);
                }
            })
            .addCase(getAllUsers.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(updateOrganization.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateOrganization.fulfilled, (state, action) => {
                state.status = "loaded";
                if (action.payload) {
                    state.organization = action.payload;
                }
            })
            .addCase(updateOrganization.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(getAllOrganizations.pending, (state) => {
                state.status = "loading"
            })
            .addCase(getAllOrganizations.fulfilled, (state, action) => {

                if (action.payload) {
                    state.organizationsList = get(action.payload, "organizations", []);
                    state.organizationsQuantity = get(action.payload, "quantity", 0);
                    state.organizationsLoaded = true;
                    state.status = "organizationsLoaded";
                }
            })
            .addCase(getAllOrganizations.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(getOrganizationLogs.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(getOrganizationLogs.fulfilled, (state, action) => {
                state.status = "loaded";
                if (action.payload) {
                    state.events = get(action.payload, "events", []);
                    state.eventsQuantity = get(action.payload, "quantity", 0);
                    state.organizationsLoaded = true;
                }
            })
            .addCase(getOrganizationLogs.pending, (state) => {
                state.status = "loading"
            })
            .addCase(deleteOrganization.pending, (state) => {
                state.status = "idle";
            })
            .addCase(deleteOrganization.fulfilled, (state) => {
                state.status = "deleted";
            })
            .addCase(deleteOrganization.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(restoreOrganization.pending, (state) => {
                state.status = "idle";
            })
            .addCase(restoreOrganization.fulfilled, (state, action) => {
                if (action.payload) {
                    state.status = "restored"
                }
            })
            .addCase(restoreOrganization.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(createOrganizationAndAdministrator.pending, (state) => {
                state.status = "idle";
            })
            .addCase(createOrganizationAndAdministrator.fulfilled, (state, action) => {
                if (action.payload) {
                    state.status = "loaded";
                }
            })
            .addCase(createOrganizationAndAdministrator.rejected, (state) => {
                state.status = "failed";
            })
    }
})

export const {setOrganizationCreated, setOrganizationStatus, setOrganization, clearOrganizationData} = organizationSlice.actions;

export const selectOrganizationStatus = (state: RootState) => state.organization.status;
export const selectOrganization = (state: RootState) => state.organization.organization;
export const selectOrganizationCreated = (state: RootState) => state.organization.organizationCreate;
export const selectOrganizationUsers = (state: RootState) => state.organization.users;
export const selectOrganizationUsersQuantity = (state: RootState) => state.organization.usersQuantity;
export const selectListOfOrganizations = (state: RootState) => state.organization.organizationsList;
export const selectOrganizationsQuantity = (state: RootState) => state.organization.organizationsQuantity;
export const selectOrganizationEvents = (state: RootState) => state.organization.events;
export const selectOrganizationEventsQuantity = (state: RootState) => state.organization.eventsQuantity;
export const selectOrganizationPortalURL = (state: RootState) => state.organization.customerPortalURL;
export const selectOrganizationsLoaded = (state: RootState) => state.organization.organizationsLoaded;

export default organizationSlice.reducer;
