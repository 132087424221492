import axios, {isAxiosError} from "axios"
import {isEqual, get} from "lodash";
import {User, CreateUserRequest, IResponse, LogInInformation, IPaginationParams, SendUserInvite} from "../types";
import config from "../config/api"
import validator from "validator";
import isEmpty = validator.isEmpty;

const {apiURL} = config.apiConfig;

interface ICreateUserResponse extends IResponse {
    data?: User;
}

export const createUserAPI = async (user: CreateUserRequest): Promise<ICreateUserResponse> => {
    try {
        const URL = `${apiURL}/api/users`;
        const {data, status, statusText} = await axios.post(URL, user);

        if (isEqual(status, 201)) {
            return {
                success: true,
                data: data
            }
        } else {
            return {
                success: false,
                errors: [statusText]
            }
        }
    } catch (error) {
        console.error("An error occurred during user creating(api)", error);
        let errorResponse: ICreateUserResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during user creating"]
        };
    }
}

export const getUserByTokenAPI = async (): Promise<ICreateUserResponse> => {
    try {
        const URL = `${apiURL}/api/users/user-by-token`;
        const {data, status, statusText} = await axios.get(URL);

        if (isEqual(status, 200)) {
            return {
                success: true,
                data: data
            }
        } else {
            return {
                success: false,
                errors: [statusText]
            }
        }
    } catch (error) {
        console.error("An error occurred during user info fetching(api)", error);
        let errorResponse: ICreateUserResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during user info fetching"]
        };
    }
}

interface IRegisterUserResponse extends IResponse {
    data?: {
        user: User,
        accessToken: string
    };
}

export const registerUserAPI = async (user: CreateUserRequest): Promise<IRegisterUserResponse> => {
    try {
        const URL = `${apiURL}/api/auth/registration`;
        const axiosResponse = await axios.post(URL, user);

        if (isEqual(axiosResponse.status, 201)) {
            return {
                success: true,
                data: axiosResponse.data
            }
        } else {
            return {
                success: false,
                errors: [axiosResponse.statusText]
            }
        }
    } catch (error) {
        console.error("An error occurred during registration(api)", error);
        let errorResponse: IRegisterUserResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during registration"]
        };
    }
}

interface IAuthorizeUserResponse extends IResponse {
    data?: {
        user: User,
        accessToken: string
    };
}

export const authorizeUserAPI = async (payload: LogInInformation): Promise<IAuthorizeUserResponse> => {
    try {
        const URL = `${apiURL}/api/auth/login`;
        const axiosResponse = await axios.post(URL, payload);

        if (isEqual(axiosResponse.status, 201)) {
            return {
                success: true,
                data: axiosResponse.data
            }
        } else {
            return {
                success: false,
                errors: [axiosResponse.statusText]
            }
        }
    } catch (error) {
        console.error("An error occurred during authorization(api)", error);
        let errorResponse: IAuthorizeUserResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during authorization"]
        };
    }
}

interface IRestorePasswordResponse extends IResponse {
}

export const restorePasswordRequestAPI = async (email: string): Promise<IRestorePasswordResponse> => {
    try {
        const URL = `${apiURL}/api/auth/forgot-password`;
        const payload = {email};
        const axiosResponse = await axios.post(URL, payload);

        if (isEqual(axiosResponse.status, 201)) {
            return {
                success: true
            }
        } else {
            return {
                success: false,
                errors: [axiosResponse.statusText]
            }
        }

    } catch(error) {
        console.error("An error occurred during password restoring(api)", error);

        let errorResponse: IRestorePasswordResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during password restoring"]
        };
    }
}

interface ICompletePasswordRestoreResponse extends IResponse {
}

export const completeRestorePasswordRequestAPI = async (email: string, token: string): Promise<ICompletePasswordRestoreResponse> => {
    try {
        const URL = `${apiURL}/api/auth/confirm-forgot-password`;
        const payload = {email, token};
        const axiosResponse = await axios.post(URL, payload);

        if (isEqual(axiosResponse.status, 201)) {
            return {
                success: true,
                errors: [axiosResponse.statusText]
            }
        } else {
            return {
                success: false
            }
        }

    } catch(error) {
        console.error("An error occurred during password restoring(api)", error);

        let errorResponse: IRestorePasswordResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during password restoring"]
        };
    }
}

interface IUpdateProfileResponse extends IResponse {
    data?: User;
}

export const updateProfileRequestAPI = async (payload: User): Promise<IUpdateProfileResponse> => {
    try {
        const URL = `${apiURL}/api/users/${payload.id}`;
        const axiosResponse = await axios.patch(URL, payload);

        if (isEqual(axiosResponse.status, 200)) {
            return {
                success: true,
                data: axiosResponse.data
            }
        } else {
            return {
                success: false
            }
        }

    } catch(error) {
        console.error("An error occurred during profile updating(api)", error);

        let errorResponse: IUpdateProfileResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during profile updating"]
        };
    }
}

interface IGetOrganizationUsersResponse extends IResponse {
    data?: User[]
    quantity?: number;
}

export const getOrganizationUsersAPI = async (pagination: IPaginationParams): Promise<IGetOrganizationUsersResponse> => {
    try {
        let URL = `${apiURL}/api/users/org-related?limit=${pagination.limit}&offset=${pagination.offset}`;

        if (!!pagination.order && !!pagination.orderBy) {
            URL = URL.concat(`&orderBy=${pagination.orderBy}&order=${pagination.order}`);
        }

        const axiosResponse = await axios.get(URL);

        if (isEqual(axiosResponse.status, 200)) {
            return {
                success: true,
                data: get(axiosResponse.data, "data", []),
                quantity: get(axiosResponse.data, "count", 0)
            }
        } else {
            return {
                success: false
            }
        }
    } catch(error) {
        console.error("An error occurred during fetching list of users(api)", error);

        let errorResponse: IGetOrganizationUsersResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during fetching list of users"]
        };
    }
}

interface IInviteUserResponse extends IResponse {
    data?: User
}

export const sendUserInviteAPI = async (payload: SendUserInvite): Promise<IInviteUserResponse> => {
    try {
        const URL = `${apiURL}/api/users/invite-user`;
        const axiosResponse = await axios.post(URL, payload);

        if (isEqual(axiosResponse.status, 201)) {
            return {
                success: true,
                data: axiosResponse.data
            }
        } else {
            return {
                success: false
            }
        }
    } catch(error) {
        console.error("An error occurred during sending invite to the user(api)", error);

        let errorResponse: IInviteUserResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during sending invite to the user"]
        };
    }
}

interface IGetAllUsersResponse extends IResponse{
    data?: User[];
    quantity?: number
}

export const getAllUsersAPI = async (pagination: IPaginationParams): Promise<IGetAllUsersResponse> => {
    try {
        let URL = `${apiURL}/api/users/with-org?limit=${pagination.limit}&offset=${pagination.offset}`;

        if (!!pagination.order && !!pagination.orderBy) {
            URL = URL.concat(`&order=${pagination.order}&orderBy=${pagination.orderBy}`);
        }

        const axiosResponse = await axios.get(URL);

        if (isEqual(axiosResponse.status, 200)) {
            return {
                success: true,
                data: get(axiosResponse.data, "data", []),
                quantity: get(axiosResponse.data, "count", 0)
            }
        } else {
            return {
                success: false
            }
        }
    } catch(error) {
        console.error("An error occurred during fetching all users(api)", error);

        let errorResponse: IGetAllUsersResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during fetching all users"]
        };

    }
}

interface IDeleteUserResponse extends IResponse {
}

export const deleteUserAPI = async (userId: string): Promise<IDeleteUserResponse> => {
    try {
        const URL = `${apiURL}/api/users/${userId}`;

        const axiosResponse = await axios.delete(URL);

        if (isEqual(axiosResponse.status, 200)) {
            return  {
                success: true
            }
        } else {
            return {
                success: false,
                errors: [axiosResponse.statusText]
            }
        }
    } catch(error) {
        console.error("An error occurred during user deleting(api)", error);
        let errorResponse: IDeleteUserResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during user deleting"]
        };
    }
}
