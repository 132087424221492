import React, {useEffect, useState} from "react"
import * as S from "./styled-components"
import SideBar from "./sideBar";
import {ORG_MEMBER, SUPERUSER, usersBoard} from "../../constants/dashboard";
import {Col, Row, Table, OverlayTrigger, Modal, Button, Spinner} from "react-bootstrap"
import {getId, getUserRoleName, isOrgAdmin, isSuperAdmin} from "../../utils";
import Pagination from 'react-bootstrap/Pagination';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    selectOrganization,
    selectOrganizationUsers,
    selectOrganizationUsersQuantity
} from "../../features/organization/organizationSlice";
import {getAllOrganizations, getAllUsers, getOrganizationUsers} from "../../features/organization/organizationThunks";
import {selectLoading} from "../../features/global/globalSlice";
import {get, isEmpty, isEqual} from "lodash";
import isUUID from "validator/lib/isUUID";
import EditProfileModal from "./EditProfileModal";
import {User} from "../../types";
import {deleteUser, updateProfile} from "../../features/user/userThunks";
import {selectAuthorizedUser, selectUserOperationStatus, setStatus} from "../../features/user/userSlice";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import PaginationFooter from "../paginationFooter";
import * as SUser from "../user/styled-components";
import {SortHeader} from "../sort-header";
import {QueryOrderByUserParam, SqlOrder} from "../../constants";

function Users() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const limit = 8;
    const [pageNumber, setPageNumber] = useState(1);
    const [showEditBox, setShowEditBox] = useState(false);
    const [pickedUser, setPickedUser] = useState<User | null>(null);
    const [role, setRole] = useState(ORG_MEMBER);
    const [currentPopover, setCurrentPopover] = useState<number | null>(null);
    const [showDeleteConfirm, setDeleteConfirm] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const [order, setOrder] = useState("");
    const [orderBy, setOrderBy] = useState("");

    const users = useAppSelector(selectOrganizationUsers);
    const user = useAppSelector(selectAuthorizedUser);
    const usersQuantity = useAppSelector(selectOrganizationUsersQuantity);
    const organization = useAppSelector(selectOrganization);
    const isLoading = useAppSelector(selectLoading);
    const status = useAppSelector(selectUserOperationStatus);

    const lastPage = Math.ceil(usersQuantity / limit);
    const offset = (pageNumber - 1) * limit; //+ 1;
    let endRange = (offset + limit - 1) > usersQuantity ? usersQuantity - 1 : offset + limit - 1;

    useEffect(() => {
        if (!dataLoaded) {
            getListOfUsers();

            if (isSuperAdmin(user)) {
                fetchOrganizations();
            }
            setDataLoaded(true);
        }
    }, []);

    useEffect(() => {
        getListOfUsers();
    }, [pageNumber, order, orderBy]);

    useEffect(() => {
        if (isEqual(status, "deleted")) {
            setDeleteConfirm(false);
            setPickedUser(null);
            getListOfUsers();
        }
    }, [status]);

    const onShowEditBox = (event: React.MouseEvent<HTMLDivElement>) => {
        dispatch(setStatus("idle"));
        const id = getId(event);
        if (isUUID(id)) {
            const user = users.filter(usr => isEqual(usr.id, id));
            if (user.length > 0) {
                setPickedUser(user[0]);
                setRole(get(user[0], "role", ORG_MEMBER));

                setShowEditBox(true);
                setCurrentPopover(null);
            }
        }
    }

    const onDeleteUser = (event: React.MouseEvent<HTMLDivElement>) => {
        const id = getId(event);

        if (isUUID(id)) {
            const user = users.filter(usr => isEqual(usr.id, id));
            if (user.length > 0) {
                setPickedUser(user[0]);
                setDeleteConfirm(true)
                setCurrentPopover(null);
            }
        }
    }

    const onHideEditBox = async (withUpdate: boolean) => {
        setPickedUser(null);
        setShowEditBox(false);
        setRole(ORG_MEMBER);
        dispatch(setStatus("idle"));

        if (withUpdate) {
            await getListOfUsers();
        }
    };

    const onChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {target} = event;
        if (target.value && pickedUser) {
            setPickedUser({...pickedUser, role: target.value});
        }
    }

    const onSaveChanges = async () => {
        if (pickedUser && !isEqual(pickedUser.role, role)) {
            await dispatch(updateProfile({user: pickedUser, updateState: false}));
        }
    }

    const getListOfUsers = async () => {
        if (isSuperAdmin(user)) {
            await dispatch(getAllUsers({limit, offset, order, orderBy}));
        } else {
            await dispatch(getOrganizationUsers({limit, offset, order, orderBy}));
        }

    }

    const fetchOrganizations = async () => {
        await dispatch(getAllOrganizations(null));
    }

    const getOrganizationName = (): string | null => {
        return (user && pickedUser && isEqual(user.role, SUPERUSER)) ? get(pickedUser, "organization.name", "") : null;
    }

    const onCloseConfirmation = () => {
        setDeleteConfirm(false);
        setPickedUser(null);
    }

    const onDeleteConfirm = async () => {
        if (pickedUser && pickedUser.id) {
            await dispatch(deleteUser(pickedUser.id));
        }
    }

    const onChangeOrder = (orderFiled: string, orderByField: string) => {
        let newOrder = "";
        if (isEqual(orderFiled, orderBy)) {
            switch (orderByField) {
                case SqlOrder.ASС:
                    newOrder = SqlOrder.DESC;
                    break;
                case SqlOrder.DESC:
                    newOrder = "";
                    break;
                case "":
                    newOrder = SqlOrder.ASС
                    break;
            }
        } else {
            newOrder = SqlOrder.ASС;
        }

        if (isEmpty(newOrder)) {
            setOrder("");
            setOrderBy("");
        } else {
            setOrder(newOrder);
            setOrderBy(orderFiled);
        }
    }

    const preLoaderWidth = (): number => isSuperAdmin(user) ? 7 : 6;

    return (
        <S.StyledDashboardContainer>
            <SideBar currentComponent={usersBoard}/>

            {showEditBox && (<EditProfileModal show={true}
                                               user={pickedUser}
                                               organizationName={getOrganizationName()}
                                               onClose={onHideEditBox}
                                               onSave={onSaveChanges}
                                               onChangeRole={onChangeRole}
            />)}
            <S.StyledMainContainer>
                <S.StyledEditRoleModal show={showDeleteConfirm} onHide={onCloseConfirmation}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure to delete <strong>{get(pickedUser, "name", "")}</strong> from your organization ?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onCloseConfirmation}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={onDeleteConfirm}>
                            Delete
                            {isLoading && (<SUser.StyledSingInSpinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />)}
                        </Button>
                    </Modal.Footer>
                </S.StyledEditRoleModal>
                <S.StyledTopBar>
                    <S.StyledMainHeader>
                        <Col md={1} style={{width: 'auto'}}>
                            <S.StyledUsersHeaderCaption>Users</S.StyledUsersHeaderCaption>
                        </Col>

                        {(isOrgAdmin(user) || isSuperAdmin(user)) && (
                            <Col md={1} style={{width: 'fit-content'}}>
                                <S.StyledInviteUserContainer>
                                    <S.StyledInviteUserButton onClick={() => navigate("/invite")}>
                                        <S.StyledIcon className="fa-solid fa-user-plus" style={{color: "#ffffff"}}/>
                                        Invite user
                                    </S.StyledInviteUserButton>
                                </S.StyledInviteUserContainer>
                            </Col>)
                        }
                    </S.StyledMainHeader>
                </S.StyledTopBar>
                <S.StyledCenterContainer>
                    <S.StyledProfileHeader>
                        <S.StyledHeaderCaption>Organization users</S.StyledHeaderCaption>
                    </S.StyledProfileHeader>
                    <S.StyledTableWrapper>
                        <Table style={{tableLayout: "fixed"}}>
                            <thead>
                            <S.StyledUsersTR>
                                <S.StyledUsersTH style={{
                                    padding: "16px 8px 16px 22px",
                                    gap: "4px",
                                    alignItems: "center"
                                }}>
                                    <SortHeader caption={"Name"}
                                                orderBy={orderBy}
                                                order={order}
                                                orderField={QueryOrderByUserParam.NAME}
                                                onChangeOrder={onChangeOrder}
                                    />
                                </S.StyledUsersTH>
                                <S.StyledUsersTH style={{padding: "16px 8px 16px 22px", gap: "4px"}}>
                                    <SortHeader caption={"Email"}
                                                orderBy={orderBy}
                                                order={order}
                                                orderField={QueryOrderByUserParam.EMAIL}
                                                onChangeOrder={onChangeOrder}
                                    />
                                </S.StyledUsersTH>
                                <S.StyledUsersTH style={{padding: "16px 8px 16px 22px", gap: "4px"}}>
                                    <SortHeader caption={"Phone"}
                                                orderBy={orderBy}
                                                order={order}
                                                orderField={QueryOrderByUserParam.PHONE_NUMBER}
                                                onChangeOrder={onChangeOrder}
                                    />
                                </S.StyledUsersTH>
                                <S.StyledUsersTH style={{padding: "16px 8px 16px 22px", gap: "4px"}}>
                                    <SortHeader caption={"Role"}
                                                orderBy={orderBy}
                                                order={order}
                                                orderField={QueryOrderByUserParam.ROLE}
                                                onChangeOrder={onChangeOrder}
                                    />
                                </S.StyledUsersTH>
                                <S.StyledUsersTH style={{padding: "16px 8px 16px 22px", gap: "4px"}}>
                                    <SortHeader caption={"Date of registration"}
                                                orderBy={orderBy}
                                                order={order}
                                                style={{minWidth: "120px"}}
                                                orderField={QueryOrderByUserParam.CREATED_AT}
                                                onChangeOrder={onChangeOrder}
                                    />
                                </S.StyledUsersTH>
                                {isSuperAdmin(user) && (
                                    <S.StyledUsersTH style={{padding: "16px 8px 16px 22px", gap: "4px"}}>
                                        <SortHeader caption={"Organization"}
                                                    orderBy={orderBy}
                                                    order={order}
                                                    style={{minWidth: "100px"}}
                                                    orderField={QueryOrderByUserParam.ORG_NAME}
                                                    onChangeOrder={onChangeOrder}
                                        />
                                    </S.StyledUsersTH>
                                )}
                                {(isOrgAdmin(user) || isSuperAdmin(user)) && (
                                    <S.StyledUsersActionTH style={{padding: "16px 8px 16px 8px", gap: "4px"}}/>)}
                            </S.StyledUsersTR>
                            </thead>
                            <tbody>
                            {isLoading ? (
                                    <tr>
                                        <td rowSpan={preLoaderWidth()} colSpan={preLoaderWidth()}>
                                            <div className="text-center py-5">
                                                <Spinner animation="border"/>
                                            </div>
                                        </td>
                                    </tr>
                                ) :
                                (
                                    users.map((usr, index) => {
                                        return (
                                            <S.StyledUsersTR key={"usr " + usr.id}>
                                                <S.StyledUsersTD
                                                    style={{
                                                        padding: "22px 8px 22px 22px",
                                                        gap: "4px"
                                                    }}>{usr.name}</S.StyledUsersTD>
                                                <S.StyledUsersTD
                                                    style={{
                                                        padding: "22px 8px 22px 22px",
                                                        gap: "4px"
                                                    }}>{usr.email}</S.StyledUsersTD>
                                                <S.StyledUsersTD
                                                    style={{
                                                        padding: "22px 8px 22px 22px",
                                                        gap: "4px"
                                                    }}>{usr.mobileNumber}</S.StyledUsersTD>
                                                <S.StyledUsersTD style={{padding: "22px 8px 22px 22px", gap: "4px"}}>
                                                    {getUserRoleName(usr.role)}
                                                </S.StyledUsersTD>
                                                <S.StyledUsersTD style={{padding: "22px 8px 22px 22px", gap: "4px"}}>
                                                    {moment(usr.createdAt).format("DD-MM-YYYY")}
                                                </S.StyledUsersTD>
                                                {isSuperAdmin(user) && (
                                                    <S.StyledUsersTD
                                                        style={{padding: "22px 8px 22px 22px", gap: "4px"}}>
                                                        {usr.organization && usr.organization.name}
                                                    </S.StyledUsersTD>
                                                )}
                                                <S.StyledUsersActionTD style={{padding: "22px 8px", gap: "4px"}}>
                                                    {((isOrgAdmin(user) || isSuperAdmin(user)) && !isEqual(user && user.id, usr.id)) ? (
                                                        <OverlayTrigger trigger="click"
                                                                        placement="left"
                                                                        rootClose={true}
                                                                        rootCloseEvent="mousedown"
                                                                        key={index}
                                                                        show={isEqual(currentPopover, index)}
                                                                        onToggle={() => {
                                                                            if (isEqual(currentPopover, index)) {
                                                                                setCurrentPopover(null);
                                                                            } else {
                                                                                setCurrentPopover(index);
                                                                            }
                                                                        }}
                                                                        overlay={
                                                                            <S.StyledPopoverMenuOrgs id={index}>
                                                                                <div style={{right: "0px"}} id={usr.id}>
                                                                                    <S.StyledPopoverMenuLink
                                                                                        onClick={onShowEditBox}
                                                                                    >
                                                                                        Edit role
                                                                                    </S.StyledPopoverMenuLink>
                                                                                    <S.StyledPopoverMenuLink
                                                                                        onClick={onDeleteUser}
                                                                                    >
                                                                                        Delete user
                                                                                    </S.StyledPopoverMenuLink>
                                                                                </div>
                                                                            </S.StyledPopoverMenuOrgs>
                                                                        }
                                                        >
                                                            <S.StyledTableActionElement>
                                                                <div>
                                                                    <S.StyledIcon
                                                                        className="fa-solid fa-ellipsis-vertical"/>
                                                                </div>
                                                            </S.StyledTableActionElement>
                                                        </OverlayTrigger>
                                                    ) : (<S.StyledTableActionElement/>)}
                                                </S.StyledUsersActionTD>

                                            </S.StyledUsersTR>
                                        )
                                    })
                                )
                            }
                            </tbody>
                        </Table>
                    </S.StyledTableWrapper>
                    <PaginationFooter setPageNumber={setPageNumber}
                                      pageNumber={pageNumber}
                                      lastPage={lastPage}
                                      offset={offset}
                                      endRange={endRange}
                                      quantity={usersQuantity}
                    />
                </S.StyledCenterContainer>
            </S.StyledMainContainer>
        </S.StyledDashboardContainer>
    );
}

export default Users;
