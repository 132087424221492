import {createAsyncThunk} from '@reduxjs/toolkit';
import {
    createOrganizationAPI,
    getUserOrganizationAPI,
    getOrganizationUsersAPI,
    getAllUsersAPI,
    getAllOrganizationsAPI,
    updateOrganizationAPI,
    deleteOrganizationAPI, createOrganizationWithAdminAPI, getCustomerPortalURLAPI, restoreOrganizationAPI
} from "../../api"
import {
    ICreateOrganizationWithAdmin,
    IDeleteOrganizationArgs,
    IPaginationParams,
    IPaginationParamsWithIds,
    Organization,
    OrganizationForCreation,
    UpdateOrganizationData
} from "../../types";
import {setLoading, setMessage} from "../global/globalSlice";
import {get, head, isArray, isEmpty} from "lodash";
import {PaginationProps} from "react-bootstrap";
import {getOrganizationLogsAPI} from "../../api/logdAPI";

export const createOrganization = createAsyncThunk("organization/create", async (arg: OrganizationForCreation, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await createOrganizationAPI(arg);
        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return response.data;
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during organization creation(thunk)" : "An error occurred during organization creation(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
});

export const getUserOrganization = createAsyncThunk("organization/getMy", async (arg: any, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await getUserOrganizationAPI();


        if (response.success) {
            let customerPortalResponse, customerPortalURL;
            try {
                const orgId = get(response, "data.id", "");
                if (!isEmpty(orgId)) {
                    customerPortalResponse = await getCustomerPortalURLAPI(orgId);
                    if (customerPortalResponse.success) {
                        customerPortalURL = customerPortalResponse.data;
                    }
                }
            } catch (error) {

            }
            thunkAPI.dispatch(setLoading(false));
            return {
                organization: response.data,
                portalURL: customerPortalURL
            };
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during organization fetching(thunk)" : "An error occurred during organization fetching(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
});

export const getOrganizationUsers = createAsyncThunk("organization/getUsers", async (arg: IPaginationParams, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await getOrganizationUsersAPI(arg);
        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return {users: response.data, quantity: response.quantity};
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during organization users fetching(thunk)" : "An error occurred during organization users fetching(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
});

export const getAllUsers = createAsyncThunk("organization/getAllUsers", async (arg: IPaginationParams, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await getAllUsersAPI(arg)
        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return {users: response.data, quantity: response.quantity};
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during organization users fetching(thunk)" : "An error occurred during organization users fetching(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
})

export const updateOrganization = createAsyncThunk("organization/update", async (arg: UpdateOrganizationData, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await updateOrganizationAPI(arg.organization, arg.id);
        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return response.data;
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during organization updating(thunk)" : "An error occurred during organization updating(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
})

export const getAllOrganizations = createAsyncThunk("organization/getAllOrganizations", async (arg: IPaginationParamsWithIds | null, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await getAllOrganizationsAPI(arg)
        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return {organizations: response.data, quantity: response.quantity};
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during organizations fetching(thunk)" : "An error occurred during organizations fetching(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
})

export const getOrganizationLogs = createAsyncThunk("organization/getOrganizationLogs", async (arg: IPaginationParamsWithIds, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await getOrganizationLogsAPI(arg);
        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return {events: response.data, quantity: response.quantity};
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during organization users fetching(thunk)" : "An error occurred during organization users fetching(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
})

export const deleteOrganization = createAsyncThunk("organization/delete", async (arg: IDeleteOrganizationArgs, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await deleteOrganizationAPI(arg);
        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return true
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during organization users fetching(thunk)" : "An error occurred during organization users fetching(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
})

export const restoreOrganization = createAsyncThunk("organization/restore", async (arg: string, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await restoreOrganizationAPI(arg);
        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return true
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during organization users fetching(thunk)" : "An error occurred during organization users fetching(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
})

export const createOrganizationAndAdministrator = createAsyncThunk("organization/orgAndAdmin", async (arg: ICreateOrganizationWithAdmin, thunkAPI) => {
    try {
        thunkAPI.dispatch(setMessage(null));
        thunkAPI.dispatch(setLoading(true));
        const response = await createOrganizationWithAdminAPI(arg);
        thunkAPI.dispatch(setLoading(false));

        if (response.success) {
            return true
        } else {
            const errorMessage: string = isArray(response.errors) && response.errors.length > 0 ? head(response.errors) || "An error occurred during organization users fetching(thunk)" : "An error occurred during organization users fetching(thunk)";
            thunkAPI.dispatch(setMessage(errorMessage));
            return false;
        }
    } catch (error) {
        thunkAPI.dispatch(setLoading(false));
    }
})
