import React from 'react';
import ReactDOM from 'react-dom';

interface LoadingPanelProps {
    selector?: string;
    style?: React.CSSProperties
}

export class LoadingPanel extends React.Component<LoadingPanelProps, any> {
    public render() {
        let selector = '.k-grid-content';
        if (this.props.selector) {
            selector = this.props.selector;
        }

        const loadingPanel = (
            <div
                style={this.props.style}
                className="k-loading-mask"
            >
                <span className="k-loading-text">Loading</span>
                <div className="k-loading-image" />
                <div className="k-loading-color" />
            </div>
        );

        const content = document && document.querySelector(selector);

        if (this.props.selector && content) {
            ReactDOM.createPortal(loadingPanel, content);
        }

        return loadingPanel;
    }
}
