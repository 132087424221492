import React, {useEffect, useState} from "react";
import * as S from "./styled-components"
import {Form, Modal, Tab, Tabs, Col, Row} from 'react-bootstrap';
import {Organization, RegistrationErrors} from "../../types";
import {
    CountryCodes, formBasicAddress, formBasicCity,
    formBasicCountry,
    formBasicEmail,
    formBasicFullName,
    formBasicOrganizationCreatorEmail,
    formBasicOrganizationCreatorName, formBasicPhoneNumber, formBasicZip
} from "../../constants";
import * as SU from "../user/styled-components";
import {get, isEmpty} from "lodash";
import * as SUser from "../user/styled-components";
import {validateEmail, validateMobileNumber} from "../../utils";

interface IEditOrganizationProps {
    show: boolean;
    isLoading: boolean;
    organization: Organization | null;
    onClose: (withUpdate: boolean) => void;
    onUpdate: (org: Organization) => void;
}

type CountryKey = keyof typeof CountryCodes;

function EditOrganizationModal(props: IEditOrganizationProps) {
    const {organization} = props;

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [country, setCountry] = useState("NO");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");

    const [key, setKey] = useState("commonSettings");
    const [addressTouch, setAddressTouch] = useState(false);
    const [commonTouch, setCommonTouch] = useState(false);
    const [errors, setErrors] = useState<RegistrationErrors>({});
    const [notValid, setNotValid] = useState(false);


    useEffect(() => {
        if (props.organization) {
            setName(props.organization.name);
            setEmail(props.organization.email);
            setCountry(props.organization.country || "NO");
            setPhoneNumber(props.organization.phoneNumber || "");
            if (props.organization.shippingAddress) {
                setStreet(props.organization.shippingAddress.street);
                setCity(props.organization.shippingAddress.city);
                setPostalCode(props.organization.shippingAddress.postalCode);
            } else {
                setStreet("");
                setCity("");
                setPostalCode("");
            }
            setCommonTouch(false);
            setAddressTouch(false);
        }
    }, [organization]);

    const validateData = (): boolean => {
        let tmpErrors = {};

        if (commonTouch) {
            if (isEmpty(name)) {
                tmpErrors = {...tmpErrors, organizationName: "Organization name cannot be empty"}
            } else if (name.length > 100) {
                tmpErrors = {...tmpErrors, organizationName: "Organization name cannot be longer 100 characters"}
            }
            if (isEmpty(email)) {
                tmpErrors = {...tmpErrors, email: "Email cannot be empty"}
            } else if (email.length > 100) {
                tmpErrors = {...tmpErrors, email: "Email cannot be longer 100 characters"}
            } else if (!validateEmail(email)) {
                tmpErrors = {...tmpErrors, email: 'Email is not valid'}
            }
            if (!isEmpty(phoneNumber)) {
                if (phoneNumber.length > 13) {
                    tmpErrors = {...tmpErrors, mobileNumber: "Phone should contains no more 13 symbols"}
                } else if (!validateMobileNumber(phoneNumber)) {
                    tmpErrors = {...tmpErrors, mobileNumber: "Phone number is not valid"}
                }
            }
        }

        if (addressTouch) {
            if (isEmpty(street)) {
                tmpErrors = {...tmpErrors, street: "Address cannot be empty"}
            } else if (street.length > 100) {
                tmpErrors = {...tmpErrors, street: "Address cannot be longer 100 characters"}
            }
            if (isEmpty(city)) {
                tmpErrors = {...tmpErrors, city: "City cannot be empty"}
            } else if (city.length > 75) {
                tmpErrors = {...tmpErrors, city: "City name cannot be longer 75 characters"}
            }
            if (isEmpty(postalCode)) {
                tmpErrors = {...tmpErrors, postalCode: "Zip/Post code cannot be empty"}
            } else if (postalCode.length > 10) {
                tmpErrors = {...tmpErrors, postalCode: "Zip/Post code cannot be longer 10 characters"}
            }
        }

        let notValid = Object.keys(tmpErrors).length > 0;
        if (notValid) {
            setErrors(tmpErrors);
        } else {
            setErrors({});
        }

        setNotValid(Object.keys(tmpErrors).length > 0);


        return notValid;
    }

    const onUpdateOrganization = async () => {
        if (!validateData() && props.organization) {
            const {id} = props.organization;
            await props.onUpdate({
                id, name, email, country, phoneNumber,
                shippingAddress: {
                    street, city, postalCode
                }
            });
        }
    }

    const getFormHeight = () => {
        return notValid ? "460px" : "400px";
    }

    return (
        <>
            <S.StyledEditRoleModal show={props.show}
                                   onHide={() => props.onClose(false)}
            >
                <Modal.Header closeButton onClick={() => {
                }}>
                    <Modal.Title>Edit organization</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs id="settings-tabs" activeKey={key} onSelect={(key) => key && setKey(key)}>
                        <Tab eventKey="commonSettings" title="General info" style={{height: getFormHeight()}}>
                            <Form>
                                <Form.Group controlId={formBasicFullName}>
                                    <SU.StyledRegistrationLabel>Organization name</SU.StyledRegistrationLabel>
                                    <SU.StyledRegistrationInput type="input"
                                                                value={name}
                                                                isInvalid={!!errors.organizationName}
                                                                disabled={props.isLoading}
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                    setName(event.target.value);
                                                                    setCommonTouch(true);
                                                                }}
                                    />
                                    <S.StyledFeedback type="invalid">{errors.organizationName}</S.StyledFeedback>
                                </Form.Group>
                                <Form.Group controlId={formBasicEmail}>
                                    <SU.StyledRegistrationLabel>Email</SU.StyledRegistrationLabel>
                                    <SU.StyledRegistrationInput type="input"
                                                                value={email}
                                                                isInvalid={!!errors.email}
                                                                disabled={props.isLoading}
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                    setEmail(event.target.value);
                                                                    setCommonTouch(true);
                                                                }}

                                    />
                                    <S.StyledFeedback type="invalid">{errors.email}</S.StyledFeedback>
                                </Form.Group>
                                <Form.Group controlId={formBasicPhoneNumber}>
                                    <SU.StyledRegistrationLabel>Phone</SU.StyledRegistrationLabel>
                                    <SU.StyledRegistrationInput type="input"
                                                                value={phoneNumber}
                                                                isInvalid={!!errors.mobileNumber}
                                                                disabled={props.isLoading}
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                    setPhoneNumber(event.target.value);
                                                                    setCommonTouch(true);
                                                                }}
                                    />
                                    <S.StyledFeedback type="invalid">{errors.mobileNumber}</S.StyledFeedback>
                                </Form.Group>

                                {organization && organization.contactUser && (
                                    <div>
                                        <Form.Group controlId={formBasicOrganizationCreatorEmail}>
                                            <SU.StyledRegistrationLabel>
                                                Organization creator email
                                            </SU.StyledRegistrationLabel>
                                            <SU.StyledRegistrationInput type="input"
                                                                        value={get(organization, "contactUser.user.email", "Not provided")}
                                                                        disabled={true}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId={formBasicOrganizationCreatorName}>
                                            <SU.StyledRegistrationLabel>
                                                Organization creator name
                                            </SU.StyledRegistrationLabel>
                                            <SU.StyledRegistrationInput type="input"
                                                                        value={get(organization, "contactUser.user.name", "Not provided")}
                                                                        disabled={true}
                                            />
                                        </Form.Group>
                                    </div>
                                )}
                            </Form>
                        </Tab>
                        <Tab eventKey="shippingAddress" title="Shipping address" style={{height: "400px"}}>
                            <Form>
                                <Form.Group controlId={formBasicCountry}>
                                    <SU.StyledRegistrationLabel>Country</SU.StyledRegistrationLabel>
                                    <S.StyledUserRoleSelect
                                        value={country}
                                        disable={props.isLoading}
                                        style={{overflowY: "scroll"}}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setCountry(event.target.value);
                                            setCommonTouch(true);
                                        }}
                                    >
                                        {
                                            //@ts-ignore
                                            Object.keys(CountryCodes).map((key: CountryKey) => {
                                                return (
                                                    <S.StyledUserRoleOption
                                                        value={CountryCodes[key]}>{String(key)}</S.StyledUserRoleOption>
                                                )
                                            })}
                                    </S.StyledUserRoleSelect>
                                    <S.StyledFeedback type="invalid">{errors.country}</S.StyledFeedback>
                                </Form.Group>
                                <Form.Group controlId={formBasicAddress}>
                                    <SU.StyledRegistrationLabel>Address</SU.StyledRegistrationLabel>
                                    <SU.StyledRegistrationInput type="input"
                                                                value={street}
                                                                isInvalid={!!errors.street}
                                                                disabled={props.isLoading}
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                    setStreet(event.target.value);
                                                                    setAddressTouch(true);
                                                                }}

                                    />
                                    <S.StyledFeedback type="invalid">{errors.street}</S.StyledFeedback>
                                </Form.Group>
                                <SU.StyledBottomOrganizationSection>
                                    <Form.Group controlId={formBasicCity}>
                                        <SU.StyledRegistrationLabelSmall>City</SU.StyledRegistrationLabelSmall>
                                        <SU.StyledRegistrationInputSmall type="input"
                                                                         value={city}
                                                                         isInvalid={!!errors.city}
                                                                         disabled={props.isLoading}
                                                                         onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                             setCity(event.target.value);
                                                                             setAddressTouch(true);
                                                                         }}
                                        />
                                        <S.StyledFeedback type="invalid">{errors.city}</S.StyledFeedback>
                                    </Form.Group>
                                    <Form.Group controlId={formBasicZip}>
                                        <SU.StyledRegistrationLabelSmall>Post code /
                                            Zip</SU.StyledRegistrationLabelSmall>
                                        <SU.StyledRegistrationInputSmall type="input"
                                                                         value={postalCode}
                                                                         isInvalid={!!errors.postalCode}
                                                                         disabled={props.isLoading}
                                                                         onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                             setPostalCode(event.target.value);
                                                                             setAddressTouch(true);
                                                                         }}
                                        />
                                        <S.StyledFeedback type="invalid">{errors.postalCode}</S.StyledFeedback>
                                    </Form.Group>
                                </SU.StyledBottomOrganizationSection>
                            </Form>
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer style={{justifyContent: "center"}}>
                    <S.StyledProfileResetPassword variant="secondary" disabled={props.isLoading}
                                                  onClick={() => props.onClose(false)}>
                        Close
                    </S.StyledProfileResetPassword>
                    <S.StyledProfileSaveButton style={{fontSize: "revert"}}
                                               onClick={onUpdateOrganization}
                                               disabled={props.isLoading || !(commonTouch || addressTouch)}
                    >
                        {props.isLoading && (<SUser.StyledSingInSpinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />)}
                        Save changes
                    </S.StyledProfileSaveButton>
                </Modal.Footer>
            </S.StyledEditRoleModal>
        </>
    )
}

export default EditOrganizationModal;
