export enum QueryOrderByDeviceParam {
    SERIAL_NUMBER = 'serialNumber',
    CREATED_AT = 'createdAt',
    CALIBRATION_EXPIRES = 'calibrationExpires',
    LOGISTIC_STATUS = 'logisticStatus',
    ORDER_ID = 'orderId',
    ACTIVE = 'active',
    ORG_NAME = 'orgName',
}

export enum QueryOrderByOrgParam {
    EMAIL = 'email',
    NAME = 'name',
    CREATED_AT = 'createdAt',
    DELETED_AT = 'deletedAt',
    COUNTRY = 'country',
    PHONE_NUMBER = 'phoneNumber',
    ADDRESS = 'address'
}
export enum QueryOrderByUserParam {
    EMAIL = 'email',
    NAME = 'name',
    CREATED_AT = 'createdAt',
    PHONE_NUMBER = 'mobileNumber',
    ROLE = 'role',
    ORG_NAME = 'orgName',
}

export enum SqlOrder {
    ASС = 'ASC',
    DESC = 'DESC',
}
