import styled from '@emotion/styled';
import company_name_with_logo from "../../images/company_name_and_logo.png"
import menu_icon from "../../images/ico_menu.jpg";
import Button from 'react-bootstrap/Button';
import Row from "react-bootstrap/Row"
import Dropdown from "react-bootstrap/Dropdown"
import Select from 'react-select';
import Pagination from 'react-bootstrap/Pagination';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { deviceBreakpoints } from '../../constants/media-breakpoints';
import Popover from 'react-bootstrap/Popover';
import character_1 from "../../images/character-1.png"
import character_2 from "../../images/character-2.png"
import character_3 from "../../images/character-3.png"
import character_4 from "../../images/character-4.png"
import character_5 from "../../images/character-5.png"
import locators from "../../images/locators.png"
import plants from "../../images/plants.png"
import world from "../../images/world.png"
import InputGroup from 'react-bootstrap/esm/InputGroup';

type StyledDeviceTRProps = {
  isSuperAdmin?: boolean
}

export const StyledMenuIcon = styled.div`
width: 30px;
height: 30px;
color: white;
background: url(${menu_icon}) center / contain no-repeat;
display: none;
cursor: pointer;
transition: 0.2s;

&:hover {
  width: 35px;
  height: 35px;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 5px #0B3493;
}

@media ${deviceBreakpoints.menuIcon} { 
  display: block;
}
`;

export const StyledTopContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
`;

export const StyledSideBar = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
padding: 0px 16px;
gap: 8px;
isolation: isolate;

position: realtive;
width: 232px;
min-height: 100vh;


/* Primary/Primary-900 */

background: #062479;

@media ${deviceBreakpoints.laptopS} { 
  width: 188px;
}

@media ${deviceBreakpoints.tablet} { 
  width: 100%;
  min-height: 80px;
  flex-direction: row;
}


@media ${deviceBreakpoints.menuIcon} { 
  position: absolute;
  display: flex;
  z-index: 5;
}

`;

export const StyledBlur = styled.div`
position: fixed;
backdrop-filter: blur(2px);
width: 100%;
height: 100vh;
flex: 1;
cursor: pointer;
left: 0px;
z-index: 5;

display: none;
`;

export const StyledNavigationTopSection = styled.div`
position: relative;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;

width: 200px;
height: 332px;


/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
z-index: 0;

@media ${deviceBreakpoints.laptopS} { 
  width: 158px;
}

@media ${deviceBreakpoints.tablet} { 
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: auto;
  z-index: 10;
}
`;

export const StyledBrandContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding: 24px 16px;

width: auto;
height: 72px;


/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

@media ${deviceBreakpoints.tablet} { 
  padding: 20px 12px;
  height: 42px;
}
`;

export const StyledNavContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 8px;

width: auto;
height: 260px;


/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;

@media ${deviceBreakpoints.tablet} { 
  width: 100%;
  height: auto;
}

@media (max-width: 500px) { 
  display: none;
}
`;

export const StyledNavSectionTop = styled.div`

box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: column;
align-items: flex-start;
padding: 8px 0px;

width: auto;
height: 136px;

/* Slate/Slate-500 */

border-bottom: 1px solid #64748B;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

@media ${deviceBreakpoints.tablet} { 
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
}
`;

export const StyledNavigationSectionBottom = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 8px 0px;

width: auto;
height: 116px;


/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;

@media ${deviceBreakpoints.tablet} { 
  flex-direction: row;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
  justify-content: space-between;
}
`;

export const StyledCompanyNameWithLogo = styled.div`
width: 124px;
height: 24px;

background: url(${company_name_with_logo});

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyledNavigationIcon = styled.div`
width: 24px;
height: 24px;

border-radius: 999px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyledIcon = styled.i``;

export const StyledNavigationDevices = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding: 8px 16px;
gap: 8px;

min-width: 150px;
height: 40px;

border-radius: 16px;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

cursor: pointer;

  &:hover {
    background: #0B3493;
    border-radius: 8px;
  }
`;


export const StyledDevicesCaption = styled.div`
width: 53px;
height: 20px;

/* Body 3

Used in small body copy, input text and placeholders.
*/
font-family: 'SF Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

display: flex;
align-items: center;

/* Slate/Slate-200 */

color: #E2E8F0;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
`;

export const StyledNavigationUsers = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding: 8px 16px;
gap: 8px;

min-width: 150px;
height: 40px;

/* Primary/Primary-800 */

/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;

cursor: pointer;


  &:hover {
    background: #0B3493;
    border-radius: 8px;
  }
`;

export const StyledUsersCaption = styled.div`
width: 39px;
height: 20px;

/* Body 3

Used in small body copy, input text and placeholders.
*/
font-family: 'SF Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

display: flex;
align-items: center;

/* Primary/Primary-300 */

color: #E2E8F0;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
`;

export const StyledNavigationEvents = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding: 8px 16px;
gap: 8px;

min-width: 150px;
height: 40px;

border-radius: 16px;

/* Inside auto layout */

flex: none;
order: 2;
align-self: stretch;
flex-grow: 0;

cursor: pointer;

  &:hover {
    background: #0B3493;
    border-radius: 8px;
  }
`;

export const StyledEventsCaption = styled.div`
width: 62px;
height: 20px;

/* Body 3

Used in small body copy, input text and placeholders.
*/
font-family: 'SF Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

display: flex;
align-items: center;

/* Slate/Slate-200 */

color: #E2E8F0;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
`;

export const StyledNavigationHeading = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px 16px 4px 20px;
gap: 8px;

min-width: 150px;
height: 20px;


/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
`;

export const StyledHeadingCaption = styled.div`
width: 61px;
height: 16px;

font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 16px;
/* identical to box height, or 133% */

display: flex;
align-items: center;
letter-spacing: 0.05em;
text-transform: uppercase;

/* Slate/Slate-400 */

color: #94A3B8;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyledNavigationProfile = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding: 8px 16px;
gap: 8px;

min-width: 150px;
height: 40px;

border-radius: 16px;

/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;

cursor: pointer;

  &:hover {
    background: #0B3493;
    border-radius: 8px;
  }
`;

export const StyledProfileCaption = styled.div`
width: 43px;
height: 20px;

/* Body 3

Used in small body copy, input text and placeholders.
*/
font-family: 'SF Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

display: flex;
align-items: center;

/* Slate/Slate-200 */

color: #E2E8F0;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
`;

export const StyledNavigationOrganization = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding: 8px 16px;
gap: 8px;

min-width: 150px;
height: 40px;

border-radius: 16px;

/* Inside auto layout */

flex: none;
order: 2;
align-self: stretch;
flex-grow: 0;

cursor: pointer;

  &:hover {
    background: #0B3493;
    border-radius: 8px;
  }
`;

export const StyledOrganizationCaption = styled.div`
width: 84px;
height: 20px;

/* Body 3

Used in small body copy, input text and placeholders.
*/
font-family: 'SF Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

display: flex;
align-items: center;

/* Slate/Slate-200 */

color: #E2E8F0;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
`;

export const StyledSideBarFrame = styled.div`
position: absolute;
display: none;
width: 4px;
height: 328px;
right: 4px;
top: 0px;

/* Slate/Slate-400 */

background: #94A3B8;
border-radius: 99px;

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
z-index: 1;

@media ${deviceBreakpoints.tablet} { 
  width: 100%;
  height: 4px;
}
`;

export const StyledProfileBadge = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding: 8px 16px;
gap: 8px;

width: 200px;
height: 40px;

/* Primary/Primary-800 */

background: #0B3493;
border-radius: 8px;

/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
`;

export const StyledNavigationSigOutLink = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding: 8px 16px;
gap: 8px;

width: auto;
height: 56px;

border-radius: 16px;

/* Inside auto layout */

flex: none;
order: 2;
align-self: stretch;
flex-grow: 0;
z-index: 2;

cursor: pointer;

  &:hover {
    background: #0B3493;
    border-radius: 8px;
  }
`;

export const StyledSignOutCaption = styled.div`
width: 55px;
height: 20px;

/* Body 3

Used in small body copy, input text and placeholders.
*/
font-family: 'SF Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

display: flex;
align-items: center;

/* Slate/Slate-200 */

color: #E2E8F0;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
`;

export const StyledDashboardContainer = styled.div`
position: relative;
display: flex;
max-width: 1980px;
min-width: 344px;
min-height: 100vh;
/* Slate/Slate-50 */

background: #F8FAFC;

@media ${deviceBreakpoints.tablet} { 
  flex-direction: column;
}
`;

export const StyledProfileContainer = styled.div`
position: relative;
display: flex;
max-width: 1980px;
min-width: 360px;
min-height: 100vh;

/* Slate/Slate-50 */

background: #F8FAFC;

@media ${deviceBreakpoints.tablet} { 
  flex-direction: column;
}
`;

export const StyledMainContainer = styled.div`
position: relative;
flex-grow: 1;
display: flex;
flex-direction: column;
min-height: 100vh;
// left: 80px;
// top: 80px;

/* Slate/Slate-50 */

background: #F8FAFC;

`;

export const StyledTopBar = styled.div`
box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: center;
padding: 16px 40px;
gap: 24px;

width: 100%;
height: 72px;

/* Slate/Slate-200 */

border-bottom: 1px solid #E2E8F0;

@media ${deviceBreakpoints.tablet} { 
  height: auto;
}

@media ${deviceBreakpoints.menuIcon} { 
  margin-top: 80px;
}
`;

export const StyledCenterContainer = styled.div`
box-sizing: border-box;

/* Auto layout */

display: flex;
flex-grow: 1;
flex-direction: column;
align-items: start;
padding: 16px 40px;
gap: 24px;

/* Slate/Slate-200 */

border-bottom: 1px solid #E2E8F0;

@media ${deviceBreakpoints.tablet} { 
  align-items: center;
  padding: 16px 25px;
}
`;

export const StyledMainHeader = styled(Row as any)`
display: flex;
flex-direction: row;
align-items: center;
padding: 0px;

height: 40px;

font-family: 'SF Pro';
font-style: normal;
font-weight: 510;
font-size: 14px;
line-height: 16px;
justify-content: space-between;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 1;
@media ${deviceBreakpoints.tablet} { 
  justify-content: space-between;
  margin-left: 0px;
}
`;

export const StyledProfileHeader = styled.div`
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
padding: 0px;

height: 88px;
@media ${deviceBreakpoints.tablet} { 
  justify-content: center;
}
`;

export const StyledHeaderWithFilter = styled.div`
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
padding: 0px;

height: 88px;
`;

export const StyledProfileInfoContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 20px;

width: 360px;

border-radius: 16px;

@media ${deviceBreakpoints.tablet} { 
  width: 290px;
}
`;

export const StyledProfileButtonsFrame = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
padding-top: 12px;
gap: 8px;
flex-wrap: wrap;
width: 360px;
height: auto;


/* Inside auto layout */

flex: none;
order: 3;
align-self: stretch;
flex-grow: 0;

@media ${deviceBreakpoints.tablet} { 
  width: 290px;
  justify-content: center;
}
`;

export const StyledProfileSaveButton = styled(Button as any)`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 32px;
gap: 8px;

width: 176px;
height: 48px;

/* Primary/Primary-500 */

background: #2383FE;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
`;

export const StyledProfileResetPassword = styled(Button as any)`
box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 32px;
gap: 8px;

width: 176px;
height: 48px;

/* White */

background: #FFFFFF;
color: #2383FE;
/* Primary/Primary-100 */

border: 1px solid #D2EEFE;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
`;

export const StyledInviteUserContainer = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;
gap: 16px;

width: 142px;
height: 40px;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
`;

export const StyledUsersHeaderCaption = styled.div`
height: 16px;

/* Paragraph 2 (500)

Used in small buttons, form field labels and table headers.
*/
font-family: 'SF Pro';
font-style: normal;
font-weight: 510;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */


/* Slate/Slate-800 */

color: #1E293B;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyledInviteUserButton = styled(Button as any)`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 12px 20px;
gap: 8px;

width: 142px;
height: 40px;

/* Primary/Primary-500 */

background: #2383FE;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

font-family: 'SF Pro';
font-style: normal;
font-weight: 510;
font-size: 14px;
line-height: 16px;
`;

export const StyledHeaderCaption = styled.div`
height: 28px;

/* Heading 5 */

font-family: 'SF Pro';
font-style: normal;
font-weight: 590;
font-size: 21px;
line-height: 28px;
/* identical to box height, or 133% */

display: flex;
align-items: center;

/* Slate/Slate-900 */

color: #0F172A;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyledTableWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
table-layout: fixed;
padding: 0px;
width: 100%;
min-height: 576px;

/* White */

background: #FFFFFF;
/* Elevation 3 */

box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
border-radius: 16px;
`;

export const StyledTableHeader = styled.thead`
box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 0px 0px 0px 40px;
gap: 8px;

display: none;
width: 1128px;
height: 72px;

/* Slate/Slate-200 */

border-bottom: 1px solid #E2E8F0;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

font-family: 'SF Pro';
font-style: normal;
font-weight: 590;
font-size: 18px;
line-height: 24px;
`

export const StyledTableHeadingElement = styled.th`
    /* Heading 6 */

    font-family: 'SF Pro';
    font-style: normal;
    font-weight: 590;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;

    /* Slate/Slate-900 */

    color: #0F172A;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`;

export const StyledDropdownRole = styled(Dropdown as any)`
box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: center;
padding: 8px 8px 8px 12px;
gap: 8px;

width: 208px;
height: 40px;

/* Backgrounds/White */

background: #FFFFFF;
/* Slate/Slate-200 */

border: 1px solid #E2E8F0;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
`;

export const StyledUserTableRow = styled.div`
box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: center;
gap: 4px;

width: 270px;
height: 64px;

/* White */

background: #FFFFFF;
/* Slate/Slate-100 */

border-bottom: 1px solid #F1F5F9;

/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
z-index: 7;
`;


export const test1 = styled.th`
box-sizing: border-box;

/* Auto layout */

flex-direction: row;
align-items: center;
padding: 16px 8px 16px 24px;
gap: 4px;

width: 270px;
height: 64px;

/* White */

background: #FFFFFF;
/* Slate/Slate-100 */

border-bottom: 1px solid #F1F5F9;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
z-index: 8;
`;
export const StyledUserTableHeading = styled.div`
width: 37px;
height: 16px;

/* Paragraph 2 (500)

Used in small buttons, form field labels and table headers.
*/
font-family: 'SF Pro';
font-style: normal;
font-weight: 510;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */

display: flex;
align-items: center;

/* Slate/Slate-900 */

color: #0F172A;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyledUserTableHeadingAction = styled.div`
box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: center;
padding: 12px 8px;
gap: 4px;

width: 48px;
height: 64px;

/* White */

background: #FFFFFF;
/* Slate/Slate-100 */

border-bottom: 1px solid #F1F5F9;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
z-index: 8;
`;

export const StyledUserTableElement = styled.div`
width: 107px;
height: 20px;

/* Body 3

Used in small body copy, input text and placeholders.
*/
font-family: 'SF Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

display: flex;
align-items: center;

/* Slate/Slate-900 */

color: #0F172A;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const StylingUserTableActionElement = styled.div`
box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: center;
padding: 12px 8px;
gap: 4px;

width: 48px;
height: 64px;

/* White */

background: #FFFFFF;
/* Slate/Slate-100 */

border-bottom: 1px solid #F1F5F9;

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
z-index: 7;
`;

export const StyledTableFooter = styled(Row as any)`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 0px;
gap: 8px;

width: 100%;
height: 72px;
`;

export const StyledTableActionElement = styled.div`
position: relative;
width: 14px;
height: 14px;
left: calc(50% - 14px/2);
top: calc(50% - 14px/2);
cursor: pointer;
`;

export const StyledTableUserRoleDropdown = styled(Select as any)`

/* Auto layout */

display: flex;
flex-direction: row;
align-items: center;
padding: 8px 8px 8px 12px;
gap: 8px;

width: 208px;
height: 40px;

/* Backgrounds/White */

background: #FFFFFF;
/* Slate/Slate-200 */

/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
`;

export const StyledPagination = styled(Pagination as any)``;

export const StyledUsersTD = styled.td`
height: 60px;

font-family: 'SF Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;

word-wrap: break-word;

align-items: center;
`;

export const StyledUsersActionTD = styled.td`
width: 48px;
height: 60px;

font-family: 'SF Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;

align-items: center;
`;

export const StyledLogsTR = styled.tr`
borderBottom: 1px solid #F1F5F9;
@media ${deviceBreakpoints.laptopS} { 
  td:nth-of-type(1) {
    display: none
  }
}
@media ${deviceBreakpoints.tablet} { 
  td:nth-of-type(2) {
    overflow-wrap: anywhere
  }
  td:nth-of-type(1), td:nth-of-type(4){
    display: none
  }
}
`;

export const StyledOrgsTR = styled.tr`
borderBottom: 1px solid #F1F5F9;

@media ${deviceBreakpoints.laptopS} { 
  td:nth-of-type(3), td:nth-of-type(4) {
    display: none
  }
  th:nth-of-type(3), th:nth-of-type(4) {
    display: none
  }
}

@media ${deviceBreakpoints.tablet} { 
  td:nth-of-type(2) {
    display: none
  }
  th:nth-of-type(2) {
    display: none
  }
}

@media ${deviceBreakpoints.mobileL} { 
  td:nth-of-type(5) {
    display: none
  }
  th:nth-of-type(5) {
    display: none
  }
}

`;

export const StyledUsersTR = styled.tr`
borderBottom: 1px solid #F1F5F9;

@media ${deviceBreakpoints.laptopS} { 
  td:nth-of-type(3), td:nth-of-type(4), td:nth-of-type(5) {
    display: none
  }
  th:nth-of-type(3), th:nth-of-type(4), th:nth-of-type(5) {
    display: none
  }
}

@media ${deviceBreakpoints.tablet} { 
  td:nth-of-type(1),  td:nth-of-type(3), td:nth-of-type(4), td:nth-of-type(5) {
    display: none
  }
  th:nth-of-type(1), th:nth-of-type(3), th:nth-of-type(4), th:nth-of-type(5) {
    display: none
  }
}

@media ${deviceBreakpoints.laptopL} { 
  td:nth-of-type(3) {
    display: none
  }
  th:nth-of-type(3){
    display: none
  }
}
`;

export const StyledDevicesTR = styled.tr<StyledDeviceTRProps>`
borderBottom: 1px solid #F1F5F9;

${(props) => {
  if (props.isSuperAdmin) {
    return `
      @media ${deviceBreakpoints.laptopL} { 
      td:nth-of-type(3) {
        display: none
      }
      th:nth-of-type(3){
        display: none
      }
    }`
  }
}}

${(props) => {
  if (props.isSuperAdmin) { 
    return `
    @media ${deviceBreakpoints.laptopS} { 
      td:nth-of-type(3), td:nth-of-type(4), td:nth-of-type(5) {
        display: none
      }
      th:nth-of-type(3), th:nth-of-type(4), th:nth-of-type(5) {
        display: none
      }
    }`
  }
  return
}}

${(props) => {
  if (props.isSuperAdmin)  {
    return `
      @media ${deviceBreakpoints.tablet} { 
        td:nth-of-type(1),  td:nth-of-type(3), td:nth-of-type(4), td:nth-of-type(5) {
          display: none
        }
        th:nth-of-type(1), th:nth-of-type(3), th:nth-of-type(4), th:nth-of-type(5) {
          display: none
        }
      }`
  }
  return 
}}
`;

export const StyledUsersTH = styled.th`
font-family: 'SF Pro';
font-style: normal;
font-weight: 550;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */
color: #0F172A;
align-items: center;

width: auto;
min-width: 110px;
height: 64px;
`

export const StyledUsersActionTH = styled.th`
font-family: 'SF Pro';
font-style: normal;
font-weight: 550;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */
color: #0F172A;
align-items: center;

width: 48px;
height: 64px;
padding: 16px 8px 16px 24px;
gap: 4px;
`;

export const StyledPaginationStats = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding: 0px 8px;
gap: 8px;

height: 20px;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
`;

export const StyledPaginationStatsText = styled.div`
height: 20px;

/* Body 3

Used in small body copy, input text and placeholders.
*/
font-family: 'SF Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

display: flex;
align-items: center;

/* Gray/Gray-600 */

color: #525252;
`;

export const StyledPaginationElCaption = styled(Pagination.Item as any)`
font-family: 'SF Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
`;

export const StyledEditRoleModal = styled(Modal as any)`
box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
border-radius: 16px;
`;


export const StyledUserRoleSelect = styled(Form.Select as any)`
box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: center;


font-family: 'SF Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;

width: 360px;
height: 48px;
/* Slate/Slate-200 */

border: 1px solid #E2E8F0;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;

@media ${deviceBreakpoints.tablet} { 
  width: 290px;
}

@media ${deviceBreakpoints.mobileL} { 
  width: 180px
}
`;

export const StyledLogOrgSelect = styled(Form.Select as any)`
box-sizing: border-box;

display: flex;
flex-direction: row;
align-items: center;
padding: 12px 8px 12px 12px;
gap: 8px;

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;

width: 230px;
height: 48px;

border: 1px solid #E2E8F0;
border-radius: 4px;

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;

@media ${deviceBreakpoints.mobileL} { 
  width: 180px
}
`;

export const StyledUserRoleOption = styled.option`
font-family: 'SF Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
`;

export const StyledUserInviteConfirm = styled.div`
box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: flex-start;
padding-top: 16px;
gap: 8px;

position: absolute;
width: 360px;
height: auto;

/* Green/Green-50 */

background: #EDFFEA;
/* Green/Green-400 */

border: 1px solid #62D65D;
border-radius: 8px;

@media ${deviceBreakpoints.tablet} { 
  width: 300px
}
`;

export const StyledUserInviteConfirmText = styled.span`
font-family: 'SF Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
text-wrap: balance;
width: 100%;
word-break: break-all;
/* identical to box height, or 143% */

display: flex;
align-items: center;

/* Green/Green-800 */

color: #0E6D2C;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
`;

export const StyledConfirmIconWrapper = styled.div`
width: 20px;
height: 20px;

border-radius: 999px;
padding-left: 6px;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyleConfirmIcon = styled.div`
width: 16px;
height: 20px;
left: calc(50% - 16px/2);
top: calc(50% - 20px/2);

font-family: 'Font Awesome 6 Pro';
font-style: normal;
font-weight: 900;
font-size: 16px;
line-height: 20px;
/* identical to box height, or 125% */

display: flex;
align-items: center;
text-align: center;
`;

export const StyledFeedback = styled(Form.Control.Feedback as any)`
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
`;

export const StyledEventsUserTR = styled.tr`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
isolation: isolate;

width: 137px;
height: 576px;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyledEventsUserTD = styled.td`
box-sizing: border-box;

/* Auto layout */
width: 137px;
height: 64px;

/* White */

background: #FFFFFF;
/* Slate/Slate-100 */

border-bottom: 1px solid #F1F5F9;

/* Inside auto layout */

font-family: 'SF Pro';
font-style: normal;
font-weight: 590;
font-size: 14px;
line-height: 20px;
`;

export const StyledEventsMessageTD = styled.td`
box-sizing: border-box;

align-items: center;


width: 800px;
height: 64px;

/* White */

background: #FFFFFF;
/* Slate/Slate-100 */

border-bottom: 1px solid #F1F5F9;

/* Inside auto layout */



font-family: 'SF Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
`;

export const StyledEventsTimeTD = styled.td`
box-sizing: border-box;

align-items: center;


width: 115px;
height: 64px;

/* White */

background: #FFFFFF;
/* Slate/Slate-100 */

border-bottom: 1px solid #F1F5F9;

/* Inside auto layout */


font-family: 'SF Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
`;

export const StyledFilterSection = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;
gap: 8px;

width: 66px;
height: 16px;

cursor: pointer;
`;

export const StyledFilterText = styled.div`
width: 42px;
height: 16px;

font-family: 'SF Pro';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
`;

export const StyledTopFilterSection = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 0px;
gap: 8px;

width: 230px;
height: 24px;


/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

@media ${deviceBreakpoints.mobileL} { 
  width: 180px;
}
`;

export const StyledTopFilterCaption = styled.div`
width: 80px;
height: 16px;

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */


color: #000000;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyledTopFilterClear = styled.div`
width: 35px;
height: 16px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */


/* Gray/Gray-500 */

color: #767676;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;

cursor: pointer;
`;

export const StyledPopoverMenuOrgs = styled(Popover as any)`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;

position: absolute;
width: 184px;
height: 80px;

/* White */

background: #FFFFFF;
/* Elevation 3 */

box-shadow: 10px 14px 4px rgba(0, 0, 0, 0.1);
border-radius: 8px;
`;

export const StyledPopoverMenuLink = styled.div`
display: flex;
flex-direction: row;
justify-content: left;
align-items: center;
padding: 10px 16px;
gap: 8px;

width: 184px;
height: 40px;

/* White */

background: #FFFFFF;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;

cursor: pointer;

  &:hover {
    background: #F5F5F5;
    border-radius: 8px;
  }
`;

export const StyledBottomFrameAddOrganization = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 16px 0px;
gap: 16px;

width: 344px;
height: 240px;


/* Inside auto layout */

flex: none;
order: 2;
flex-grow: 0;
`;

export const StyledCreateOrganizationButton = styled(Button as any)`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 12px 54px;
gap: 8px;

width: 222px;
height: 56px;

/* Gray/Gray-500 */

background: #767676;
border-color: #767676;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 4;
flex-grow: 0;

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */


/* White */

color: #FFFFFF;
`;

export const StyledNoDevicesFrame = styled.div`
position: relative;
width: 1440px;
height: 820px;
left: 80px;
top: 80px;

/* Slate/Slate-50 */

background: #F8FAFC;
`;

export const StyledNoDevicesCentralFrame = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding: 0px;
gap: 32px;
margin: 0 auto;
position: relative;
width: 520px;

@media ${deviceBreakpoints.tablet} { 
  width: 420px;
}

@media ${deviceBreakpoints.mobileL} { 
  width: 360px;
}
`;

export const StyledNoDevicesCentralImageFrame = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 40px 22px 24px;
gap: 8px;

width: 100%;
height: 321.69px;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyledNoDevicesImageGroup = styled.div`
max-width: 461.17px;
width: 100%;
height: 257.69px;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyledNoDevicesImageWorld = styled.div`
position: absolute;
left: 32.3%;
right: 23.41%;
top: 2.43%;
bottom: 56.32%;

background: url(${world}) center / contain no-repeat;
`;

export const StyledNoDevicesImagePlants = styled.div`
position: absolute;
left: 5.66%;
right: 38.67%;
top: 8.08%;
bottom: 60.03%;

background: url(${plants}) center / contain no-repeat;
`;

export const StyledNoDevicesImageLocators = styled.div`
position: absolute;
left: 18.37%;
right: 12.92%;
top: 5.12%;
bottom: 60.76%;

background: url(${locators}) center / contain no-repeat;
`;

export const StyleNoDevicesImageCharacter_1 = styled.div`
position: absolute;
left: 9.03%;
right: 79.03%;
top: 2.57%;
bottom: 60.36%;

background: url(${character_1}) center / contain no-repeat;
`;

export const StyleNoDevicesImageCharacter_2 = styled.div`
position: absolute;
left: 13.43%;
right: 68.76%;
top: 4.16%;
bottom: 33.56%;

background: url(${character_2}) center / contain no-repeat;
`;

export const StyleNoDevicesImageCharacter_3 = styled.div`
position: absolute;
left: 46.71%;
right: 39.64%;
top: 6.8%;
bottom: 43.56%;

background: url(${character_3}) center / contain no-repeat;
`;

export const StyleNoDevicesImageCharacter_4 = styled.div`
position: absolute;
left: 76.87%;
right: 9.5%;
top: 6.15%;
bottom: 27.46%;

background: url(${character_4}) center / contain no-repeat;
`;

export const StyleNoDevicesImageCharacter_5 = styled.div`
position: absolute;
left: 77.72%;
right: 5.66%;
top: 5.02%;
bottom: 75.72%;

background: url(${character_5}) center / contain no-repeat;

@media (max-width: 500px) { 
  bottom: 65.72%;
}
`;

export const StyledNoDevicesCentralMessage = styled.div`
height: 28px;

/* Heading 5 */

font-family: 'SF Pro';
font-style: normal;
font-weight: 590;
font-size: 21px;
line-height: 28px;
/* identical to box height, or 133% */

display: flex;
align-items: center;
text-align: center;

/* Slate/Slate-800 */

color: #1E293B;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
`;

export const StyledNoDevicesCentralText = styled.div`
height: 72px;

/* Paragraph 2

Used as default body copy.
*/
font-family: 'SF Pro';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* or 150% */

text-align: center;

/* Gray/Gray-500 */

color: #767676;


/* Inside auto layout */

flex: none;
order: 2;
flex-grow: 0;

@media ${deviceBreakpoints.tablet} { 
  height: 100px;
}
`;

export const StyledNoDevicesInput = styled(Form.Control as any)`
box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: center;
padding: 12px 16px;
gap: 8px;

width: 360px;
height: 56px;

/* White */

background: #FFFFFF;
/* Slate/Slate-200 */

border: 1px solid #E2E8F0;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 3;
flex-grow: 0;
`;

export const StyledNoDevicesOrderButton = styled(Button as any)`




width: 264px;
height: 56px;

/* Primary/Primary-500 */

background: #2383FE;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 4;

flex-grow: 0;
`;

export const StyledPaymentDetailsCaption = styled.div`
width: 190px;
height: 16px;

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 16px;
/* identical to box height, or 80% */


color: #000000;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyledPaymentOrderCaption = styled.div`
width: 158px;
height: 16px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */


color: #000000;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyledPaymentOrderText = styled.div`
width: 418px;
font-family: 'Inter';
font-style: italic;
font-weight: 400;
font-size: 14px;
color: #000000;
flex: none;
order: 0;
flex-grow: 0;

@media ${deviceBreakpoints.menuIcon} { 
  width: 290px;
}
`;

export const StyledPaymentMethodFrame = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
margin-top: 15px;
gap: 8px;

width: 418px;
height: 72px;


/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;


@media ${deviceBreakpoints.menuIcon} { 
  width: 290px;
}
`;

export const StyledPaymentMethodSection = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 8px;

width: 418px;
height: 72px;


/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

@media ${deviceBreakpoints.menuIcon} { 
  width: 290px;
}
`;

export const StyledPaymentMethodsSectionFrame = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;
gap: 8px;

width: 418px;
height: 48px;


/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;

@media ${deviceBreakpoints.menuIcon} { 
  width: 280px;
}
`;

export const StyledPaymentCardMethod = styled.div`
box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: center;
padding: 16px;
gap: 8px;

width: 205px;
height: 48px;

/* Gray/Gray-50 */

background: #FAFAFA;
/* Gray/Gray-200 */

border: 1px solid #E5E5E5;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 1;

@media ${deviceBreakpoints.menuIcon} { 
  width: 140px;
}
`;

export const StyledPaymentInvoiceMethod = styled.div`
box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: center;
padding: 16px;
gap: 8px;

width: 205px;
height: 48px;

/* Gray/Gray-200 */

border: 1px solid #E5E5E5;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 1;

@media ${deviceBreakpoints.menuIcon} { 
  width: 140px;
}
`;

export const StyledDeviceModelsSection = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 8px;

width: 418px;
height: 72px;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;

@media ${deviceBreakpoints.menuIcon} { 
  width: 290px;
}
`;

export const StyledDeviceModelSelect = styled(Form.Select as any)`
box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 8px;
gap: 8px;

width: 418px;
height: 48px;

/* Gray/Gray-200 */

border: 1px solid #E5E5E5;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;

@media ${deviceBreakpoints.menuIcon} { 
  width: 290px;
}
`;

export const StyledBottomPaymentFrame = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;
gap: 8px;

width: 418px;
height: 72px;


/* Inside auto layout */

flex: none;
order: 4;
align-self: stretch;
flex-grow: 0;

@media ${deviceBreakpoints.menuIcon} { 
  width: 290px;
}
`;

export const StyledPaymentSummarySection = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 8px;

width: 418px;
height: 48px;


/* Inside auto layout */

flex: none;
order: 4;
align-self: stretch;
flex-grow: 0;

@media ${deviceBreakpoints.menuIcon} { 
  width: 290px;
}
`;

export const StyledDeviceQuantitySection = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 8px;

width: 418px;
height: 72px;


/* Inside auto layout */

flex: none;
order: 2;
align-self: stretch;
flex-grow: 0;

marginTop: 15px; 

@media ${deviceBreakpoints.menuIcon} { 
  width: 290px;
}
`;

export const StyledInputGroup = styled(InputGroup as any)`
@media ${deviceBreakpoints.menuIcon} { 
  width: 290px !important;
}
`

export const StyledOrderSummaryCaption = styled.div`
width: 158px;
height: 16px;

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */


color: #000000;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyledPaymentSummaryDetails = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
flex: none;
order: 0;
flex-grow: 1;
`;

export const StyledSummaryCaption = styled.div`
width: 158px;
height: 16px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */


color: #000000;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyledSummaryValue = styled.div`
width: 158px;
height: 16px;

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */


color: #000000;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
`;

export const StyledPaymentOrganizationSection = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 8px;

width: 418px;
height: 48px;


/* Inside auto layout */

flex: none;
order: 3;
align-self: stretch;
flex-grow: 0;

@media ${deviceBreakpoints.menuIcon} { 
  width: 290px;
}
`;

export const StyledDevicesMainHeader = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 0px;

width: 100%;
height: 40px;


/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 1;
`;

export const StyledDevicesHeaderCaption = styled.div`
width: 54px;
height: 16px;

/* Paragraph 2 (500)

Used in small buttons, form field labels and table headers.
*/
font-family: 'SF Pro';
font-style: normal;
font-weight: 510;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */


/* Slate/Slate-800 */

color: #1E293B;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyledDevicesOrderHeaderButtonContainer = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;
gap: 16px;

height: 40px;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
`;

export const StyledDevicesOrderHeaderButton = styled(Button as any)`
display: flex;
flex-direction: column;
align-items: flex-start;

justify-content: center;
align-items: center;
padding: 12px 20px;
gap: 8px;

width: 128px;
height: 40px;

border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;

font-family: 'SF Pro';
font-style: normal;
font-weight: 510;
font-size: 14px;
line-height: 16px;
`;

export const StyledDevicesFormHeader = styled.div`
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
padding: 0px;

height: 88px;
@media ${deviceBreakpoints.tablet} { 
  justify-content: center;
}
`;

export const StyledDevicesFormHeaderCaption = styled.div`
width: 127px;
height: 28px;

/* Heading 5 */

font-family: 'SF Pro';
font-style: normal;
font-weight: 590;
font-size: 21px;
line-height: 28px;
/* identical to box height, or 133% */

display: flex;
align-items: center;

/* Slate/Slate-900 */

color: #0F172A;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyledDevicesFormHeaderButtonContainer = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;
gap: 16px;

width: 186px;
height: 40px;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
`;

export const StyledDeviceHeaderSearchContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 8px;

width: 88px;
height: 40px;

border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;

cursor: pointer;
`;

export const StyledDeviceHeaderFilterContainer= styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 8px;

width: 82px;
height: 40px;

border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;


`;

export const StyledDevicesSearchIcon = styled.i`
display: flex;
flex-direction: row;
justify-content: center;

width: 14px;
height: 14px;
left: calc(50% - 14px/2);
top: calc(50% - 14px/2);

display: flex;
align-items: center;
text-align: center;

/* Primary/Primary-500 */

color: #2383FE;

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyledDevicesSearchCaption = styled.div`
display: flex;
flex-direction: row;
justify-content: center;

width: 48px;
height: 16px;

/* Paragraph 2 (500)

Used in small buttons, form field labels and table headers.
*/
font-family: 'SF Pro';
font-style: normal;
font-weight: 510;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */


/* Primary/Primary-500 */

color: #2383FE;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
`;

export const StyledDevicesFilterIcon = styled.i`
width: 14px;
height: 14px;
left: calc(50% - 14px/2);
top: calc(50% - 14px/2);

display: flex;
align-items: center;
text-align: center;

/* Primary/Primary-500 */

color: #2383FE;

flex: none;
order: 0;
flex-grow: 0;
`;

export const StyledDevicesFilterCaption = styled.i`
width: 42px;
height: 16px;

/* Paragraph 2 (500)

Used in small buttons, form field labels and table headers.
*/
font-family: 'SF Pro';
font-style: normal;
font-weight: 510;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */


/* Primary/Primary-500 */

color: #2383FE;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
`;

export const StyledDevicesTableWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;

position: absolute;
width: 1128px;
height: 576px;
left: 272px;
top: 160px;

/* White */

background: #FFFFFF;
/* Elevation 3 */

box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
border-radius: 16px;
`;

export const StyledDevicesTableHeader = styled.thead`
box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 0px 0px 0px 40px;
gap: 8px;

display: none;
width: 1128px;
height: 72px;

/* Slate/Slate-200 */

border-bottom: 1px solid #E2E8F0;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
`;

export const StyledDevicesIMEIHeader = styled.th`
box-sizing: border-box;
min-width: 120px;
max-width: 160px;
height: 64px;

/* White */

background: #FFFFFF;
/* Slate/Slate-100 */

border-bottom: 1px solid #F1F5F9;

font-family: 'SF Pro';
font-style: normal;
font-weight: 510;
font-size: 14px;
line-height: 16px;
`;

export const StyledDevicesBulkHeader = styled.th`
box-sizing: border-box;

/* Auto layout */

align-items: center;

width: 48px;
height: 64px;

/* White */

background: #FFFFFF;
/* Slate/Slate-100 */

border-bottom: 1px solid #F1F5F9;

font-family: 'SF Pro';
font-style: normal;
font-weight: 510;
font-size: 14px;
line-height: 16px;
`;

export const StyledDevicesStatusHeader = styled.th`
box-sizing: border-box;

min-width: 150px;
height: 64px;

/* White */

background: #FFFFFF;
/* Slate/Slate-100 */

border-bottom: 1px solid #F1F5F9;

font-family: 'SF Pro';
font-style: normal;
font-weight: 510;
font-size: 14px;
line-height: 16px;
`;

export const StyledDevicesHoursHeader = styled.th`
box-sizing: border-box;

width: 344px;
height: 64px;

/* White */

background: #FFFFFF;
/* Slate/Slate-100 */

border-bottom: 1px solid #F1F5F9;

font-family: 'SF Pro';
font-style: normal;
font-weight: 510;
font-size: 14px;
line-height: 16px;
`;

export const StyledDevicesActionsHeader = styled.th`
box-sizing: border-box;

width: 48px;
height: 64px;

/* White */

background: #FFFFFF;
/* Slate/Slate-100 */

border-bottom: 1px solid #F1F5F9;

font-family: 'SF Pro';
font-style: normal;
font-weight: 510;
font-size: 14px;
line-height: 16px;
`;

export const StyledDevicesBulkTD = styled.td`
box-sizing: border-box;

/* Auto layout */
align-items: center;
padding: 12px 8px 12px 16px;
gap: 4px;

width: 48px;
height: 64px;

/* White */

background: #FFFFFF;
/* Slate/Slate-100 */

border-bottom: 1px solid #F1F5F9;
`;

export const StyledDevicesIMEITD = styled.td`
box-sizing: border-box;

/* Auto layout */
align-items: center;
padding: 22px 8px;
gap: 4px;

width: 160px;
height: 64px;

/* White */

background: #FFFFFF;
/* Slate/Slate-100 */

border-bottom: 1px solid #F1F5F9;

font-family: 'SF Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

`;

export const StyledDevicesCalibrationDateTD = styled.td`
box-sizing: border-box;

/* Auto layout */
align-items: center;
padding: 22px 8px;
gap: 4px;

width: 190px;
height: 64px;

/* White */

background: #FFFFFF;
/* Slate/Slate-100 */

border-bottom: 1px solid #F1F5F9;

font-family: 'SF Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

`;

