import React, {useEffect, useState, useRef} from "react"
import {Spinner, Table} from "react-bootstrap"
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import * as S from "./styled-components"
import SideBar from "./sideBar";
import {eventsBoard} from "../../constants/dashboard";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    selectListOfOrganizations,
    selectOrganizationEvents,
    selectOrganizationEventsQuantity
} from "../../features/organization/organizationSlice";
import {
    getAllOrganizations,
    getAllUsers,
    getOrganizationLogs,
    getOrganizationUsers
} from "../../features/organization/organizationThunks";
import {isOrgAdmin, isSuperAdmin} from "../../utils";
import {selectAuthorizedUser} from "../../features/user/userSlice";
import moment from "moment"
import {get, isEqual, isEmpty} from "lodash";
import PaginationFooter from "../paginationFooter";
import {selectLoading} from "../../features/global/globalSlice";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function EventLog() {
    const dispatch = useAppDispatch();

    const target = useRef(null);
    const limit = 8;
    const [pageNumber, setPageNumber] = useState(1);
    const [showFilter, setShowFilter] = useState(false);
    const [organizationId, setOrganizationId] = useState("");
    const [loadedLogs, setLoadedLogs] = useState(false);
    const [currentPopover, setCurrentPopover] = useState<number | null>(null);

    const eventsList = useAppSelector(selectOrganizationEvents);
    const eventsQuantity = useAppSelector(selectOrganizationEventsQuantity);
    const user = useAppSelector(selectAuthorizedUser);
    const organizationsList = useAppSelector(selectListOfOrganizations);
    const isLoading = useAppSelector(selectLoading);

    const lastPage = Math.ceil(eventsQuantity / limit);
    const offset = (pageNumber - 1) * limit; //+ 1;
    let endRange = (offset + limit - 1) > eventsQuantity ? eventsQuantity - 1 : offset + limit - 1;

    useEffect(() => {
        if (!loadedLogs) {
            getListOfEvents();
            if (isSuperAdmin(user)) {
                fetchOrganizations();
            }

            setLoadedLogs(true);
        }
    }, []);

    useEffect(() => {
        getListOfEvents();
    }, [pageNumber, organizationId]);

    const getListOfEvents = async () => {
        if (isSuperAdmin(user)) {
            let orgIds: string[] | undefined = [];
            if (isEqual(organizationId, "")) {
                orgIds = undefined;//organizationsList.length > 0 ? organizationsList.map((org) => get(org, "id", "")) : undefined;
            } else {
                orgIds = [organizationId];
            }
            await dispatch(getOrganizationLogs({limit, offset, orgIds}));
        } else {
            await dispatch(getOrganizationLogs({limit, offset}));
        }
    }

    const fetchOrganizations = async () => {
        await dispatch(getAllOrganizations(null));
    }

    const onFilter = () => {
        setShowFilter(!showFilter);
    }

    const onSelectOrganization = (event: React.ChangeEvent<HTMLInputElement>) => {
        const id = event.target.value;
        setTimeout(() => {
            setOrganizationId(id);
            setShowFilter(false);
        }, 300);
    }

    const onClearFilter = () => {
        setTimeout(() => {
            setOrganizationId("");
            setShowFilter(false);
        }, 300);
    }

    const preLoaderWidth = (): number => isSuperAdmin(user) ? 4 : 3;

    return (
        <S.StyledDashboardContainer>
            <SideBar currentComponent={eventsBoard}/>
            <S.StyledMainContainer>
                <S.StyledTopBar>
                    <S.StyledMainHeader>Event log</S.StyledMainHeader>
                </S.StyledTopBar>
                <S.StyledCenterContainer>
                    <S.StyledHeaderWithFilter>
                        <S.StyledHeaderCaption>Event log</S.StyledHeaderCaption>
                        {isSuperAdmin(user) && (
                            <>
                                <S.StyledFilterSection>
                                    <OverlayTrigger trigger="click"
                                                    placement="left"
                                                    rootClose={true}
                                                    rootCloseEvent="mousedown"
                                                    key={999}
                                                    show={isEqual(currentPopover, 999)}
                                                    onToggle={() => {
                                                        if (isEqual(currentPopover, 999)) {
                                                            setCurrentPopover(null);
                                                        } else {
                                                            setCurrentPopover(999);
                                                        }
                                                    }}
                                                    overlay={
                                                        <Popover id="popover-basic">
                                                            <Popover.Header as="h3" style={{background: "#FFFFFF"}}>
                                                                Organizations
                                                            </Popover.Header>
                                                            <Popover.Body>
                                                                <S.StyledTopFilterSection style={{height: "1px"}}>
                                                                </S.StyledTopFilterSection>
                                                                <S.StyledLogOrgSelect value={organizationId}
                                                                                      style={{
                                                                                          marginTop: "10px",
                                                                                          border: "1px solid #E2E8F0",
                                                                                          borderRadius: "4px"
                                                                                      }}
                                                                                      onChange={onSelectOrganization}
                                                                >
                                                                    <S.StyledUserRoleOption value={""}>Pick an
                                                                        organization</S.StyledUserRoleOption>
                                                                    {organizationsList.map((org) => {
                                                                        return (
                                                                            <S.StyledUserRoleOption value={org.id}
                                                                                                    key={"ord-" + org.id}>
                                                                                {org.name}
                                                                            </S.StyledUserRoleOption>
                                                                        )
                                                                    })}
                                                                </S.StyledLogOrgSelect>
                                                            </Popover.Body>
                                                        </Popover>
                                                    }
                                    >
                                        <S.StyledFilterSection>
                                            <S.StyledIcon className="fa-solid fa-filter"/>
                                            <S.StyledFilterText>Filter</S.StyledFilterText>
                                        </S.StyledFilterSection>
                                    </OverlayTrigger>
                                </S.StyledFilterSection>
                            </>
                        )}
                    </S.StyledHeaderWithFilter>
                    <S.StyledTableWrapper>
                        <Table>
                            <thead>
                            <S.StyledUsersTR>
                            </S.StyledUsersTR>
                            </thead>
                            <tbody>
                            {isLoading ? (
                                <tr>
                                    <td rowSpan={preLoaderWidth()} colSpan={preLoaderWidth()}>
                                        <div className="text-center py-5">
                                            <Spinner animation="border"/>
                                        </div>
                                    </td>
                                </tr>
                            ) : (
                                eventsList.map((event) => {
                                    return (
                                        <S.StyledLogsTR key={"event" + event.id}>
                                            <S.StyledEventsUserTD style={{padding: "22px 8px 22px 22px", gap: "4px"}}>
                                                <strong>{event.user && event.user.name}</strong>
                                            </S.StyledEventsUserTD>
                                            <S.StyledEventsMessageTD style={{
                                                padding: "22px 8px 22px 22px",
                                                gap: "4px",
                                                width: "750px",
                                                maxWidth: "750px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}>
                                                {event.event}
                                            </S.StyledEventsMessageTD>
                                            {isSuperAdmin(user) && (
                                                <S.StyledEventsUserTD
                                                    style={{padding: "22px 8px 22px 22px", gap: "4px", width: "180px"}}>
                                                    <strong>{isEmpty(event.organization) ? "None" : event.organization.name}</strong>
                                                </S.StyledEventsUserTD>
                                            )}
                                            <S.StyledEventsTimeTD
                                                style={{padding: "22px 8px 22px 22px", gap: "4px", minWidth: "96px"}}>
                                                {event.createdAt && moment(event.createdAt).format("DD-MM-YYYY")}
                                            </S.StyledEventsTimeTD>
                                        </S.StyledLogsTR>
                                    )
                                })
                            )
                            }
                            </tbody>
                        </Table>
                    </S.StyledTableWrapper>
                    <PaginationFooter setPageNumber={setPageNumber}
                                      pageNumber={pageNumber}
                                      lastPage={lastPage}
                                      offset={offset}
                                      endRange={endRange}
                                      quantity={eventsQuantity}
                    />
                </S.StyledCenterContainer>
            </S.StyledMainContainer>
        </S.StyledDashboardContainer>
    );
}

export default EventLog;
