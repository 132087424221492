import React, {useEffect, useState} from "react";
import * as S from "./styled-components";
import * as SUser from "../user/styled-components";
import Form from 'react-bootstrap/Form';
import {isEqual, isEmpty} from "lodash";
import SideBar from "./sideBar";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectAuthorizedUser, selectUserOperationStatus, setStatus} from "../../features/user/userSlice";
import {selectLoading, selectMessage, setMessage} from "../../features/global/globalSlice"
import {
    formBasicCurrentPassword,
    formBasicEmail,
    formBasicFullName,
    formBasicPassword, formBasicPasswordConfirm,
    formBasicPhoneNumber, passwordValidationError
} from "../../constants";
import {RegistrationErrors, User} from "../../types";
import {getInvalidFiledName, validateMobileNumber, validatePassword} from "../../utils";
import {profileBoard} from "../../constants/dashboard";
import {InputGroup} from "react-bootstrap";
import {updateProfile} from "../../features/user/userThunks"

function Profile() {
    const dispatch = useAppDispatch();


    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [showReset, setShowReset] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errors, setErrors] = useState<RegistrationErrors>({});
    const [touch, setTouch] = useState(false);

    const user = useAppSelector(selectAuthorizedUser);
    const isLoading = useAppSelector(selectLoading);
    const errorMessage = useAppSelector(selectMessage);
    const status = useAppSelector(selectUserOperationStatus);

    useEffect(() => {
        if (user) {
            setEmail(user.email);
            setName(user.name);
            if (user.mobileNumber) setMobileNumber(user.mobileNumber);
        }
    }, [user]);

    useEffect(() => {
        if (isEqual(status, "updated")) {
            dropPasswords();
            setShowReset(false);
            setShowSuccess(true);
        }
    }, [status]);

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                clearSession();
            }, 2000);
        }
    }, [showSuccess]);

    useEffect(() => {
        let fieldName = getInvalidFiledName(errorMessage);
        fieldName = isEqual(fieldName, "password") ? "currentPassword" : fieldName;
        setErrors({
            ...errors,
            [fieldName]: errorMessage
        });
    }, [errorMessage]);

    useEffect(() => {
        if (showReset) {
            dropPasswords();
        } else if (!isEmpty(user) && !isEmpty(email)) {
            validateData();
        }
    }, [showReset]);

    useEffect(() => {
        return () => {
            dispatch(setMessage(null));
            dispatch(setStatus("idle"));
        }
    }, []);

    const clearSession = () => {
        dispatch(setStatus("idle"));
        setShowSuccess(false);
    }

    const dropPasswords = () => {
        setCurrentPassword("");
        setPassword("");
        setPasswordConfirmation("");
    }

    const validateData = (): boolean => {
        let tmpErrors = {};

        if (isEmpty(name)) {
            tmpErrors = {...tmpErrors, fullName: "Name cannot be empty"};
        } else if (name.length > 20) {
            tmpErrors = {...tmpErrors, fullName: "Full name should contains no more 20 symbols"}
        }

        if (!isEmpty(mobileNumber)) {
            if (mobileNumber.length > 13) {
                tmpErrors = {...tmpErrors, mobileNumber: "Phone should contains no more 13 symbols"}
            } else if (!validateMobileNumber(mobileNumber)) {
                tmpErrors = {...tmpErrors, mobileNumber: "Phone number is not valid"}
            }
        }

        if (showReset) {
            if (isEmpty(currentPassword)) {
                tmpErrors = {...tmpErrors, currentPassword: "Current password cannot be empty"}
            } else if (!validatePassword(currentPassword)) {
                tmpErrors = {
                    ...tmpErrors,
                    currentPassword: passwordValidationError
                }
            }
            if (isEmpty(password)) {
                tmpErrors = {
                    ...tmpErrors,
                    password: "New password cannot be empty"
                }
            } else if (!validatePassword(password)) {
                tmpErrors = {
                    ...tmpErrors,
                    password: passwordValidationError
                }
            }
            if (isEmpty(passwordConfirmation)) {
                tmpErrors = {...tmpErrors, passwordConfirm: "Confirm password cannot be empty"}
            } else if (!isEqual(password, passwordConfirmation)) {
                tmpErrors = {
                    ...tmpErrors,
                    passwordConfirm: "New password and Confirm password fields are different"
                }
            }
        }

        let notValid = Object.keys(tmpErrors).length > 0;
        if (notValid) {
            setErrors(tmpErrors);
        } else {
            setErrors({});
        }

        return notValid;
    }

    const onUpdateProfile = async () => {
        if (!validateData()) {
            if (user && user.id) {
                const id = user.id;
                let updateObj: User = {email, name, mobileNumber, id};
                if (showReset) {
                    updateObj = {...updateObj, password, passwordConfirmation, currentPassword};
                }
                await dispatch(updateProfile({user: updateObj, updateState: true}));
                setTouch(false);
            }
        }
    }

    return (
        <S.StyledProfileContainer>
            <SideBar currentComponent={profileBoard}/>
            <S.StyledMainContainer>
                <S.StyledTopBar>
                    <S.StyledMainHeader>{user && user.name}</S.StyledMainHeader>
                </S.StyledTopBar>
                <S.StyledCenterContainer>
                    <S.StyledProfileHeader>
                        <S.StyledHeaderCaption>Profile settings</S.StyledHeaderCaption>
                    </S.StyledProfileHeader>

                    <S.StyledProfileInfoContainer>
                        <Form>
                            <Form.Group controlId={formBasicFullName}>
                                <SUser.StyledRegistrationLabel>Full name</SUser.StyledRegistrationLabel>
                                <SUser.StyledRegistrationInput type="input"
                                                            value={name}
                                                            isInvalid={!!errors.fullName}
                                                            disabled={isLoading}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                setName(event.target.value);
                                                                setTouch(true);
                                                            }}

                                />
                                <S.StyledFeedback type="invalid">{errors.fullName}</S.StyledFeedback>
                            </Form.Group>
                            <Form.Group controlId={formBasicPhoneNumber}>
                                <SUser.StyledRegistrationLabel>Phone</SUser.StyledRegistrationLabel>
                                <SUser.StyledRegistrationInput type="input"
                                                            value={mobileNumber}
                                                            isInvalid={!!errors.mobileNumber}
                                                            disabled={isLoading}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                setMobileNumber(event.target.value);
                                                                setTouch(true);
                                                            }}

                                />
                                <S.StyledFeedback type="invalid">{errors.mobileNumber}</S.StyledFeedback>
                            </Form.Group>
                            <Form.Group controlId={formBasicEmail}>
                                <SUser.StyledRegistrationLabel>Email</SUser.StyledRegistrationLabel>
                                <InputGroup>
                                    <Form.Control type="input"
                                                value={email}
                                                disabled={true}
                                                style={{
                                                    padding: "12px 8px 12px 12px",
                                                    border: "1px solid #E2E8F0",
                                                    gap: "8px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontFamily: 'Roboto',
                                                    fontStyle: "normal",
                                                    fontWeight: 400,
                                                    fontSize: "14px",
                                                    lineHeight: "16px"
                                                }}
                                    />
                                    <InputGroup.Text>
                                        <i className="fa-sharp fa-solid fa-lock"/>
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                            {showReset && (
                                <>
                                    <Form.Group controlId={formBasicCurrentPassword}>
                                        <SUser.StyledRegistrationLabel>Current password</SUser.StyledRegistrationLabel>
                                        <SUser.StyledRegistrationInput type="password"
                                                                    value={currentPassword}
                                                                    isInvalid={!!errors.currentPassword}
                                                                    disabled={isLoading}
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                        setCurrentPassword(event.target.value);
                                                                        setTouch(true);
                                                                    }}
                                        />
                                        <S.StyledFeedback type="invalid">{errors.currentPassword}</S.StyledFeedback>
                                    </Form.Group>
                                    <Form.Group controlId={formBasicPassword}>
                                        <SUser.StyledRegistrationLabel>New password</SUser.StyledRegistrationLabel>
                                        <SUser.StyledRegistrationInput type="password"
                                                                    value={password}
                                                                    isInvalid={!!errors.password}
                                                                    disabled={isLoading}
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                        setPassword(event.target.value)
                                                                        setTouch(true);
                                                                    }}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId={formBasicPasswordConfirm}>
                                        <SUser.StyledRegistrationLabel>Confirm password</SUser.StyledRegistrationLabel>
                                        <SUser.StyledRegistrationInput type="password"
                                                                    value={passwordConfirmation}
                                                                    isInvalid={!!errors.passwordConfirm}
                                                                    disabled={isLoading}
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                        setPasswordConfirmation(event.target.value);
                                                                        setTouch(true);
                                                                    }}
                                        />
                                        <S.StyledFeedback type="invalid">{errors.passwordConfirm}</S.StyledFeedback>
                                    </Form.Group>
                                </>
                            )}
                            <S.StyledProfileButtonsFrame>
                                <S.StyledProfileSaveButton style={{fontSize: "revert"}} onClick={onUpdateProfile} disabled={!touch || isLoading}>
                                    {isLoading && (<SUser.StyledSingInSpinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />)}
                                    Save changes
                                </S.StyledProfileSaveButton>
                                <S.StyledProfileResetPassword style={{fontSize: "revert"}}
                                                            onClick={() => setShowReset(!showReset)}
                                                            disabled={isLoading}
                                >
                                    Reset password
                                </S.StyledProfileResetPassword>
                            </S.StyledProfileButtonsFrame>
                            {showSuccess && (<div style={{paddingTop: "20px"}}>
                                <S.StyledUserInviteConfirm style={{width: "360px"}}>
                                    <S.StyledConfirmIconWrapper>
                                        <S.StyleConfirmIcon>
                                            <S.StyledIcon style={{color: "#2FBC36"}} className="fa-solid fa-circle-check"/>
                                        </S.StyleConfirmIcon>
                                    </S.StyledConfirmIconWrapper>
                                    <S.StyledUserInviteConfirmText>
                                        Profile has been successfully updated!
                                    </S.StyledUserInviteConfirmText>
                                </S.StyledUserInviteConfirm>
                            </div>)}
                        </Form>
                    </S.StyledProfileInfoContainer>
                </S.StyledCenterContainer>
            </S.StyledMainContainer>
        </S.StyledProfileContainer>
    );
};

export default Profile;
