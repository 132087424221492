import axios, {AxiosError, isAxiosError} from "axios";
import config from "../config/api";
import {IPaginationParams, IPaginationParamsWithIds, IResponse} from "../types";
import {EventLog} from "../types/event";
import {get, isEqual} from "lodash";

const {apiURL} = config.apiConfig;

interface IGetOrganizationLogsResponse extends IResponse {
    data?: EventLog[]
    quantity?: number;
}

export const getOrganizationLogsAPI = async (pagination: IPaginationParamsWithIds): Promise<IGetOrganizationLogsResponse> => {
    try {
        const URL = `${apiURL}/api/logs?limit=${pagination.limit}&offset=${pagination.offset}`;
        let payload = {};
        if (pagination.orgIds) {
            payload = {
                ...payload,
                orgIds: pagination.orgIds
            }
        }
        const axiosResponse = await axios.post(URL, payload);

        if (isEqual(axiosResponse.status, 201)) {
            return {
                success: true,
                data: get(axiosResponse.data, "data", []),
                quantity: get(axiosResponse.data, "count", 0)
            }
        } else {
            return {
                success: false
            }
        }
    } catch(error) {
        console.error("An error occurred during fetching list of events(api)", error);

        let errorResponse: IGetOrganizationLogsResponse = {
            success: false
        };

        return {
            ...errorResponse,
            errors: [isAxiosError(error) ? get(error, "response.data.message", "") : "An error occurred during fetching list of events"]
        };
    }
}
