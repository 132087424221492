import React, {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import * as S from "../user/styled-components";
import {isEmpty} from "lodash";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {getInvalidFieldNameForOrganization, validateEmail, validateMobileNumber} from "../../utils";
import {RegistrationErrors} from "../../types";
import {formBasicEmail, formBasicOrganizationName, formBasicPhoneNumber, sliceStatus} from "../../constants";
import {useNavigate} from "react-router-dom";
import {selectLoading, selectMessage, setMessage} from "../../features/global/globalSlice";
import {
    selectOrganizationCreated,
    setOrganizationCreated,
    setOrganizationStatus
} from "../../features/organization/organizationSlice";
import {createOrganization} from "../../features/organization/organizationThunks";
import { getFileURL } from '../../features/file/fileSlice';
import { getTermsOfServiceFile } from '../../features/file/fileThunks';

function CreateOrganisation() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [organizationName, setOrganizationName] = useState("");
    const [organizationEmail, setOrganizationEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [accepted, setAccepted] = useState(false)
    const [errors, setErrors] = useState<RegistrationErrors>({});

    const isLoading = useAppSelector(selectLoading);
    const organizationCreated = useAppSelector(selectOrganizationCreated);
    const errorMessage = useAppSelector(selectMessage);
    const fileURL = useAppSelector(getFileURL);

    useEffect(() => {
        if (organizationCreated) {
            navigate("/dashboard")
        }

        return () => {
            dispatch(setOrganizationCreated(false));
            setOrganizationStatus("loading");
            dispatch(setMessage(null));
        }
    }, [organizationCreated]);

    useEffect(() => {
        setErrors({
            ...errors,
            [getInvalidFieldNameForOrganization(errorMessage)]: errorMessage
        })
    }, [errorMessage]);

    useEffect(() => {
        loadFile()
    }, []);

    const loadFile = async () => {
        await dispatch(getTermsOfServiceFile(null));
    }

    const openTermsAndConditions = () => {
        if (typeof fileURL === 'string') {
            window.open(fileURL);
        }
    }

    const validateData = (): boolean => {
        let tmpErrors = {};

        if (isEmpty(organizationEmail)) {
            tmpErrors = {...tmpErrors, email: "Email cannot be empty"};
        } else if (organizationEmail.length > 100) {
            tmpErrors = {...tmpErrors, email: "Email should contain no more than 100 characters"};
        } else if (!validateEmail(organizationEmail)) {
            tmpErrors = {
                ...tmpErrors,
                email: "Email contains invalid characters"
            };
        }
        if (isEmpty(organizationName)) {
            tmpErrors = {...tmpErrors, organizationName: "Organization name cannot be empty"}
        } else if (organizationName.length > 20) {
            tmpErrors = {...tmpErrors, organizationName: "Organization name should contain no more than 20 characters"}
        }
        if (isEmpty(phoneNumber)) {
            tmpErrors = {...tmpErrors, mobileNumber: "Phone number name cannot be empty"}
        } else if (phoneNumber.length > 13) {
            tmpErrors = {...tmpErrors, mobileNumber: "Phone should contains no more 13 symbols"}
        } else if (!validateMobileNumber(phoneNumber)) {
            tmpErrors = {...tmpErrors, mobileNumber: "Phone number is not valid"}
        }

        if (!accepted) {
            tmpErrors = {...tmpErrors, accepted: "Terms of service not accepted"}
        }

        let notValid = Object.keys(tmpErrors).length > 0;
        if (notValid) {
            setErrors(tmpErrors);
        } else {
            setErrors({});
        }

        return notValid;
    }

    const onCreateOrganization = () => {
        if (!validateData()) {
            dispatch(createOrganization({email: organizationEmail, name: organizationName, phoneNumber}));
        }
    }

    return (
        <S.RegistrationContainer>
            <S.StyledRegistrationForm>
                <S.StyledRegistrationFrame>
                    <S.StyledRegistrationGroup>
                        <S.StyledTopLogo>
                            <S.StyledCompanyLogo/>
                            <S.StyledCompanyName/>
                        </S.StyledTopLogo>
                        <Form>
                            <S.StyledRegistrationData>
                                <S.StyledCaption>Create organization</S.StyledCaption>
                                <Form.Group controlId={formBasicOrganizationName}>
                                    <S.StyledRegistrationLabel>Company name</S.StyledRegistrationLabel>
                                    <S.StyledRegistrationInput type="input"
                                                               disabled={isLoading}
                                                               placeholder="e.g. Surfact"
                                                               isInvalid={!!errors.organizationName}
                                                               value={organizationName}
                                                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOrganizationName(event.target.value)}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid">{errors.organizationName}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId={formBasicEmail}>
                                    <S.StyledRegistrationLabel>Email</S.StyledRegistrationLabel>
                                    <S.StyledRegistrationInput type="email"
                                                               disabled={isLoading}
                                                               placeholder="e.g. john@appleseed.com"
                                                               isInvalid={!!errors.email}
                                                               value={organizationEmail}
                                                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOrganizationEmail(event.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId={formBasicPhoneNumber}>
                                    <S.StyledRegistrationLabel>Phone</S.StyledRegistrationLabel>
                                    <S.StyledRegistrationInput type="input"
                                                               isInvalid={!isEmpty(errors.mobileNumber)}
                                                               disabled={isLoading}
                                                               value={phoneNumber}
                                                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(event.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.mobileNumber}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Check>
                                    <Form.Check.Input type={"checkbox"}
                                                      isInvalid={!!errors.accepted}
                                                      onChange={() => setAccepted(!accepted)}
                                    />
                                    <Form.Check.Label>
                                        <S.StyledBottomText>I accept the
                                            <S.StyledLink onClick={openTermsAndConditions}>
                                                Terms of Service
                                            </S.StyledLink>
                                        </S.StyledBottomText>
                                    </Form.Check.Label>
                                    <Form.Control.Feedback type="invalid">{errors.accepted}</Form.Control.Feedback>
                                </Form.Check>
                                <S.StyledLoginButton onClick={onCreateOrganization} disabled={isLoading}>
                                    {isLoading && (<S.StyledSingInSpinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />)}
                                    Create organization
                                </S.StyledLoginButton>
                            </S.StyledRegistrationData>
                        </Form>
                    </S.StyledRegistrationGroup>
                </S.StyledRegistrationFrame>
                <S.StyledRegistrationImageBlock>
                    <S.StyledRegistrationImage/>
                </S.StyledRegistrationImageBlock>
            </S.StyledRegistrationForm>
        </S.RegistrationContainer>
    )
}

export default CreateOrganisation;
