import React from "react";
import * as S from "../dashboard/styled-components";
import {Col} from "react-bootstrap";
import Pagination from 'react-bootstrap/Pagination';

interface PaginationProps {
    pageNumber: number;
    lastPage: number;
    offset: number;
    endRange: number;
    quantity: number;
    setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}

function PaginationFooter(props: PaginationProps) {
    const paginate = () => {
        let paginationItems = [];
        const delta = 2;

        if (props.lastPage <= 1) {
            return (
                <Pagination.Item key={1} active={true}>
                    {1}
                </Pagination.Item>
            );
        }

        for (let i = Math.max(2, props.pageNumber - delta); i <= Math.min(props.lastPage - 1, props.pageNumber + delta); i += 1) {
            paginationItems.push(i);
        }

        if (props.pageNumber - delta > 2) {
            paginationItems.unshift('...');
        }

        if (props.pageNumber + delta < props.lastPage - 1) {
            paginationItems.push('...');
        }

        paginationItems.unshift(1);
        if (props.lastPage !== 1) {
            paginationItems.push(props.lastPage);
        }

        let n = 0;
        return paginationItems.map((i: any, index: number) => {
            n++;
            return i !== '...' ? (
                <S.StyledPaginationElCaption
                    key={i}
                    onClick={() => {
                        props.setPageNumber(i);
                    }}
                    active={i === props.pageNumber}
                >{i}
                </S.StyledPaginationElCaption>
            ) : (
                <Pagination.Ellipsis key={`ellipsis-${n}`}/>
            );
        });
    }

    return (
        <div style={{width: '100%'}}>
        <S.StyledTableFooter>
            <Col md={4}>
                <S.StyledPagination>
                    <Pagination.First onClick={() => props.setPageNumber(1)}/>
                    <Pagination.Prev disabled={props.pageNumber === 1}
                                     onClick={() => {
                                         if (props.pageNumber > 1) props.setPageNumber(props.pageNumber - 1);
                                     }}
                    />
                    {paginate()}
                    <Pagination.Next disabled={props.pageNumber === props.lastPage}
                                     onClick={() => {
                                         if (props.pageNumber < props.lastPage) props.setPageNumber(props.pageNumber + 1);
                                     }}
                    />
                    <Pagination.Last onClick={() => props.setPageNumber(props.lastPage)}/>
                </S.StyledPagination>
            </Col>
            <Col md={3}>
                <S.StyledPaginationStats>
                    <S.StyledPaginationStatsText>
                        {(props.offset + 1) + "-" + (props.endRange + 1) + " of " + (props.quantity)}
                    </S.StyledPaginationStatsText>
                </S.StyledPaginationStats>
            </Col>
        </S.StyledTableFooter>
        </div>
    );
}

export default PaginationFooter;
