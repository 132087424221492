export const countriesSEPA = {
FI: 'FI',
AD: 'AD',
AT: 'AT',
PT: 'PT',
BE: 'BE',
ES: 'ES',
CY: 'CY',
EE: 'EE',
FR: 'FR',
DE: 'DE',
GI: 'GI',
GR: 'GR',
IE: 'IE',
IT: 'IT',
LV: 'LV',
LT: 'LT',
LU: 'LU',
MT: 'MT',
MC: 'MC',
NL: 'NL',
SM: 'SM',
SK: 'SK',
SI: 'SI',
BG: 'BG',
HR: 'HR',
CZ: 'CZ',
DK: 'DK',
HU: 'HU',
IS: 'IS',
LI: 'LI',
NO: 'NO',
PL: 'PL',
RO: 'RO',
SE: 'SE',
CH: 'CH',
GB: 'GB',
}