import {createSlice} from '@reduxjs/toolkit';
import {User} from "../../types";
import {createUser} from "./registrationThunks";
import {RootState} from "../../app/store";

export interface RegistrationState {
    user: User | null;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: RegistrationState = {
    user: null,
    status: 'idle',
};

export const registrationSlice = createSlice({
    name: "registration",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createUser.pending, (state) => {
                state.status = "loading";
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) state.user = action.payload;
            })
            .addCase(createUser.rejected, (state, action) => {
                state.status = "failed";
            })
    }
});

export const selectRegisteredUser = (state: RootState) => state.registration.user;
export default registrationSlice.reducer;
