import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Device, DeviceFeaturePrice, OrderType, PreOrderData} from "../../types";
import {RootState} from "../../app/store";
import {
    getOrganizationDevices,
    getDevicesFeatures,
    getPreOrderInformation,
    createOrder,
    addDevicesToOrganization,
    getFreeDevices,
    unlinkDevice,
    returnDevice,
    cancelPreOder,
    updateDevice,
    deleteDevice
} from "./deviceThunks"
import {get, isEqual} from "lodash";

export interface DeviceState {
    devices: Device[],
    freeDevicesList: Device[],
    devicesQuantity: number,
    freeDevices: number,
    enoughDevices: boolean;
    devicesLoaded: boolean;
    devicesFeatures: DeviceFeaturePrice[],
    orderURL: string;
    preOrder: PreOrderData;
    status: "idle" | "loading" | "failed" | "loaded" | "loadedPreOrder" | "updated" |
        "orderCreated" | "canceledPreOrder" | "loadedFree" | "deleted" | "invoiceSend" | "devicesAdded" | "unlinked" | "returned"
}

const defaultPreorder: PreOrderData = {
    fullPrice: -1,
    notEnough: true,
    orderId: ""
}

const initialState: DeviceState = {
    devices: [],
    freeDevicesList: [],
    devicesQuantity: 0,
    freeDevices: 0,
    enoughDevices: false,
    devicesLoaded: false,
    devicesFeatures: [],
    orderURL: "",
    preOrder: defaultPreorder,
    status: "idle"
}

export const deviceSlice = createSlice({
    name: "device",
    initialState,
    reducers: {
        setStatus: (state, action: PayloadAction<'idle' | 'loading' | 'failed'>) => {
            state.status = action.payload;
        },
        setOrderURL: (state, action: PayloadAction<string>) => {
            state.orderURL = action.payload;
        },
        setPreOrderToDefault : (state) => {
            state.preOrder = defaultPreorder;
        },
        clearDevicesData: (state) => {
            state.status = "idle";
            state.devicesLoaded = false;
            state.devices = [];
            state.freeDevicesList = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOrganizationDevices.pending, (state) => {
                state.status = "idle";
            })
            .addCase(getOrganizationDevices.fulfilled, (state, action) => {
                if (action.payload) {
                    state.status = "loaded";
                    state.devices = action.payload.devices || [];
                    state.devicesQuantity = action.payload.quantity || 0;
                    state.devicesLoaded = true;
                }
            })
            .addCase(getOrganizationDevices.rejected, (state) => {
                state.status = "failed"
            })
            .addCase(getDevicesFeatures.pending, (state) => {
                state.status = "idle";
            })
            .addCase(getDevicesFeatures.fulfilled, (state, action) => {
                if (action.payload) {
                    state.status = "loaded";
                    state.devicesFeatures = get(action, "payload.devicesFeatures", []);
                    state.freeDevices = get(action, "payload.freeDevices", 0);
                }
            })
            .addCase(getDevicesFeatures.rejected, (state) => {
                state.status = "failed"
            })
            .addCase(getPreOrderInformation.pending, (state) => {
                state.status = "idle";
            })
            .addCase(getPreOrderInformation.fulfilled, (state, action) => {
                if (action.payload) {
                    state.status = "loadedPreOrder";
                    state.preOrder = action.payload;
                }
            })
            .addCase(getPreOrderInformation.rejected, (state) => {
                state.status = "failed"
            })
            .addCase(createOrder.pending, (state) => {
                state.status = "idle";
            })
            .addCase(createOrder.fulfilled, (state, action) => {
                if (action.payload) {
                    if (isEqual(action.payload.orderType, OrderType.CHECKOUT_SESSION)) {
                        state.status = "orderCreated";
                        state.orderURL = get(action, "payload.data.url", "");
                    } else {
                        state.status = "invoiceSend";
                    }
                }
            })
            .addCase(createOrder.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(addDevicesToOrganization.pending, (state) => {
                state.status = "idle";
            })
            .addCase(addDevicesToOrganization.fulfilled, (state, action) => {
                if (action.payload) {
                    state.status = "devicesAdded";
                } else {
                    state.status = "failed";
                }
            })
            .addCase(addDevicesToOrganization.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(getFreeDevices.pending, (state) => {
                state.status = "idle";
            })
            .addCase(getFreeDevices.fulfilled, (state, action) => {

                if (action.payload) {
                    state.status = "loadedFree";
                    state.freeDevicesList = get(action, "payload.data", []);
                }
            })
            .addCase(getFreeDevices.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(unlinkDevice.pending, (state) => {
                state.status = "idle"
            })
            .addCase(unlinkDevice.fulfilled, (state, action) => {
                if (action.payload) {
                    state.status = "unlinked"
                }
            })
            .addCase(unlinkDevice.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(returnDevice.pending, (state) => {
                state.status = "idle"
            })
            .addCase(returnDevice.fulfilled, (state, action) => {
                if (action.payload) {
                    state.status = "returned"
                }
            })
            .addCase(returnDevice.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(cancelPreOder.pending, (state) => {
                state.status = "idle"
            })
            .addCase(cancelPreOder.fulfilled, (state, action) => {
                if (action.payload) {
                    state.status = "canceledPreOrder"
                    state.preOrder = defaultPreorder;
                }
            })
            .addCase(cancelPreOder.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(updateDevice.pending, (state) => {
                state.status = "idle"
            })
            .addCase(updateDevice.fulfilled, (state, action) => {
                if (action.payload) {
                    state.status = "updated"
                }
            })
            .addCase(updateDevice.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(deleteDevice.pending, (state) => {
                state.status = "idle"
            })
            .addCase(deleteDevice.fulfilled, (state, action) => {
                if (action.payload) {
                    state.status = "deleted"
                }
            })
            .addCase(deleteDevice.rejected, (state) => {
                state.status = "failed";
            })
    }
});

export const {setStatus, setOrderURL, setPreOrderToDefault, clearDevicesData} = deviceSlice.actions;

export const selectDeviceStatus = (state: RootState) => state.device.status;
export const selectDevices = (state: RootState) => state.device.devices;
export const selectFreeDevices = (state: RootState) => state.device.freeDevices;
export const selectFreeDevicesList = (state: RootState) => state.device.freeDevicesList;
export const selectDeviceFeatures = (state: RootState) => state.device.devicesFeatures;
export const selectOrderURL = (state: RootState) => state.device.orderURL;
export const selectPreOrder = (state: RootState) => state.device.preOrder;
export const selectDevicesQuantity = (state: RootState) => state.device.devicesQuantity;
export const selectDevicesLoaded = (state: RootState) => state.device.devicesLoaded;

export default deviceSlice.reducer;
